<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <div class="btn-popup pull-right">
        <a [routerLink]="'/users/create-user'"
           class="btn btn-secondary">{{ 'Users.list-users.create-button' | translate }}</a>
      </div>
      <div class="category-table custom-datatable transcation-datatable user-image" id="batchDelete">
        <div class="table-responsive">
          <ng2-smart-table (delete)="this.deleteUser($event)" (edit)="this.editUser($event)" [settings]="settings"
                           [source]="tableSource"></ng2-smart-table>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
