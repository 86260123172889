import { Component, Inject, Input, OnInit } from '@angular/core';
import { DefaultSettingProduct } from 'src/app/model/default-setting';

@Component({
  selector: 'app-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss']
})
export class ShareButtonComponent implements OnInit {
  @Input() product: DefaultSettingProduct = {} as DefaultSettingProduct;
  productUrl: string = '';
  constructor(@Inject('FRONT_OFFICE_BASE_URL') private frontOfficeUrl: string,) { }

  ngOnInit(): void {
    const uri = localStorage.getItem('storeUri');
    this.productUrl = `${this.frontOfficeUrl}/${uri}/shop/product/${this.product.sku}`;
  }
}
