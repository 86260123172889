<div
  class="page-wrapper"
  [ngStyle]="{
    'background-image': 'url(../../../../assets/images/fondologin.png)'
  }"
>
  <div class="authentication-box">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 p-0 card-right">
          <div class="card">
            <div class="card-body">
              <div class="row w-100">
                <div class="col-md-5 w-100 border-2 border-end">
                  <div class="flex justify-content-center">
                    <img
                      class="w-100"
                      [width]="85"
                      alt=""
                      [src]="
                        country === 'UY'
                          ? '../../../../assets/images/BeeSeller-07.png'
                          : '../../../../assets/images/registro_shedone.png'
                      "
                    />
                    <p class="mt-4">{{ "Login.desc" | translate }}</p>
                  </div>
                  <!-- <app-card-primary [disableForm1]="disableForm1"></app-card-primary> -->
                </div>
                <div class="col-md-7 w-100 mt-4">
                  <form
                    (ngSubmit)="login(loginForm)"
                    [formGroup]="loginForm"
                    class="form-horizontal auth-form w-100"
                    novalidate=""
                  >
                    <div class="form-group">
                      <input
                        class="form-control"
                        formControlName="email"
                        id="exampleInputEmail1"
                        name="login[email]"
                        placeholder="Email"
                        required
                        type="email"
                      />
                    </div>
                    <div class="form-group" style="display: flex">
                      <input
                        class="form-control"
                        formControlName="password"
                        name="login[password]"
                        placeholder="{{
                          'Login.placeholder.password' | translate
                        }}"
                        required
                        [type]="show ? 'text' : 'password'"
                      />
                      <div class="center view-pass">
                        <a (click)="this.showPassword()" class="view-pass-eye">
                          <app-feather-icons
                            [icon]="'eye'"
                            *ngIf="!show; else eyeHide"
                          ></app-feather-icons>
                          <ng-template #eyeHide>
                            <app-feather-icons
                              [icon]="'eye-off'"
                            ></app-feather-icons>
                          </ng-template>
                        </a>
                      </div>
                    </div>
                    <div style="text-align: center">
                      <button
                        *ngIf="!this.loadingLogin"
                        class="btn btn-primary mt-1 w-100"
                        type="submit"
                      >
                        {{ "Login.get-into" | translate }}
                      </button>
                      <!-- <div *ngIf="this.loadingLogin" class="loader"></div> -->
                    </div>
                    <div class="my-3">
                      <button
                        *ngIf="!this.loadingLogin"
                        class="btn btn-outline-primary btn-google rounded-pill mt-1 w-100 d-flex justify-content-around"
                        (click)="singInWithGoogle()"
                        type="button"
                      >
                        <img
                          class="logo-size"
                          alt=""
                          src="../../../../assets/images/google(1).svg"
                        />
                        {{ "Login.get-into-google" | translate }}
                      </button>
                    </div>
                    <div *ngIf="this.loadingLogin" class="loader"></div>
                    <!-- <div>
                      <button *ngIf="!this.loadingLogin"
                        class="btn btn-outline-primary rounded-pill mt-1 w-100 d-flex justify-content-around">
                        <img class="logo-size" alt="" src="../../../../assets/images/facebook.svg" />
                        Facebook
                      </button>
                      <div *ngIf="this.loadingLogin" class="loader"></div>
                    </div> -->
                    <div class="text-center mt-2 link-primary">
                      <!-- <div>¿Has olvidado tu contraseña?</div> -->
                      <a
                        class="mt-2"
                        style="text-align: center; color: blue"
                        routerLink="/recuperarpassword"
                        >{{ "Login.forget-password" | translate }}
                      </a>
                    </div>
                  </form>
                  <hr class="m-3" />
                  <div>
                    <p>{{ "Login.account" | translate }}</p>
                    <div style="text-align: center">
                      <a
                        class="btn btn-outline-primary rounded-pill mt-1 w-100 d-flex justify-content-around"
                        [ngStyle]="{
                          'text-align': 'center',
                          'background-color': ''
                        }"
                        routerLink="/register"
                        >{{ "Login.register" | translate }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
