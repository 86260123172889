<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-xl-12">
      <div class="card tab2-card">
        <div class="card-body">
          <ngb-tabset class="tab-coupon profile-tabs">
            <ngb-tab>
              <ng-template ngbTabTitle>
                <app-feather-icons [icon]="'shopping-cart'"></app-feather-icons>
                {{'Settings.store' | translate}}
              </ng-template>
              <ng-template ngbTabContent>
                <div aria-labelledby="top-store-tab" class="tab-pane fade show active" id="top-store" role="tabpanel">
                  <app-form-edit-store [userId]="userId" [user]="user"></app-form-edit-store>
                </div>
              </ng-template>
            </ngb-tab>
            <ngb-tab>
              <ng-template ngbTabTitle>
                <app-feather-icons [icon]="'user'"></app-feather-icons>
                {{'Settings.profile' | translate}}
              </ng-template>
              <ng-template ngbTabContent>
                <app-edit-user [user]="user"></app-edit-user>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal></app-modal>