import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Catalog } from '../../model/Catalog';
import { Product } from '../../model/Product';
import { CONSTANTS } from '../../utils/Constants';
import { CatalogPost } from '../../model/CatalogPost';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  // Todos unsubscriptos

  url: string;
  tempListProduct: Product[]; // Lista global de productos selecciona el usuario
  catalogs: any[]; // Lista de catalogos a utilizar en los demas componentes.
  activeCatalog: Catalog; // El catálogo marcado como activo.
  editCatalog: Catalog;
  editForCatalogModal: boolean;
  IdCatalogToEdit: string;

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private translate: TranslateService,
    private toastr: ToastrService) {
    this.url = baseUrl + 'products_api/catalogues';
    this.catalogs = [];
    this.activeCatalog = null;
    this.tempListProduct = [];
  }

  getCatalogues() {
    return this.httpClient.get(this.url);
  }

  createCatalog(catalog: CatalogPost) {
    return this.httpClient.post(this.url, catalog);
  }

  getDefaultCatalogConfig() {
    return this.httpClient.get(`/assets/data/catalogs.json`);
  }

  getCatalogueById(uuid: string) {
    return this.httpClient.get(this.url + '/' + uuid);
  }

  putCatalog(catalog: CatalogPost, uuid: string) {
    return this.httpClient.put(this.url + '/' + uuid, catalog);
  }

  deleteCatalog(uuid: string) {
    return this.httpClient.delete(this.url + '/' + uuid);
  }

  publishCatalog(uuid: string) {
    return this.httpClient.post(this.url + '/' + uuid + '/publish', {});
  }

  addProduct(ev: any, rowData: any) {
    const product = this.tempListProduct.find(element => element.sku === rowData.sku);
    if (product === undefined && ev.target.checked && this.tempListProduct.length < CONSTANTS.MAX_SIZE_CATALOG) {
      this.tempListProduct.push(rowData);
    } else {
      const index = this.tempListProduct.indexOf(product, 0);
      if (index > -1) {
        this.tempListProduct.splice(index, 1);
      }
      if (this.tempListProduct.length >= CONSTANTS.MAX_SIZE_CATALOG) {
        this.toastr.warning(this.translate.instant('Toast.catalog.catalog-full'), this.translate.instant('Toast.warning'), {
          progressBar: true,
          timeOut: 3000
        });
      }
      ev.target.checked = false;
    }
  }

}
