<div class="row">
    <div class="col-xl-4 col-md-6 xl-50">
        <div class="card px-4 py-2 o-hidden widget-cards bee-seller-bg-black">
            <!-- <div class="bee-seller-bg-black card-body"> -->
            <div class="row d-flex justify-content-around align-items-center">
                <div class="col-9">
                    <h4 class="mb-3">
                        {{'Dashboard.cards.completed' | translate}}
                    </h4>
                    <div class="mb-0 d-flex align-items-center">
                        <h3 class="counter mr-2" [CountTo]="progress.completed" [from]="0" [duration]="1">0</h3>
                        <small>
                            {{'Dashboard.months' | translate}}
                        </small>
                    </div>
                </div>
                <div class="col-3">
                    <app-emotic [emoji]="emojiCompeted"></app-emotic>
                </div>
            </div>
            <!-- </div> -->
        </div>
    </div>
    <div class="col-xl-4 col-md-6 xl-50">
        <div class="card widget-cards px-4 py-2">
            <div class="row d-flex justify-content-around align-items-center">
                <div class="col-9">
                    <h4 class="mb-3">
                        {{'Dashboard.cards.in-progress' | translate}}
                    </h4>
                    <div class="mb-0 d-flex align-items-center">
                        <h3 class="counter mr-2" [CountTo]="progress.pending" [from]="0" [duration]="1">0</h3>
                        <small>{{'Dashboard.months' | translate}}</small>
                    </div>
                </div>
                <div class="col-3">
                    <app-emotic [emoji]="emojiPending"></app-emotic>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-4 col-md-12 xl-50">
        <div class="card o-hidden widget-cards px-4 py-2 bee-seller-bg-yellow">
            <!-- <div class=""> -->
            <div class="row d-flex justify-content-around align-items-center">
                <div class="col-9">
                    <h4 class="mb-3">
                        {{'Dashboard.cards.earnings' | translate}}
                    </h4>
                    <div class="mb-0 d-flex align-items-center">
                        <h3 class="mr-2">$</h3>
                        <h3 class="counter mr-2" [CountTo]="progress.profits.toFixed(2)" [from]="0" [duration]="0">0
                        </h3>
                        <small>
                            {{'Dashboard.months' | translate}}
                        </small>
                    </div>
                </div>
                <div class="col-3">
                    <!-- <div class="align-self-center text-center"> -->
                    <app-emotic [emoji]="emojiProfit"></app-emotic>
                    <!-- </div> -->
                </div>
            </div>
            <!-- </div> -->
        </div>
    </div>
</div>