<form
  class="container-fluid text-capitalize card-container"
  [formGroup]="settingForm"
>
  <!-- <div class="card"> -->
  <div class="row">
    <div class="col-md-4">
      <app-product-month-card
        [product]="product"
        title="{{ 'Modal-Product.title' | translate }}"
      ></app-product-month-card>
    </div>
    <div class="col-md-2">
      <label for="productMonth" class="form-label">{{
        "Modal-Product.title" | translate
      }}</label>
      <input
        type="text"
        class="form-control"
        id="productMonth"
        formControlName="productMonth"
      />
    </div>
    <div
      class="col-md-2 d-flex justify-content-center align-items-end"
      *ngIf="togleBtnSave"
    >
      <button type="button" class="btn btn-primary" (click)="onSave()">
        {{ "Modal-Product.save-changes" | translate }}
      </button>
    </div>
  </div>
  <!-- </div> -->
</form>
