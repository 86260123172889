import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {NavService} from '../../service/nav.service';
import {AuthService} from '../../../services/auth/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../../model/User';
import { getLanguage } from 'src/main';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public rightSidebar = false;
  public open = false;
  public openNav = false;
  public isOpenMobile: boolean;
  public lang: string;
  public user: User;
  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor (public navServices: NavService, public authService: AuthService, public translate: TranslateService) {
    this.lang = "ES";
  }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  right_side_bar() {
    this.rightSidebar = !this.rightSidebar;
    this.rightSidebarEvent.emit(this.rightSidebar);
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  public changeLanguage (lang: string) {
    lang === 'es' ? this.translate.getLangs()[1] : this.translate.getLangs()[0];
    this.lang = lang.toUpperCase();
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
  }

  async logout() {
    await this.authService.logout();
  }
}
