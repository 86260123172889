import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {CatalogService} from '../../../../services/catalog/catalog.service';
import {CatalogPost} from '../../../../model/CatalogPost';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import { getCountry } from 'src/main';

@Component({
  selector: 'app-create-catalog-modal',
  templateUrl: './create-catalog-modal.component.html',
  styleUrls: ['./create-catalog-modal.component.scss']
})
export class CreateCatalogModalComponent implements OnInit, OnDestroy {

  newCatalog: any = {};
  editModal: boolean;
  user = JSON.parse(localStorage.user);
  country = getCountry();

  private subscriptions = new Subscription();

  constructor(public catalogService: CatalogService,
              public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private router: Router,
              private toaster: ToastrService,
              private translate: TranslateService) {
    this.newCatalog = this.catalogService.editCatalog;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.editModal = this.catalogService.editForCatalogModal;
  }

  createCatalog(publish?: boolean) {
    this.newCatalog.products = this.catalogService.tempListProduct;
    const catalog: CatalogPost = {
      name: this.newCatalog.name,
      percentage: this.newCatalog.percentage,
      publish,
      skuProducts: []
    };

    this.newCatalog.products.forEach(product => {
      catalog.skuProducts.push(product.sku);
    });

    if (this.editModal) {
      const editSubscription = this.catalogService.putCatalog(catalog, this.catalogService.IdCatalogToEdit).subscribe(
        (res: any) => {
          this.editModal = false;
          this.catalogService.editForCatalogModal = false;
          this.modalService.dismissAll();
          this.toaster.success(this.translate.instant('Toast.catalog.updated'), this.translate.instant('Toast.success'), {
            progressBar: true,
            timeOut: 3000
          });
          // Se vacía la colección de productos en el service de catálogo
          this.catalogService.tempListProduct = [];
          // Se redirige al usuario a la lista de catalogos
          this.router.navigate(['/catalogs/catalog-list']);
        },
        error => {
          if (error.status === 400) {
            Object.entries(error.error.errors).forEach(([key, value]) => {
              this.toaster.warning(this.translate.instant('Toast.catalog.bad-request.' + key), this.translate.instant('Toast.warning'), {
                progressBar: true,
                timeOut: 3000
              });
            });
          }
        }
      );

      this.subscriptions.add(editSubscription);

    } else {
      const createSubscription = this.catalogService.createCatalog(catalog).subscribe(
        (res: any) => {
          /* Se cierra el módal, falta añadir un mensaje al usuario (toast, sweet alert, etc) */
          this.modalService.dismissAll();
          this.toaster.success(this.translate.instant('Toast.catalog.created'), this.translate.instant('Toast.success'), {
            progressBar: true,
            timeOut: 3000,
          });
          // Se vacía la colección de productos en el service de catálogo
          this.catalogService.tempListProduct = [];
          // Se redirige al usuario a la lista de catalogos
          this.router.navigate(['/catalogs/catalog-list']);
        },
        error => {
          if (error.status === 409) {
            this.toaster.error(this.translate.instant('Toast.catalog.conflict'), this.translate.instant('Toast.error'), {
              progressBar: true,
              timeOut: 3000
            });
          } else if (error.status === 400) {
            Object.entries(error.error.errors).forEach(([key, value]) => {
              this.toaster.warning(this.translate.instant('Toast.catalog.bad-request.' + key), this.translate.instant('Toast.warning'), {
                progressBar: true,
                timeOut: 3000
              });
            });
          } else {
            this.toaster.error(this.translate.instant('Toast.catalog.error'), this.translate.instant('Toast.error'), {
              progressBar: true,
              timeOut: 3000
            });
          }
        }
      );

      this.subscriptions.add(createSubscription);
    }
  }
}
