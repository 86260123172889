<div class="card o-hidden widget-cards">
  <div class="products-row-title my-3" *ngIf="title.length > 0">
    <h3 class="text-uppercase text-center">{{ title }}</h3>
  </div>
  <div class="row px-3">
    <div class="col-xl-6 col-md-6 col-sm-6">
      <img
        class="img-fluid"
        [src]="
          product.image
            ? product.image
            : '../../../assets/images/digital-product/lecture-video-recorder.jpg'
        "
        alt=""
        srcset=""
      />
    </div>
    <div class="col-xl-6 col-md-6 col-sm-6 produt-data">
      <h4 class="text-capitalize my-3 title">{{ product.title }}</h4>
      <div class="fs-2">
        <strong class="text-uppercase mb-3 subtitle"
          >sku: {{ product.sku }}</strong
        >
      </div>
      <div>
        <strong class="text-capitalize mb-3 subtitle"
          >{{ "Product-details.cost-ppgg" | translate }} $
          {{ product.price }}</strong
        >
      </div>
    </div>
  </div>
  <ng-container *ngIf="subtitle; else elseTemplate">
    <div class="row-md-12">
      <h5 class="col-md-12 products-row-footer">{{ subtitle }}</h5>
    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <div class="row-md-12 bee-seller-bg-yellow">
      <app-btn-add-product
        [sku]="product.sku"
        class="col-md-12 products-row-footer"
      ></app-btn-add-product>
    </div>
  </ng-template>
</div>
