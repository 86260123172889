import {Component, OnDestroy, OnInit} from '@angular/core';
import {CouponsService} from '../../../services/coupons/coupons.service';
import {Coupon} from '../../../model/coupon';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-list-coupon',
  templateUrl: './list-coupon.component.html',
  styleUrls: ['./list-coupon.component.scss']
})
export class ListCouponComponent implements OnInit, OnDestroy {

  public digitalCategories: Coupon[] = [];
  public selected = [];

  private subscriptions = new Subscription();

  constructor(private couponsService: CouponsService) {
    this.subscriptions.add(
      this.couponsService.getCoupons().subscribe(coupons => {
        this.digitalCategories = coupons;
      })
    );
  }

  onSelect({selected}) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
