<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title f-w-600" *ngIf="!this.sharedService.ecommercePublished">{{'Dashboard.buttons.publish' | translate}}</h5>
    <h5 class="modal-title f-w-600" *ngIf="this.sharedService.ecommercePublished">{{'Dashboard.buttons.deactivate' | translate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5 class="modal-title f-w-400 margin-top-bottom" *ngIf="!this.sharedService.ecommercePublished">{{'Dashboard.modal.publish-message' | translate}}</h5>
    <h5 class="modal-title f-w-400 margin-top-bottom" *ngIf="this.sharedService.ecommercePublished">{{'Dashboard.modal.deactivate-message' | translate}}</h5>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="this.publishVendorEcommerce()">{{'Dashboard.modal.confirm' | translate}}</button>
    <button type="button" class="btn btn-secondary" (click)="this.cancel()">{{'Dashboard.modal.cancel' | translate}}</button>
  </div>
</div>
