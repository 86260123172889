import {User} from './User';

export interface Order {
  orderId: number;
  profileId: string;
  itemList: OrderItem[];
  discountCouponPercentage: number;
  coin: string; // UYU, USD
  totalPrice: number;
  contactFullName: string;
  contactMail: string;
  contactPhone: string;
  deliveryAddress: string;
  deliveryDoorNumber: string;
  deliveryDepartment: string;
  deliveryLocation: string;
  deliveryType: string;
  deliveryPostalCode: string;
  status: Status;
  createdAt: string; // Es una fecha (Date())
  vendor: User;
  deliveryShipCost: number;
  vendorProfitPercentage: number;
  beeSellerProfit: number;
  sellerProfit: number;
  subTotal: number;
  postalCode: string;
}

export interface OrderItem {
  sku: string;
  price: number;
  quantity: number;
  artNombreML: string;
}

export enum Status {
  ALL = 'ALL',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  CANCELLED = 'CANCELLED'
}
