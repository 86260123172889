import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-success-register',
  templateUrl: './success-register.component.html',
  styleUrls: ['./success-register.component.scss']
})
export class SuccessRegisterComponent implements OnInit {
  constructor(
      private router: Router,
  ) { }

  ngOnInit(): void {
  }

  onContinue(){
    this.router.navigate(['/dashboard']).then().catch(console.error);
  }
}
