<div class="main-header-left d-none d-lg-block">
  <div class="logo-wrapper">
    <a [routerLink]="'/dashboard'"
      ><img
        class="blur-up lazyloaded"
        src="assets/images/Bee-horizontal-white.png"
        style="max-height: 60px; max-width: 160px"
        alt=""
    /></a>
  </div>
</div>
<div class="sidebar custom-scrollbar">
  <div class="sidebar-user text-center">
    <div>
      <img
        class="img-60 rounded-circle"
        src="{{ user?.image || 'assets/images/user-generic.png' }}"
        alt="#"
      />
    </div>
    <h6 class="mt-3 f-14">{{ user?.firstName }} {{ user?.lastName }}</h6>
    <p>{{ displayRole }}</p>
    <div
      *ngIf="
        rol !== 'ADMIN' &&
        this.sharedService.ecommerceUrl &&
        this.sharedService.ecommercePublished !== undefined
      "
      style="margin-top: 20px"
    >
      <a
        class="btn-primary btn-sm"
        href="{{ this.frontOfficeBaseUrl }}/{{
          this.sharedService.ecommerceUrl
        }}"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-shopping-cart"></i>
        {{ "Sidebar.store" | translate }}
      </a>
      <p class="text-pill">
        <span
          *ngIf="this.sharedService.ecommercePublished"
          class="badge active-color text-white mt-2"
        >
          {{ "Sidebar.activo" | translate }}
        </span>
        <span
          *ngIf="!this.sharedService.ecommercePublished"
          class="badge inactive-color text-white mt-2"
        >
          {{ "Sidebar.inactivo" | translate }}
        </span>
      </p>
    </div>
  </div>
  <ul class="sidebar-menu">
    <li
      *ngFor="let menuItem of menuItems"
      [ngClass]="{ active: menuItem.active }"
    >
      <!-- Products -->
      <a
        href="javascript:void(0)"
        class="sidebar-header"
        *ngIf="menuItem.type === 'sub'"
        (click)="toggletNavActive(menuItem)"
      >
        <app-feather-icons [icon]="menuItem.icon"></app-feather-icons>
        <span>
          {{ menuItem.title | translate }}
          <span
            class="badge badge-{{ menuItem.badgeType }} ml-3"
            *ngIf="menuItem.badgeType"
            >{{ menuItem.badgeValue }}
          </span>
        </span>
        <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
      </a>
      <!-- Dashboard & Settings-->
      <a
        [routerLink]="!menuItem.type ? null : [menuItem.path]"
        routerLinkActive="active"
        class="sidebar-header"
        *ngIf="
          menuItem.type === 'link' &&
          (this.rol === 'ADMIN' || menuItem.title !== 'Sidebar.users') &&
          menuItem.show
        "
        (click)="collapseSidebar()"
      >
        <app-feather-icons [icon]="menuItem.icon"></app-feather-icons>
        <span
          >{{ menuItem.title | translate }}
          <span
            class="badge badge-{{ menuItem.badgeType }} ml-3"
            *ngIf="menuItem.badgeType"
            >{{ menuItem.badgeValue }}</span
          >
        </span>
        <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
      </a>
      <ul
        class="sidebar-submenu"
        [ngClass]="{
          'menu-open': menuItem.active,
          'menu-close': !menuItem.active
        }"
        *ngIf="menuItem.children"
      >
        <li
          *ngFor="let childrenItem of menuItem.children"
          [ngClass]="{ active: childrenItem.active }"
        >
          <a
            [routerLink]="!childrenItem.type ? null : [childrenItem.path]"
            *ngIf="childrenItem.type === 'link'"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="collapseSidebar()"
          >
            <i class="fa fa-circle"></i>
            <span
              >{{ childrenItem.title | translate }}
              <span
                class="badge badge-{{ childrenItem.badgeType }} pull-right"
                *ngIf="childrenItem.badgeType"
                >{{ childrenItem.badgeValue }}
              </span>
            </span>
            <i
              class="fa fa-angle-down pull-right"
              *ngIf="childrenItem.children"
            ></i>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</div>
