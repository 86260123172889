<div class="modal-ranking">
  <button type="button" class="close" aria-label="Close">
    <span aria-hidden="true" (click)="activeModal.dismiss()">&times;</span>
  </button>
  <div class="modal-ranking__header">
    <h5 class="modal-ranking__title f-w-600" id="exampleModalLabel">
      Top 10 Seller Ranking
    </h5>
    <h5 class="modal-ranking__sub-title f-w-600" id="exampleModalLabel">
      {{ month }}
    </h5>
  </div>
  <div class="modal-ranking__body">
    <div
      *ngFor="let item of ranking; index as i"
      class="modal-ranking__body__position"
    >
      <div class="background-ranking">
        <img
          class="img-ranking"
          *ngIf="i + 1 <= 3"
          [src]="
            i + 1 !== 1
              ? i + 1 !== 2
                ? i + 1 === 3
                  ? 'assets/images/ranking/third.png'
                  : ''
                : 'assets/images/ranking/second.png'
              : 'assets/images/ranking/first.png'
          "
          alt="#"
        />
        <div *ngIf="i + 1 > 3" class="circulo">
          <p class="number-position">{{ i + 1 }}</p>
        </div>
      </div>
      <div class="modal-ranking__body__position__user">
        <div
          class="background-perfil d-flex justify-content-around align-items-center"
          style="width: 20%"
        >
          <img
            class="img-perfil"
            src="assets/images/user-generic.png"
            alt="#"
          />
        </div>
        <p class="field-user">{{ item.fullName }}</p>
      </div>
      <div class="d-flex flex-row align-items-center gap-50">
        <p class="field">Ventas concretadas</p>
        <p class="field">{{ item.sales }}</p>
      </div>
    </div>
  </div>
</div>
