<form (ngSubmit)="editStore()" [formGroup]="storeForm" class="container-fluid">
  <app-separator name="General:"></app-separator>

  <div class="row mb-3 justify-content-center">
    <label class="col-sm-6 col-form-label">{{ "Store.url" | translate }}</label>
    <div class="col-sm-6">
      <ng-container *ngIf="!this.editMode; else elseTemplate">
        <a
          class="config-a-color"
          href="{{ this.frontOfficeBaseUrl }}/{{ this.store.uri }}"
          target="_blank"
        >
          {{ this.frontOfficeBaseUrl }}/{{ this.store.uri }}
        </a>
      </ng-container>
      <ng-template #elseTemplate>
        <input
          class="form-control"
          formControlName="url"
          name="url"
          type="text"
          [value]="this.store.uri"
          pattern="[A-Za-z0-9]{1,20}"
        />
      </ng-template>
    </div>
  </div>
  <div class="row mb-3 justify-content-center">
    <label class="col-sm-6 col-form-label">
      {{ "Store-Configuration.name-store" | translate }}:
    </label>
    <div class="col-sm-6">
      <input
        class="form-control"
        formControlName="storeName"
        [value]="store.settings?.storeName || ''"
        name="storeName"
        id=""
        cols="0"
        rows="0"
        maxlength="75"
      />
    </div>
  </div>
  <br/>
  <app-separator
    name="{{ 'Store-Configuration.design' | translate }}:"
  ></app-separator>
  <label class="col-form-label">
    {{ "Store-Configuration.choose-colors" | translate }}
  </label>
  <div class="row mb-3 justify-content-around">
    <div class="col-sm-4">
      <label class="col-form-label" for="storeNameColor">
        {{ "Store-Configuration.name-color" | translate }}
      </label>
      <input
        class="form-control form-control-color"
        type="color"
        formControlName="storeNameColor"
        name="storeNameColor"
        [value]="store.settings?.storeNameColor || ''"
      />
    </div>
    <div class="col-sm-4">
      <label class="col-form-label" for="headerColor">
        {{ "Store-Configuration.header-color" | translate }}
      </label>
      <input
        class="form-control form-control-color"
        type="color"
        formControlName="headerColor"
        name="headerColor"
        [value]="store.settings?.headerColor || ''"
      />
    </div>
    <div class="col-sm-4">
      <label class="col-form-label" for="backgroundColor">
        {{ "Store-Configuration.background-color" | translate }}
      </label>
      <input
        class="form-control"
        type="color"
        formControlName="backgroundColor"
        name="backgroundColor"
        [value]="storeSetting.backgroundColor"
      />
    </div>
  </div>
  <br/>

  <app-separator name="Banner:"></app-separator>
  <div class="row mb-3 justify-content-center">
    <label class="col-sm-5 col-form-label" for="bannerImg">
      {{ "Store-Configuration.banner" | translate }}<br/>
      {{ "Store-Configuration.banner-2" | translate }}
    </label>
    <div class="col-sm-7">
      <ng-container *ngIf="!editMode; else templateFile">
        <img [src]="store.settings?.bannerImg" class="bannerImg" alt=""/>
      </ng-container>
      <ng-template #templateFile>
        <ng-container
          *ngIf="
            defaultTemplateSelected && defaultTemplateSelected.banner;
            else elseTemplate
          "
        >
          <img
            [src]="this.defaultTemplateSelected.banner"
            class="bannerImg"
            alt=""
          />
          <input
            type="button"
            class="btn btn-danger"
            value="x"
            (click)="onRemoveBannerImg()"
          />
        </ng-container>
        <ng-template #elseTemplate>
          <div class="input-group">
            <input
              (click)="onClickToFile()"
              type="button"
              class="input-group-text btn btn-primary"
              [value]="fileImgSelected ? 'Cambiar' : 'Seleccionar'"
            />
            <input
              type="text"
              class="form-control"
              disabled
              [value]="fileImgSelected"
            />
          </div>
          <input
            class="form-control"
            style="display: none"
            type="file"
            name="bannerImg"
            id="bannerImg"
            (change)="getImageData($event)"
            accept=".png,.jpg,.jpeg,.gif"
          />
        </ng-template>
      </ng-template>
    </div>
  </div>
  <br/>
  <label class="col-form-label">
    {{ "Store-Configuration.change-banner" | translate }} </label
  ><br/>
  <ng-container *ngIf="editMode; else templatesConfigs">
    <app-templates-stores
      class="justify-content-center"
      (select)="getTemplateSelection($event)"
    ></app-templates-stores>
  </ng-container>
  <ng-template #templatesConfigs>
    <app-templates-stores class="justify-content-center"></app-templates-stores>
  </ng-template>

  <app-separator
    name="{{ 'Store-Configuration.contact' | translate }}:"
  ></app-separator>
  <div>
    <label class="col-form-label">
      {{ "Store-Configuration.information-contact" | translate }}
    </label>
  </div>
  <br/>
  <div class="row mb-3 justify-content-evenly">
    <div class="col-sm-6">
      <div class="input-group">
        <label for="telephone" class="col-sm-4 col-form-label">
          {{ "Store-Configuration.cell-phone" | translate }}
        </label>
        <span *ngIf="country === 'UY'" class="input-group-text" id="addon-wrapping">{{
            userPhoneArea
          }}</span>
        <input
          type="tel"
          class="form-control"
          formControlName="telephone"
          name="phone"
          maxlength="13"
          id=""
          placeholder="991808"
          (input)="country === 'UY' ? onInputPhone($event.target.value) : ''"
          [value]="store.settings?.contacts.telephone || ''"
        />
      </div>
    </div>
    <div class="col-sm-6">
      <div class="input-group">
        <label for="email" class="col-sm-4 col-form-label"> E-mail: </label>
        <input
          class="form-control"
          formControlName="email"
          name="email"
          id=""
          maxlength="75"
          [disabled]="!editMode"
          placeholder="megashop@gmail.com"
          [value]="store.settings?.contacts.email || ''"
        />
      </div>
    </div>
  </div>
  <app-separator></app-separator>
  <div class="row mb-3 justify-content-around">
    <div class="col-sm-4 mt-2 px-0">
      <div class="input-group">
        <label for="instagram" class="col-sm-6 col-form-label">
          Instagram:
        </label>
        <input
          class="form-control"
          formControlName="instagram"
          name="instagram"
          id=""
          maxlength="75"
          [disabled]="!editMode"
          placeholder="MegaShop"
          [value]="store.settings?.contacts.instagram || ''"
        />
      </div>
    </div>
    <div class="col-sm-4 mt-2 px-0">
      <div class="input-group">
        <label for="facebook" class="col-sm-6 col-form-label">
          Facebook:
        </label>
        <input
          class="form-control"
          formControlName="facebook"
          name="facebook"
          id=""
          maxlength="75"
          [disabled]="!editMode"
          placeholder="MegaShop"
          [value]="store.settings?.contacts.facebook || ''"
        />
      </div>
    </div>
    <div class="col-sm-4 mt-2 px-0">
      <div class="input-group">
        <label for="tiktok" class="col-sm-6 col-form-label"> Tik-tok: </label>
        <input
          class="form-control"
          formControlName="tiktok"
          name="tiktok"
          id=""
          maxlength="75"
          [value]="store.settings?.contacts.tiktok || ''"
          [disabled]="!editMode"
          placeholder="MegaShop"
        />
      </div>
    </div>
  </div>
  <br/>
  <app-separator
    name="{{ 'Store-Configuration.state-store' | translate }}"
  ></app-separator>
  <div class="row mb-3 justify-content-center">
    <label class="col-sm-4 col-form-label">
      {{ "Store.published" | translate }}
    </label>
    <div class="col-sm-6">
      <select
        class="form-control"
        formControlName="enabled"
        name="store-enabled"
        style="width: auto"
      >
        <option value="{{ this.store.enabled }}">
          {{ this.store.enabled ? "Activo" : "Inactivo" }}
        </option>
        <option value="{{ !this.store.enabled }}">
          {{ this.store.enabled ? "Inactivo" : "Activo" }}
        </option>
      </select>
    </div>
  </div>

  <div class="row text-center justify-content-center">
    <div class="col">
      <button
        *ngIf="this.editMode"
        class="btn-sm btn-primary"
        style="color: #0a0100"
        style="padding: 0.4rem 0.5rem; font-size: 12px"
        type="submit"
      >
        {{ "Settings.buttons.save" | translate }}
      </button>
    </div>
  </div>

  <div class="row mt-2 text-center justify-content-center">
    <div class="col">
      <ng-container *ngIf="!this.editMode; else elseTemplateButtons">
        <button
          (click)="changeEditMode()"
          class="btn-sm btn-primary"
          style="color: #0a0100"
        >
          <span>{{ "Store-Configuration.edit" | translate }}</span>
        </button>
      </ng-container>
      <ng-template #elseTemplateButtons>
        <button (click)="changeEditMode()" class="btn-sm btn-secondary">
          <span
          ><span>{{ "Store-Configuration.cancel" | translate }}</span></span
          >
        </button>
      </ng-template>
    </div>
  </div>
</form>
