import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RankingSellers } from 'src/app/model/ranking-sellers';
import { GamificationService } from 'src/app/services/gamification/gamification.service';
import { ModalRankingComponent } from 'src/app/shared/modal/modals/modal-ranking/modal-ranking.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-row-ranking',
  templateUrl: './row-ranking.component.html',
  styleUrls: ['./row-ranking.component.scss'],
})
export class RowRankingComponent implements OnInit {
  public user: any;

  constructor(
    private modalService: NgbModal,
    private gamificationService: GamificationService,
    private toast: ToastrService
  ) {}

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
  }

  gotoRanking() {
    const { fechaInicial, fechaFinal } = this.obtenerFechasDelMes();
    this.gamificationService
      .getRanking(fechaInicial, fechaFinal)
      .subscribe((res: RankingSellers[]) => {
        if (res.length === 0) {
          this.toast.info('No hay datos para mostrar', 'Informaci\xf3n');
        } else {
          this.gamificationService.rankingSellers = res;
          this.modalService.open(ModalRankingComponent, {
            size: 'lg',
            centered: true,
            backdrop: 'static',
          });
        }
      });
  }

  private obtenerFechasDelMes() {
    const fechaActual = new Date(); // Obtener la fecha actual
    const primerDiaDelMes = new Date(
      fechaActual.getFullYear(),
      fechaActual.getMonth(),
      1
    ); // Primer día del mes
    const ultimoDiaDelMes = new Date(
      fechaActual.getFullYear(),
      fechaActual.getMonth() + 1,
      0
    ); // Último día del mes

    return {
      fechaInicial: this.formatearFecha(primerDiaDelMes),
      fechaFinal: this.formatearFecha(ultimoDiaDelMes),
    };
  }

  private formatearFecha(date) {
    // Extraer el año, mes y día del objeto Date
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Se agrega 1 al mes ya que los meses van de 0 a 11
    const day = ('0' + date.getDate()).slice(-2);

    // Combinar los valores en el formato deseado
    const fechaFormateada = `${year}/${month}/${day}`;

    return fechaFormateada;
  }
}
