import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import auth from 'firebase/app';
import { ToastrService } from 'ngx-toastr';

interface CustomWindow extends Window {
  intercomSettings?: {
    api_base: string;
    app_id: string;
    user_id: string;
    name: string;
    email: string;
  };
}

declare var window: CustomWindow;
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // Todos unsubscriptos

  url: string;

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private httpClient: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private toast: ToastrService
  ) {
    this.url = baseUrl + 'users/self';
  }

  async login(email: string, password: string, callback: () => void) {
    const res = await this.afAuth.signInWithEmailAndPassword(email, password);
    const getSelfSubscribe = this.getSelfUserData(res.user.uid).subscribe(
      (res: any) => {
        localStorage.setItem('rol', res.userType);
        // localStorage.setItem('rol', 'ADMIN');
        localStorage.setItem('uuid', res.uid);
        localStorage.setItem('user', JSON.stringify(res));
        window.intercomSettings = {
          api_base: 'https://api-iam.intercom.io',
          app_id: 'uo5j6xhy',
          user_id: res.uid,
          name: `${res?.firstName} ${res?.lastName}`,
          email: res.email,
        };
        callback();
        getSelfSubscribe.unsubscribe();
      },
      (error) => {
        console.error('EROR LOGIN');
      }
    );
    // await this.addUserDataToLocalStorage(callback);
  }

  async loginGoogle() {
    this.registerWithGoogle();
  }

  async registerWithGoogle() {
    const authResult = await this.afAuth.signInWithPopup(
      new auth.auth.GoogleAuthProvider()
    );

    const httpCall = this.httpClient
      .post(
        `${this.baseUrl}users/signup/google?firebaseId=${authResult.user?.uid}`,
        {}
      )
      .subscribe(
        (res: any) => {
          localStorage.setItem('rol', res.userType);
          // localStorage.setItem('rol', 'ADMIN');
          localStorage.setItem('uuid', res.uid);
          localStorage.setItem('user', JSON.stringify(res));

          if (authResult.additionalUserInfo?.isNewUser) {
            this.router.navigate(['/thankyou']);
          } else {
            this.router.navigate(['/dashboard']);
          }

          httpCall.unsubscribe();
        },
        (error) => {
          this.toast.error('Error al iniciar sesión con Google');
        }
      );
  }

  async logout() {
    try {
      localStorage.clear();
      await this.afAuth.signOut();
      await this.router.navigate(['']);
      localStorage.removeItem('user');
    } catch (error) {
      console.error(error);
    }
  }

  async resetPass(email: string) {
    try {
      return this.afAuth.sendPasswordResetEmail(email);
    } catch (error) {
      console.error(error);
    }
  }

  getSelfUserData(userId = 'UWloHN4MWsQ6okc058lOHurKKcD3'): Observable<any> {
    return this.httpClient.get(
      this.baseUrl + 'users/by-firebase-uid/' + userId,
      { headers: { user_id: userId } }
    );
  }

  getRole() {
    return localStorage.getItem('rol');
  }
}
