import {CreateCatalogModalComponent} from './create-catalog-modal/create-catalog-modal.component';
import {ProductDetailsModalComponent} from './product-details-modal/product-details-modal.component';
import {PublishEcommerceModalComponent} from './publish-ecommerce-modal/publish-ecommerce-modal.component';
import {DeleteCatalogModalComponent} from './delete-catalog-modal/delete-catalog-modal.component';
import {OrderModalComponent} from './order-modal/order-modal.component';
import {LinkDiscountCouponsModalComponent} from './link-discount-coupons-modal/link-discount-coupons-modal.component';
import {PublishCatalogModalComponent} from './publish-catalog-modal/publish-catalog-modal.component';
import { ModalRankingComponent } from './modal-ranking/modal-ranking.component';

export default {
  'create-catalog-modal': CreateCatalogModalComponent,
  'product-details-modal': ProductDetailsModalComponent,
  'publish-ecommerce-modal': PublishEcommerceModalComponent,
  'delete-catalog-modal': DeleteCatalogModalComponent,
  'order-modal': OrderModalComponent,
  'link-discount-coupons-modal': LinkDiscountCouponsModalComponent,
  'publish-catalog-modal': PublishCatalogModalComponent,
  'modal-ranking': ModalRankingComponent
};
