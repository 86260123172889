import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getUrl() {
  return environment.url;
}

export function getBaseImgUrl() {
  return environment.baseImgUrl;
}

export function getLanguage() {
  return environment.language;
}

export function getFrontOfficeBaseUrl() {
  return environment.frontOfficeBaseUrl;
}

export function getCountry() {
  return environment.country;
}

export function getCoupunsEnable() {
  return environment.coupunsEnable;
}

export function getBeeSellerRevenue(): number {
  return Number(environment.beeSellerRevenue);
}

export function getIVA(): number {
  return Number(environment.IVA);
}

const providers = [
  { provide: 'BASE_URL', useFactory: getUrl, deps: [] },
  { provide: 'BASE_IMG_URL', useFactory: getBaseImgUrl, deps: [] },
  {
    provide: 'FRONT_OFFICE_BASE_URL',
    useFactory: getFrontOfficeBaseUrl,
    deps: [],
  },
];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
