import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-emotic',
  templateUrl: './emotic.component.html',
  styleUrls: ['./emotic.component.scss']
})
export class EmoticComponent implements OnInit {
  @Input()
  emoji: number = 0;

  constructor() { }

  ngOnInit(): void {
  }
}
