import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { Image } from '@ks89/angular-modal-gallery';
import { ProductsService } from '../../../../services/products/products.service';
import { Product } from '../../../../model/Product';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from '../../../../model/User';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
  providers: [NgbRatingConfig]
})
export class ProductDetailComponent implements OnInit, OnDestroy {
  public closeResult: string;
  public counter = 1;
  public imagesRect: Image[] = [
    new Image(0, { img: 'assets/images/pro3/2.jpg' }, { img: 'assets/images/pro3/1.jpg' }),
    new Image(1, { img: 'assets/images/pro3/27.jpg' }, { img: 'assets/images/pro3/27.jpg' }),
    new Image(2, { img: 'assets/images/pro3/1.jpg' }, { img: 'assets/images/pro3/1.jpg' }),
    new Image(3, { img: 'assets/images/pro3/2.jpg' }, { img: 'assets/images/pro3/2.jpg' })];
  public producto: Product;
  private subscriptions: Subscription = new Subscription();

  constructor(private modalService: NgbModal,
    config: NgbRatingConfig,
    private productService: ProductsService,
    private route: ActivatedRoute) {
    config.max = 5;
    config.readonly = false;
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  ngOnInit() {
    const user: User = JSON.parse(localStorage.user); // TODO: mejorar esto y tratar de no ir al LocalStorage y parsear
    this.subscriptions.add(
      this.route.params.subscribe(params => {
        this.subscriptions.add(this.productService.getProductById(params.uuid, user.country).subscribe((res: Product) => {
          this.producto = res;
          this.producto.catalogDescrip = this.producto.catalogDescrip.replace(/\n/g, '<br>');
        }));
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  porcentajeDeGanancia(precioPesos: string) {
    return (0.15 * Number(precioPesos)).toFixed(2);
  }

  costoDeVenta(precio: number) {
    return (0.15 * precio + precio).toFixed(2);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
