import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportsComponent } from './reports.component';
import { TopProductsComponent } from './top-products/top-products.component';


const routes: Routes = [
  {
    path: '',
    component: ReportsComponent,
    data: {
      title: "Reports",
      breadcrumb: "Reports"
    },
    // children: [

    // ]
  },
  {
    path: 'tops',
    component: TopProductsComponent,
    data: {
      title: "tops",
      breadcrumb: "tops"
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
