<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">
      {{ "Orders.order.title" | translate }} N° {{ order.orderId }}
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5>{{ "Orders.order.name" | translate }} {{ order.contactFullName }}</h5>
    <h5>
      {{ "Orders.order.address" | translate }} {{ order.deliveryAddress }} ,
      {{ "Orders.order.door" | translate }} {{ order.deliveryDoorNumber }},
      {{ order.deliveryLocation }} , {{ order.deliveryDepartment }}
    </h5>
    <h5>
      {{ "Orders.order.postalCode" | translate }}
      {{ order.deliveryPostalCode }}
    </h5>
    <h5>{{ "Orders.order.phone" | translate }} {{ order.contactPhone }}</h5>
    <h5>{{ "Orders.order.email" | translate }} {{ order.contactMail }}</h5>
    <h5>
      {{ "Orders.order.date" | translate }}
      {{ order.createdAt | date : "dd/MM/yyy, hh:mm:ss a" }}
    </h5>
    <hr />
    <div class="price-details">
      <h6>{{ "Orders.order.products" | translate }}</h6>
      <div *ngFor="let item of order.itemList">
        <p>SKU: {{ item.sku }}, $ {{ item.price }} x {{ item.quantity }}</p>
      </div>
      <hr />
      <h5>Sub Total: $ {{ subTotal.toFixed(2) }}</h5>
      <h6 *ngIf="country === 'UY'">
        - {{ "Orders.order.percentage" | translate }} ({{
          this.order.vendorProfitPercentage
        }}
        %): $ {{ this.vendorProfit.toFixed(2) }}
      </h6>
      <h6 *ngIf="this.order.discountCouponPercentage != null">
        - {{ "Orders.order.coupon" | translate }}
        {{ this.order.discountCouponPercentage }} % (subtotal +
        {{ "Orders.order.profit" | translate }}): $ -
        {{ this.discount.toFixed(2) }}
      </h6>
      <h5 *ngIf="country === 'UY'">
        {{ "Orders.order.vendor-profit" | translate }} $
        {{ this.sellerProfit.toFixed(2) }}
      </h5>
      <h5 *ngIf="country === 'UY'">
        {{ "Orders.order.beeseller-profit" | translate }} $
        {{ this.beeSellerProfit.toFixed(2) }}
      </h5>
    </div>
  </div>
  <div class="modal-footer">
    <h4>
      {{ "Orders.order.total" | translate }} $ {{ order.totalPrice.toFixed(2) }}
    </h4>
  </div>
</div>
