import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SharedService} from '../../../../services/shared/shared.service';
import {Router} from '@angular/router';
import {CatalogService} from '../../../../services/catalog/catalog.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-delete-catalog-modal',
  templateUrl: './delete-catalog-modal.component.html',
  styleUrls: ['./delete-catalog-modal.component.scss']
})
export class DeleteCatalogModalComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  constructor(public activeModal: NgbActiveModal, public sharedService: SharedService, private router: Router,
              private catalogService: CatalogService,
              private toastr: ToastrService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  deleteCatalog() {
    const deleteSubscription = this.catalogService.deleteCatalog(this.sharedService.catalogToDelete).subscribe((res: any) => {
      this.activeModal.dismiss();
      this.toastr.success(this.translate.instant('Toast.catalog.deleted'),
        this.translate.instant('Toast.success'), {progressBar: true, timeOut: 3000});
      this.router.navigate(['/catalogs/catalog-list']).then();
    });

    this.subscriptions.add(deleteSubscription);
  }

  cancel() {
    this.activeModal.dismiss();
    this.router.navigate(['/catalogs/catalog-list']).then();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
