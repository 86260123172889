import {Component, OnInit} from '@angular/core';
import {Form, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {DefaultSetting, DefaultSettingProduct} from 'src/app/model/default-setting';
import {ProductsService} from 'src/app/services/products/products.service';
import {SettingService} from 'src/app/services/setting/setting.setvice';
import { getCountry } from 'src/main';

@Component({
  selector: 'app-catalog-default',
  templateUrl: './catalog-default.component.html',
  styleUrls: ['./catalog-default.component.scss']
})
export class CatalogDefaultSettingComponent implements OnInit {

  currentPage = 1;
  pageSize = 25;
  defaultSetting: DefaultSetting = {} as DefaultSetting;
  products: DefaultSettingProduct[] = [];
  productsFilter: DefaultSettingProduct[] = [];
  country = getCountry();

  settingForm: FormGroup = this.fb.group({
    percentage: [this.defaultSetting.percentage, [Validators.min(10), Validators.max(100)]],
    catalogName: [this.defaultSetting.catalogName, [Validators.minLength(1)]],
    sku: ['', [Validators.minLength(1)]],
  });
  prducts: any;

  constructor(
    private settingService: SettingService,
    private productService: ProductsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.settingService.getDefaultSetting().subscribe((defaultSetting) => {
      this.defaultSetting = defaultSetting;
      this.getProducts();
      this.resetForm();
    });
    this.settingForm.statusChanges.subscribe((status) => {

    });
  }

  togleBtns() {

  }

  resetForm() {
    this.settingForm = this.fb.group({
      percentage: [this.defaultSetting.percentage],
      catalogName: [this.defaultSetting.catalogName, [Validators.minLength(1)]],
      sku: ['', [Validators.minLength(1)]],
    });
  }

  onClickAddProduct() {
    const sku = this.settingForm.controls.sku.value;
    if (sku.trim() !== '') {
      this.addProductSku(sku);
      this.refreshProducts();
    }
  }

  onClickUpdateCatalogName() {
    const catalogName = this.settingForm.controls.catalogName.value;
    if (catalogName.trim() !== '') {
      this.settingService.updateCatalogName(catalogName).subscribe(() => {
        this.defaultSetting.catalogName = catalogName;
        this.toastr.success('Nombre de catálogo actualizado');
      }, (error) => {
        this.toastr.error('Error al actualizar el nombre del catálogo');
      });
    }
  }

  onClickUpdatePercentage() {
    const percentage = this.settingForm.controls.percentage.value;
    if (percentage !== 0) {
      this.updatePercentage(percentage);
    }
  }

  getProducts() {
    return this.defaultSetting.products.forEach((sku) => {
      if (sku.trim() !== '') {
        this.getProductSku(sku);
      }
    });
  }

  refreshProducts() {
    this.products = this.products.map((product, i) => ({id: i + 1, ...product}));
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.productsFilter = this.products.slice(startIndex, endIndex);
  }

  updateProductMonth(productMonth: string) {
    this.settingService.updateProductMonth(productMonth).subscribe(() => {
      this.defaultSetting.productMonth = productMonth;
      this.toastr.success('Mes de producto actualizado');
    }, (error) => {
      this.toastr.error('Error al actualizar el mes de producto');
    });
  }

  updatePercentage(percentage: number) {
    this.settingService.updatePercentage(percentage).subscribe(() => {
      this.defaultSetting.percentage = percentage;
      this.toastr.success('Porcentaje actualizado');
      this.settingForm.controls.percentage.setValue(percentage);
    }, (error) => {
      this.toastr.error('Error al actualizar el porcentaje');
    });
  }

  addProductSku(sku: string) {
    const products: Set<string> = this.createSetProducts();

    if (products.has(sku)) {
      this.toastr.error('El producto ya existe');
      return;
    }

    this.defaultSetting.products.push(sku);
    this.settingService.updateProducts(this.defaultSetting.products).subscribe(() => {
      this.toastr.success('Producto agregado');
      this.getProductSku(sku);
    }, (error) => {
      this.toastr.error('Error al agregar el producto');
    });
  }

  createSetProducts() {
    const products: Set<string> = new Set<string>();
    this.defaultSetting.products.forEach((product) => {
      if (product.trim() !== '') {
        products.add(product);
      }
    });
    return products;
  }

  removeProductSku(sku: string) {
    const index = this.defaultSetting.products.indexOf(sku);

    if (index > -1) {
      this.defaultSetting.products.splice(index, 1);
    }

    this.settingService.updateProducts(this.defaultSetting.products).subscribe(() => {
      this.products = this.products.filter((product) => product.sku !== sku);
      this.toastr.success('Producto eliminado');
    }, (error) => {
      this.toastr.error('Error al eliminar el producto');
    });
  }

  getProductSku(sku: string) {
    this.productService.getProductBySku(sku).subscribe((product) => {
      this.products.push({
        sku: product.sku,
        image: product.images[0],
        title: product.artNombreMl,
        description: product.catalogDescrip,
        price: product.pricePesos,
        stock: product.currentStock,
        category: product?.categories?.[0]?.name || 'Sin categoría',
      });
      this.refreshProducts();
    }, (error) => {
      this.toastr.error('Error al obtener el producto');
    });
  }
}

