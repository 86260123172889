import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { RankingSellers } from 'src/app/model/ranking-sellers';

@Injectable({
  providedIn: 'root'
})
export class GamificationService {
  url: string;
  rankingSellers: RankingSellers[] = [];

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private translate: TranslateService,
    private toastr: ToastrService) {
    this.url = baseUrl + 'gamification/ranking/top10-profiles';
  }

  getRanking(date_ini: string, date_end: string) {
    return this.httpClient.get(`${this.url}?startDate=${date_ini}&endDate=${date_end}`);
  }
}
