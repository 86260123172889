import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {SharedService} from '../../services/shared/shared.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  modalId: Subject<string>;

  constructor(private sharedServices: SharedService) { }

  ngOnInit(): void {
    this.modalId = this.sharedServices.modalId;
  }
}
