import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Product } from '../../model/Product';
import { map } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { getCountry } from 'src/main';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  // Todos unsubscriptos

  url: string;
  private readonly baseImgUrl: string;

  constructor(
    private httpClient: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    @Inject('BASE_IMG_URL') baseImgUrl: string
  ) {
    this.baseImgUrl = baseImgUrl;
    this.url = baseUrl + 'products_api/products';
  }

  getProducts(filter: any): Observable<{ total: number, products: Product[] }> {
    return this.httpClient.get<{ total: number, products: Product[] }>(this.url, { params: filter })
      .pipe(map(page => {
        page.products = page.products.map(product => {
          // product.catalogDescrip = product.catalogDescrip.length >= 36 ? `${product.catalogDescrip.substring(0, 35)} ...` : product.catalogDescrip;
          product.images = product.images.map(imgPath => this.imagePath(imgPath));

          return product;
        });

        return page;
      })
      );
  }

  getProductById(id: string, country: string): Observable<Product> {
    return this.httpClient.get<Product>(this.url + '/' + id, { headers: { 'Store-Country': country } })
      .pipe(map(product => {
        product.images = product.images.map(imgPath => this.imagePath(imgPath));
        return product;
      }));
  }
  getProductBySku(sku: string): Observable<Product> {
    return this.httpClient.get<Product>(`${this.url}/${sku}`, { headers: { 'Store-Country': getCountry() } })
      .pipe(map(product => {
        product.images = product.images.map(imgPath => this.imagePath(imgPath));
        return product;
      }));
  }

  imagePath(imgPath: string): string {
    return `${this.baseImgUrl.replace(':imgPath', imgPath)}`;
  }
}
