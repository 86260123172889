import {
  Component,
  Injectable,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CONSTANTS } from '../../../utils/Constants';
import { Subscription } from 'rxjs';
import { UsersService } from '../../../services/users/users.service';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { AccountFormComponent } from '../singup/accountForm/accountForm.component';

import { AngularFireAuth } from '@angular/fire/auth';
import { getCountry } from 'src/main';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild(NgbTabset) tabs: NgbTabset;
  // @ViewChild(AccountFormComponent) accountForm: AccountFormComponent;

  public accountInfo: any;
  public loginForm: UntypedFormGroup;
  public user: any;
  public errorMessage = '';
  public subscriptions = new Subscription();
  public readonly defaultDocumentType = 'invalid';
  public documentType = this.defaultDocumentType; // Valor dummy para que quede seleccionado el "placeholder".
  public disableForm1 = 0;

  loadingLogin = false;
  public show = false;

  country = getCountry();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    private toast: ToastrService,
    private translate: TranslateService,
    private usersService: UsersService
  ) {
    this.createLoginForm();
    this.user = { email: '', password: '' };
  }

  singInWithGoogle() {
    this.authService.loginGoogle();
  }

  updateDisableForm1($event) {
    this.disableForm1 = $event.disableForm;
    this.accountInfo = $event.accountInfo;
  }

  showPassword() {
    this.show = !this.show;
  }

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  checkPasswords: ValidatorFn = (
    group: AbstractControl
  ): ValidationErrors | null => {
    const pass = group.get('password').value;
    const confirmPass = group.get('repeatPassword').value;
    return pass === confirmPass ? null : { notSame: true };
  };

  ngOnInit() {
    if (this.authService.getRole() !== null) {
      this.router.navigate(['/dashboard']).then().catch(console.error);
    }
  }

  async login(formLogin: UntypedFormGroup) {
    this.loadingLogin = !this.loadingLogin;
    if (!formLogin.valid) {
      this.toast.error(
        this.translate.instant('Login.invalid-form'),
        this.translate.instant('Toast.error')
      );
      this.loadingLogin = !this.loadingLogin;
      return;
    }

    this.user.email = this.loginForm.value.email;
    this.user.password = this.loginForm.value.password;
    await this.loginAuthService(this.user.email, this.user.password);
  }

  async loginAuthService(mail: string, pass: string) {
    try {
      const navigate = () => {
        this.router.navigate(['/dashboard']).then().catch(console.error);
      };
      await this.authService.login(mail, pass, navigate);
      this.loadingLogin = !this.loadingLogin;
    } catch (error) {
      this.loadingLogin = !this.loadingLogin;
      this.toast.error(
        this.translate.instant('Login.wrong-userpass'),
        this.translate.instant('Toast.error')
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
