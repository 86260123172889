import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingComponent } from './setting.component';
import { DefaultSettingComponent } from './default-setting/default-setting.component';


const routes: Routes = [
  {
    path: '',
    component: SettingComponent
  },
  {
    path: 'default',
    component: DefaultSettingComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingRoutingModule { }
