import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OrderStatistics } from 'src/app/model/order-statistics';

@Component({
  selector: 'app-row-progress',
  templateUrl: './row-prgress.component.html',
  styleUrls: ['./row-prgress.component.scss']
})
export class RowProgressComponent implements OnInit, OnChanges {

  @Input()
  progress: OrderStatistics = {} as OrderStatistics;

  emojiProfit: number = 0;
  emojiCompeted: number = 0;
  emojiPending: number = 0;
  constructor() { }

  ngOnInit(): void {
    this.settingEmojiPtofit();
    this.emojiCompeted = this.settingEmojiOrders(this.progress.completed);
    this.emojiPending = this.settingEmojiOrders(this.progress.pending);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.progress) {
      this.settingEmojiPtofit();
      this.emojiCompeted = this.settingEmojiOrders(this.progress.completed);
      this.emojiPending = this.settingEmojiOrders(this.progress.pending);
    }
  }

  settingEmojiOrders(input: number = 0) {
    if (input >= 0 && input < 1) {
      return 0;
    } else if (input >= 1 && input < 3) {
      return 1;
    } else if (input >= 2 && input < 5) {
      return 2;
    } else if (input >= 5 && input < 10) {
      return 3;
    } else if (input >= 10) {
      return 4;
    }
  }

  settingEmojiPtofit() {
    if (this.progress.profits == 0 || this.progress.profits <= 100) {
      this.emojiProfit = 0;
    } else if (this.progress.profits >= 100 && this.progress.profits < 200) {
      this.emojiProfit = 1;
    } else if (this.progress.profits >= 200 && this.progress.profits < 500) {
      this.emojiProfit = 2;
    } else if (this.progress.profits >= 500 && this.progress.profits < 1000) {
      this.emojiProfit = 3;
    } else if (this.progress.profits >= 1000) {
      this.emojiProfit = 4;
    }
  }
}
