<div class="card">
  <div class="svg-icon" *ngIf="disableForm1 === 0">
    <img [width]="85" alt="" src="../../../../assets/images/BeeSeller-07.png" />
  </div>
  <div class="svg-icon2" *ngIf="disableForm1 > 0">
    <img [width]="170" alt="" src="../../../../assets/images/BeeSeller-07.png" />
  </div>
  <div class="single-item" *ngIf="disableForm1 === 0">
    <h3>{{'Login.title' | translate}}</h3>
    <p class="mt-4">{{'Login.desc' | translate}}</p>
  </div>
  <div class="single-item" *ngIf="disableForm1 === 1">
    <h3>{{'Login.acountData' | translate}}</h3>
  </div>
  <div class="single-item" *ngIf="disableForm1 === 2">
    <h3>{{'Login.personalInfo' | translate}}</h3>
  </div>
</div>