<div class="card bee-seller-bg-black">
  <div class="row justify-content-around px-3 dashboard">
      <div
        class="card border border-warning rounded-3 border-2 bee-seller-bg-black"
      >
        <div class="card-body ranking">
          <!-- <div class="row"> -->
          <div
            class="background-perfil d-flex justify-content-around align-items-center"
          >
            <img
              class="img-perfil"
              src="{{ user?.image || 'assets/images/user-generic.png' }}"
              alt="#"
            />
          </div>

          <div class="mt-3 text-center">
            <h3 class="text-uppercase">
              {{ user?.firstName }} {{ user?.lastName }}
            </h3>
            <strong class="text-lowercase">{{ user?.email }}</strong>
          </div>

          <div class="row mt-3 justify-content-end flex-column align-items-center">
            <div class="d-flex flex-column align-items-center">
              <h4 class="text-uppercase text-start">Seller Ranking</h4>
              <strong class="text-capitalize text-start"
                >Top 10 mejores del mes</strong
              >
            </div>
            <div
              class="d-flex justify-content-around align-items-center paddingtop2rem"
              (click)="gotoRanking()"
            >
              <img
                class="img-container ranking-coupe"
                src="https://firebasestorage.googleapis.com/v0/b/landing-ppgg-test.appspot.com/o/icons%2Ficonotrofeo.png?alt=media&token=64fa5853-49fd-4737-b25b-a8d6f1682d35"
                alt="#"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="h-100">
      <div
        class="card border border-warning rounded-3 border-2 bee-seller-bg-black"
      >
        <div class="card-body">
          <div class="row d-flex flex-column flex-nowrap flex-sm-row paddingbottom05rem">
            <div class="center-icon">
              <img
                class="img-container img-mobile"
                src="https://firebasestorage.googleapis.com/v0/b/landing-ppgg-test.appspot.com/o/icons%2FIconoGoal.png?alt=media&token=a282efac-faaf-494f-9ad8-af6be2e41ef7"
                alt=""
                srcset=""
              />
            </div>
            <div class="col-xl-8 col-md-10 mb-3 center-icon">
              <h3 class="text-uppercase text-start center-text">Objetivo Actual</h3>
              <h4 class="text-capitalize text-start">Proximamente</h4>
              <h4 class="text-capitalize text-start">Disponible</h4>
              <!--<h4 class="text-capitalize text-start">Pagar Netflix</h4>
              <strong class="text-capitalize text-start fs-1"
                >Ganancias a generar: $ 341</strong
              >-->
            </div>
          </div>
          <div class="row justify-content-around align-items-center">
            <button disabled class="btn btn-primary w-75">Editar</button>
          </div>
        </div>
      </div>
      <div class="card bee-seller-bg-black">
        <div class="card-body">
          <div class="row d-flex flex-column flex-nowrap flex-sm-row align-items-center">
            <div class="center-icon">
              <img
                class="img-container img-mobile"
                src="https://firebasestorage.googleapis.com/v0/b/landing-ppgg-test.appspot.com/o/icons%2Fdiamond3.png?alt=media&token=d16ebc04-295b-406b-8be9-dd31b0217c86"
                alt=""
              />
            </div>
            <div class="col-xl-8 col-md-10 mb-3 center-icon">
              <h3 class="text-uppercase text-start center-text">Desafios</h3>
              <h4 class="text-capitalize text-start">Proximamente</h4>
              <h4 class="text-capitalize text-start">Disponible</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="separation"></div>
    <div class="paddingtop16rem">
      <div class="">
        <h3 class="text-uppercase text-center">Progreso</h3>
      </div>
      <div class="card bee-seller-bg-black mt-0 mb-3">
        <div class="card-body">
          <div class="row d-flex flex-column flex-nowrap flex-sm-row align-items-center">
            <div class="center-icon">
              <img
                class="img-container img-mobile"
                src="https://firebasestorage.googleapis.com/v0/b/landing-ppgg-test.appspot.com/o/icons%2FIconoGoal.png?alt=media&token=85ad06c2-ccfd-4d43-9770-c663826b6acb"
                alt=""
                srcset=""
              />
            </div>
            <div class="col-xl-8 col-md-10 mb-3 center-icon">
              <h3 class="text-uppercase text-start center-text">
                Progreso objetivo actual
              </h3>
            </div>
          </div>
        </div>
        <div class="card bee-seller-bg-black mt-3">
          <div class="card-body">
            <div class="row d-flex flex-column flex-nowrap flex-sm-row align-items-center">
              <div class="center-icon">
                <img
                  class="img-container img-mobile"
                  src="https://firebasestorage.googleapis.com/v0/b/landing-ppgg-test.appspot.com/o/icons%2Fdiamondonly.png?alt=media&token=d2142dd2-bed7-495a-b98f-d99a9dac4ff1"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="col-xl-8 col-md-10 col-sm-8 center-icon">
                <h3 class="text-uppercase text-start center-text">Progreso Desafio</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
