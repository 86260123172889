<div class="container-fluid">
  <div class="card">
    <div class="row product-page-main card-body">
      <div class="col-xl-4">
        <section>
          <ks-carousel
            [id]="100"
            [images]="imagesRect"
            [dotsConfig]="{ visible: false }"
            [carouselConfig]="{ maxHeight: '600px', keyboardEnable: true }"
            [previewConfig]="{ maxHeight: '125px' }"
          >
          </ks-carousel>
        </section>
      </div>
      <div class="col-xl-8">
        <div class="product-page-details product-right mb-0">
          <h2>{{ this.producto.artNombreMl }}</h2>
          <ngb-rating [rate]="3" class="rating-size-product"></ngb-rating>
          <hr />
          <h6 class="product-title">
            {{ "Modal-Product.description-product" | translate }}
          </h6>
          <p
            class="mb-0"
            style="font-size: medium"
            [innerHTML]="this.producto.catalogDescrip"
          ></p>
          <h6 class="product-title mt-4">
            {{ "Product-details.packing-measure" | translate }}
          </h6>
          <p class="mb-0" style="font-size: medium">17 x 19 x 9 cm</p>
          <div class="product-price digits mt-4">
            <h5>
              {{ "Product-details.cost-ppgg" | translate }} ${{
                this.producto.pricePesos
              }}
              <!--<del>$350.00</del>-->
            </h5>
            <h5>
              {{ "Product-details.selling-price" | translate }} ${{
                this.costoDeVenta(this.producto.pricePesos)
              }}
              <!--<del>$350.00</del>-->
            </h5>
          </div>
          <!--<ul class="color-variant">
              <li class="bg-light0"></li>
              <li class="bg-light1"></li>
              <li class="bg-light2"></li>
          </ul>
          <hr>
          <h6 class="product-title size-text">elegir talle
              <span class="pull-right">
                  <a data-toggle="modal" (click)="open(content)" class="font-primary">guia de talles</a>
              </span>
          </h6>
          <ng-template #content let-modal>
              <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Sheer Straight Kurta</h5>
                  <button type="button" class="close" aria-label="Close"
                      (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                  <img src="assets/images/size-chart.jpg" alt="" class="img-fluid blur-up lazyloaded">
              </div>
          </ng-template>
          <div class="size-box">
              <ul>
                  <li class="active"><a href="javascript:void(0)">s</a></li>
                  <li><a href="javascript:void(0)">m</a></li>
                  <li><a href="javascript:void(0)">l</a></li>
                  <li><a href="javascript:void(0)">xl</a></li>
              </ul>
          </div>
          <div class="add-product-form">
              <h6 class="product-title">Cantidad</h6>
              <div class="qty-box1">
                  <div class="input-group">
                      <i class="fa fa-minus btnGtr1" (click)=decrement()></i>
                      <input class="touchspin1 text-center" name="counter" [value]="counter" type="text">
                      <i class="fa fa-plus btnLess1" (click)=increment()></i>
                  </div>
              </div>
          </div>
          <hr>
          <h6 class="product-title">Time Reminder</h6>
          <div class="timer">
              <p id="demo"><span>25 <span class="padding-l">:</span> <span class="timer-cal">Days</span>
                  </span><span>22 <span class="padding-l">:</span> <span class="timer-cal">Hrs</span>
                  </span><span>13 <span class="padding-l">:</span> <span class="timer-cal">Min</span>
                  </span><span>57 <span class="timer-cal">Sec</span></span>
              </p>
          </div>
          <div class="m-t-15">
              <button class="btn btn-primary m-r-10" type="button">Add To Cart</button>
              <button class="btn btn-secondary" type="button">Buy Now</button>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>
