import { HostListener, Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WINDOW } from './windows.service';
import { getCoupunsEnable } from 'src/main';

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  show?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root',
})
export class NavService {
  public screenWidth: any;
  public collapseSidebar = false;
  MENUITEMSADMIN: Menu[] = [
    {
      path: '/users/list-user',
      title: 'Sidebar.users',
      icon: 'user',
      type: 'link',
      active: false,
      show: true,
    },
    {
      path: '/reports',
      title: 'Reports',
      icon: 'bar-chart',
      type: 'link',
      active: false,
      show: true,
    },
    {
      path: '/settings/default',
      title: 'Configuracion por defecto',
      icon: 'settings',
      type: 'link',
      active: false,
      show: true,
    },
  ];
  MENUITEMSVENDOR: Menu[] = [
    {
      path: '/catalogs/catalog-list',
      title: 'Sidebar.catalogs',
      icon: 'shopping-bag',
      type: 'link',
      active: false,
      show: true,
    },
    {
      path: '/coupons/list-coupons',
      title: 'Sidebar.coupons',
      icon: 'gift',
      type: 'link',
      active: false,
      show: getCoupunsEnable(),
    },
    {
      path: '/settings',
      title: 'Sidebar.settings',
      icon: 'settings',
      type: 'link',
      active: false,
      show: true,
    },
    {
      path: '/help',
      title: 'Sidebar.help',
      icon: 'help-circle',
      type: 'link',
      active: false,
      show: true,
    },
  ];

  MENUITEMS: Menu[] = [
    /* {
       path: '/dashboard',
       title: 'Dashboard.title',
       icon: 'home',
       type: 'link',
       badgeType: 'primary',
       active: false
     },
     {
       path: '/orders',
       title: 'Sidebar.orders',
       icon: 'check-square',
       type: 'link', active: false
     },*/
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMSVENDOR);

  constructor(@Inject(WINDOW) private window) {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true;
    }
  }

  renderMenu() {
    this.MENUITEMS = [
      {
        path: '/dashboard',
        title: 'Dashboard.title',
        icon: 'home',
        type: 'link',
        badgeType: 'primary',
        active: false,
        show: true,
      },
      {
        path: '/orders',
        title: 'Sidebar.orders',
        icon: 'check-square',
        type: 'link',
        active: false,
        show: true,
      },
    ];
    if (localStorage.rol === 'ADMIN') {
      this.MENUITEMS.push(...this.MENUITEMSADMIN);
    } else {
      this.MENUITEMS.push(...this.MENUITEMSVENDOR);
    }
    this.items.next(this.MENUITEMS);
  }

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }
}
