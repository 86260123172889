import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {CatalogPublished} from '../../model/CatalogPublished';
import {Store} from '../../model/Store';
import {StoreService} from '../store/store.service';
import {Order} from '../../model/Order';
import {Catalog} from '../../model/Catalog';
import { Category } from 'src/app/model/Category';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  // Todos unsubscriptos

  modalId: Subject<string> = new Subject<string>();
  selectedProductId: string;
  triggeredFrom: string;
  profitPercentage: string;
  productChecked: boolean;
  rowData: any;
  ecommercePublished: boolean;
  ecommerceUrl: string;
  catalogPublished: CatalogPublished;
  catalogToDelete: string;
  catalogId: string;
  orderSelected: Order;

  // TODO: sacar de este servicio la siguiente property
  catalogToLinkToCoupons: Catalog;

  categories: Category[];

  constructor(public storeService: StoreService) {
  }

  getStoreByVendor() {
    const soreSubscription = this.storeService.getStoreByVendor(localStorage.getItem('uuid')).subscribe((res: Store) => {
      this.ecommercePublished = res.enabled;
      this.ecommerceUrl = res.uri;
      this.catalogPublished = res.catalog;

      soreSubscription.unsubscribe();
    });
  }
}
