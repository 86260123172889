import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  SearchOrderImp,
  SearchOrdersDto,
} from '../../model/dto/SearchOrdersDto';
import { SalesOrder } from '../../model/SalesOrder';
import { CatalogPublished } from 'src/app/model/CatalogPublished';
import { CreateCatalogInStore } from 'src/app/model/catalog-in-store';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  // Todos unsubscriptos

  private readonly url: string;
  private readonly tempUrl: string;
  private readonly searchUrl: string;
  private readonly orderStaticsUrl: string;

  private skus: BehaviorSubject<Set<string>> = new BehaviorSubject<Set<string>>(
    new Set()
  );
  hasSkus: boolean = false;

  constructor(
    private httpClient: HttpClient,
    @Inject('BASE_URL') baseUrl: string
  ) {
    this.tempUrl = baseUrl + 'orders/tmp/orders';
    this.url = baseUrl + 'microsite';
    this.searchUrl = baseUrl + 'orders/search';
    this.orderStaticsUrl = baseUrl + 'orders/statics';
  }

  getSkus(): Observable<Set<string>> {
    return this.skus.asObservable();
  }

  setSkus(skus: Set<string>) {
    this.skus.next(skus);
    this.hasSkus = true;
  }

  isInCatalog(sku: string): Observable<boolean> {
    return this.skus
      .asObservable()
      .pipe(map((skus: Set<string>) => skus.has(sku)));
  }

  getStoreByVendor(vendorId: string) {
    return this.httpClient.get(this.url + '/by-vendor-uid/' + vendorId);
  }

  editStore(storeId: string, updatedStore: any, vendorId: string) {
    return this.httpClient.put(this.url + '/' + storeId, updatedStore, {
      headers: { userId: vendorId },
    });
  }

  getOrders(params?: string) {
    return this.httpClient.get(
      params ? `${this.tempUrl}${params}` : this.tempUrl
    );
  }

  searchOrders(search: SearchOrdersDto) {
    return this.httpClient.post(this.searchUrl, search);
  }

  getOrderStatics(storeUuid: string) {
    return this.httpClient.get(this.orderStaticsUrl + '/by-seller', {
      params: {
        storeUuid,
      },
    });
  }

  getGoogleAnalyticsReport(
    storeUuid: string,
    startDate: string,
    endDate: string
  ) {
    return this.httpClient.get(this.url + '/google-analytics', {
      params: {
        storeUuid,
        startDate,
        endDate,
      },
    });
  }

  getOrderAdminStatics() {
    return this.httpClient.get(this.orderStaticsUrl + '/by-seller');
  }

  getTopProducts() {
    return this.httpClient.get(this.orderStaticsUrl + '/top-products');
  }
  getTopUsers() {
    return this.httpClient.get(this.orderStaticsUrl + '/top-sellers');
  }

  linkDiscountCoupons(
    catalogUuid: string,
    body: { couponsData: { uuid: string; discountPercentage: number }[] }
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/catalog/${catalogUuid}/link-coupons`,
      body
    );
  }

  createCatalog(catlog: CreateCatalogInStore) {
    return this.httpClient.post(`${this.url}/catalog`, catlog);
  }

  getCatalog() {
    const userUuid = localStorage.getItem('uuid');
    return this.httpClient.get<CreateCatalogInStore>(
      `${this.url}/catalog/${userUuid}`
    );
  }

  updateCatalog(catalogUuid: string, catalog: CreateCatalogInStore) {
    return this.httpClient.put(`${this.url}/catalog/${catalogUuid}`, catalog);
  }

  addProductToCatalog(catalogUuid: string, skus: string[]) {
    return this.httpClient.put(
      `${this.url}/catalog/${catalogUuid}/add-products`,
      skus
    );
  }

  removeProductFromCatalog(catalogUuid: string, skus: string[]) {
    return this.httpClient.put(
      `${this.url}/catalog/${catalogUuid}/remove-products`,
      skus
    );
  }
}
