<form
  class="container-fluid text-capitalize text-color"
  [formGroup]="settingForm"
>
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-4">
          <label for="catalogName" class="form-label"
            >Nombre del catalogo</label
          >
          <input
            type="text"
            class="form-control w-100"
            id="catalogName"
            placeholder="Nombre catalogo"
            formControlName="catalogName"
          />
        </div>
        <div class="col-md-2 d-flex justify-content-center align-items-end">
          <button
            type="button"
            class="btn btn-primary"
            (click)="onClickUpdateCatalogName()"
          >
            Actualizar
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-6" *ngIf="country === 'UY'">
      <div class="row">
        <div class="col-md-4">
          <label for="porcentage" class="form-label"
            >Porcentage del catalogo</label
          >
          <input
            type="number"
            class="form-control"
            id="porcentage"
            placeholder="30"
            formControlName="percentage"
          />
        </div>
        <div class="col-md-2 d-flex justify-content-center align-items-end">
          <button
            type="button"
            class="btn btn-primary"
            (click)="onClickUpdatePercentage()"
          >
            Actualizar
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row my-3">
    <div class="col-md-6">
      <div>
        <h4>productos {{ productsFilter.length }}</h4>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-4">
          <label for="sku" class="form-label">SKU</label>
          <input
            type="text"
            class="form-control"
            id="sku"
            placeholder="SKU"
            formControlName="sku"
          />
        </div>
        <div class="col-md-2 d-flex justify-content-center align-items-end">
          <button
            type="button"
            class="btn btn-primary"
            (click)="onClickAddProduct()"
          >
            Agregar
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="row justify-content-center align-items-center">
        <table class="table table-striped text-color">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">SKU</th>
              <th scope="col">Imagen</th>
              <th scope="col">Titulo</th>
              <th scope="col">{{ "Modal-Product.description" | translate }}</th>
              <th scope="col">Precio de costo</th>
              <th scope="col">Stock</th>
              <th scope="col">{{ "Modal-Product.category" | translate }}</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of productsFilter; let index = index">
              <td scope="row">
                <span class="">{{ index + 1 }}</span>
              </td>
              <td scope="row">
                <span>{{ product.sku }}</span>
              </td>
              <td>
                <img
                  [src]="
                    product.image
                      ? product.image
                      : '../../../assets/images/digital-product/lecture-video-recorder.jpg'
                  "
                  alt="imagen del producto"
                  class="img-fluid img-thumbnail"
                />
              </td>
              <td>
                <span>{{ product.title }}</span>
              </td>
              <td>
                <span>{{ product.description | transformTextTag }}</span>
              </td>
              <td>
                <span>{{ product.price.toFixed(2) }}</span>
              </td>
              <td>
                <span>{{ product.stock }}</span>
              </td>
              <td>
                <span>{{ product.category }}</span>
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-danger"
                  (click)="removeProductSku(product.sku)"
                >
                  Eliminar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</form>
<div class="d-flex justify-content-start p-2">
  <ngb-pagination
    [collectionSize]="products.length"
    [(page)]="currentPage"
    [pageSize]="pageSize"
    (pageChange)="refreshProducts()"
  >
  </ngb-pagination>

  <select
    class="form-select"
    [(ngModel)]="pageSize"
    (ngModelChange)="refreshProducts()"
  >
    <option [ngValue]="25" [defaultSelected]="true">
      25 productos por pagina
    </option>
    <option [ngValue]="50">50 productos por pagina</option>
    <option [ngValue]="100">100 productos por pagina</option>
    <option [ngValue]="200">200 productos por pagina</option>
  </select>
</div>
