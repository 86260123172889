import { Component, OnDestroy, OnInit } from '@angular/core';
import * as chartData from '../../shared/data/chart';
import { doughnutData, pieData } from '../../shared/data/chart';
import { SharedService } from '../../services/shared/shared.service';
import { CatalogService } from '../../services/catalog/catalog.service';
import { CatalogPublished } from '../../model/CatalogPublished';
import { Store } from '../../model/Store';
import { StoreService } from '../../services/store/store.service';
import { Product } from '../../model/Product';
import { ProductsService } from '../../services/products/products.service';
import { Subscription } from 'rxjs';
import { Order, OrderItem, Status } from '../../model/Order';
import { SearchOrderImp } from 'src/app/model/dto/SearchOrdersDto';
import { SalesOrder } from 'src/app/model/SalesOrder';
import { CatalogPost } from 'src/app/model/CatalogPost';
import { OrderStatistics } from 'src/app/model/order-statistics';
import { SettingService } from 'src/app/services/setting/setting.setvice';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  pageCatalog = 1;
  pageMostSelled = 1;
  count = 0;
  pageSize = 5;

  userId: string;
  rol: string;
  catalog: CatalogPublished = null;
  productMonth: any = {};
  public user: any;
  private subscriptions = new Subscription();
  private storeUuid: string;
  ordersStatics: OrderStatistics = {
    completed: 0,
    pending: 0,
    profits: 0,
    canceled: 0
  };

  constructor(
    public catalogService: CatalogService,
    public sharedService: SharedService,
    public storeService: StoreService,
    public productService: ProductsService,
  ) {
    this.userId = localStorage.getItem('uuid');
    this.rol = localStorage.rol;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    if (this.rol !== 'ADMIN') {
      this.loadCatalog();
    }
    this.loadOrders();
  }

  loadCatalog() {
    this.subscriptions.add(
      this.storeService.getStoreByVendor(this.userId).subscribe((res: Store) => {
        this.catalog = res.catalog;
        this.storeUuid = res.uuid;
        localStorage.setItem('storeUuid', this.storeUuid);
        localStorage.setItem('storeUri', res.uri);
        localStorage.setItem('catalogUuid', this.catalog.uuid);
        localStorage.setItem('percentage', this.catalog.percentage.toString());
        if (this.catalog != null) {
          this.storeService.setSkus(new Set(this.catalog.skuProducts));
        }
      })
    );
  };

  loadOrders() {
    if (this.rol !== 'ADMIN') {
      this.subscriptions.add(this.storeService.getStoreByVendor(this.userId).subscribe((res: Store) => {
        this.storeUuid = res.uuid;
        this.storeService.getOrderStatics(this.storeUuid).subscribe((statistics: any) => {
          this.ordersStatics = statistics;
        });
      }));
    } else {
      this.storeService.getOrderAdminStatics().subscribe((statistics: any) => {
        this.ordersStatics = statistics;
      });
    }
  }
}
