import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  NgbActiveModal,
  NgbCarousel,
  NgbSlideEvent,
  NgbSlideEventSource,
} from '@ng-bootstrap/ng-bootstrap';
import { Product } from '../../../../model/Product';
import { SharedService } from '../../../../services/shared/shared.service';
import { ProductsService } from '../../../../services/products/products.service';
import { CatalogService } from '../../../../services/catalog/catalog.service';
import { Subscription } from 'rxjs';
import { User } from '../../../../model/User';
import { StoreService } from 'src/app/services/store/store.service';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import { getCountry } from 'src/main';

@Component({
  selector: 'app-product-details-modal',
  templateUrl: './product-details-modal.component.html',
  styleUrls: ['./product-details-modal.component.scss'],
})
export class ProductDetailsModalComponent implements OnInit, OnDestroy {
  paused = false;
  unpauseOnArrow = true;
  pauseOnIndicator = true;
  pauseOnHover = true;
  pauseOnFocus = true;

  loagin: boolean = true;

  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;

  images = [62, 83, 466, 965, 982, 1043, 738].map(
    (n) => `https://picsum.photos/id/${n}/900/500`
  );
  selectedProduct: Product = {} as Product;
  productImages: string[] = [];
  triggeredFrom: string;
  profitPercentage: string;
  productChecked: boolean;
  productImagesOptions = {
    items: 1,
    dots: false,
    mouseDrag: false,
    nav: true,
    navText: ['<', '>'],
  };
  imagesLoaded = false;
  private subscriptions = new Subscription();

  categories = [];
  categoria = [];

  user: User = JSON.parse(localStorage.user);

  country = getCountry();

  constructor(
    private productsService: ProductsService,
    private sharedService: SharedService,
    private catalogService: CatalogService,
    public activeModal: NgbActiveModal,
    private storeService: StoreService,
    private categoriesService: CategoriesService
  ) {}

  ngOnInit(): void {
    this.triggeredFrom = this.sharedService.triggeredFrom;
    this.profitPercentage = this.sharedService.profitPercentage;
    this.productChecked = this.sharedService.productChecked;
    this.categories = this.sharedService.categories;
    if (this.categories === undefined) {
      this.categoriesService.getCategories().subscribe((categories: any) => {
        this.categories = categories;
        this.getProduct();
        this.getCatalog();
      });
    }
    else  {
      this.getProduct();
      this.getCatalog();
    }
  }

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (
      this.unpauseOnArrow &&
      slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT ||
        slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)
    ) {
      this.togglePaused();
    }
    if (
      this.pauseOnIndicator &&
      !slideEvent.paused &&
      slideEvent.source === NgbSlideEventSource.INDICATOR
    ) {
      this.togglePaused();
    }
  }

  getProduct() {
     // TODO: mejorar esto y tratar de no ir al LocalStorage y parsear
    const getProductSubscription = this.productsService
      .getProductById(this.sharedService.selectedProductId, this.user.country)
      .subscribe((product: Product) => {
        this.selectedProduct = product;
        product.categories.forEach((category) => {
          this.categoria.push(
            this.categories.filter((cat) => cat.id === category)[0]?.name
          );
        });

        if (this.selectedProduct.images.length === 0) {
          this.productImages = [
            '../../../../../assets/images/product-list/1.jpg',
          ];
        } else {
          this.productImages = this.selectedProduct.images;
        }

        this.imagesLoaded = true;
        this.loagin = false;
      });

    this.subscriptions.add(getProductSubscription);
  }

  getEarningsValue(price: number) {
    return ((Number(this.profitPercentage) / 100) * price).toFixed(2);
  }

  getSellingPrice(price: number) {
    return ((Number(this.profitPercentage) / 100) * price + price).toFixed(2);
  }

  getSellerProfit(price: number, seller: boolean = true) {
    if (seller) {
      return ((Number(this.profitPercentage) / 100) * price * 0.75).toFixed(2);
    } else {
      return ((Number(this.profitPercentage) / 100) * price * 0.25).toFixed(2);
    }
  }

  getCatalog() {
    this.storeService.getCatalog().subscribe((catalog: any) => {
      this.profitPercentage = catalog.percentage;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
