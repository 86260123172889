import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TranslateModule } from '@ngx-translate/core';
import { AccountFormComponent } from './singup/accountForm/accountForm.component';
import { PersonalFormComponent } from './singup/personalForm/personalForm.component';
import { CardPrimaryComponent } from './login/card-primary/card-primary.component';
import { SuccessRegisterComponent } from './success-register/success-register.component';
import { SharedModule } from '../../shared/shared.module';
import { RecuperarPasswordComponent } from './RecuperarPassword/RecuperarPassword.component';
import { SingUpComponent } from './singup/singup.component';
import { FirebaseUIModule } from 'firebaseui-angular';

@NgModule({
  declarations: [
    LoginComponent,
    SingUpComponent,
    CardPrimaryComponent,
    AccountFormComponent,
    PersonalFormComponent,
    SuccessRegisterComponent,
    RecuperarPasswordComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    TranslateModule,
    FormsModule,
    SharedModule,
  ]
})
export class AuthModule { }
