import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { getCountry } from 'src/main';

@Component({
  selector: 'app-singup',
  templateUrl: './singup.component.html',
  styleUrls: ['./singup.component.scss'],
})
export class SingUpComponent implements OnInit, OnDestroy {
  public accountInfo: any;
  public user: any;
  public errorMessage = '';
  public subscriptions = new Subscription();
  public readonly defaultDocumentType = 'invalid';
  public documentType = this.defaultDocumentType; // Valor dummy para que quede seleccionado el "placeholder".
  public disableForm1 = 1;

  loadingLogin = false;
  public show = false;

  public country = getCountry();

  constructor(private authSservice: AuthService) {}
  ngOnInit(): void {}
  ngOnDestroy(): void {}

  updateDisableForm1($event) {
    this.disableForm1 = $event.disableForm;
    this.accountInfo = $event.accountInfo;
  }

  registerWithGoogle() {
    this.authSservice
      .registerWithGoogle()
      .then((res) => {
        this.loadingLogin = true;
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
