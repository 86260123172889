<div class="card o-hidden widget-cards h-100">
  <div
    class="row justify-content-center align-items-center p-3 h-100"
    *ngIf="title.length > 0"
  >
    <h3 class="text-uppercase text-center">{{ title }}</h3>
  </div>
  <div
    class="row px-3 w-100 h-100 justify-content-center align-items-center position-relative"
  >
    <div
      class="col-md-6 d-flex justify-content-center align-items-center"
      (click)="openModal()"
    >
      <img
        class="img-fluid w-100"
        [defaultImage]="
          '../../../../assets/images/digital-product/web-element.png'
        "
        [lazyLoad]="product.image"
        [useSrcset]="true"
      />
    </div>
    <div class="col-md-6 h-100" (click)="openModal()">
      <h4 class="text-capitalize my-3 title">{{ product.title }}</h4>
      <div class="pb-3">
        <strong class="text-uppercase mb-3 subtitle font-size-08rem"
          >sku: {{ product.sku | transformTextSku }}</strong
        >
      </div>
      <div>
        <strong class="text-capitalize mb-3 subtitle font-size-08rem"
          >{{ "Product-details.cost-ppgg" | translate }}
        </strong>
      </div>
      <div>
        <strong class="text-capitalize mb-3 subtitle font-size-08rem">
          $ {{ product.price }}</strong
        >
      </div>
    </div>
    <div
      (click)="openModal()"
      class="position-absolute top-0 start-0 p-3 w-100 h-100 d-flex justify-content-center align-items-center"
      *ngIf="product.stock === 0"
    >
      <div class="rotate shadow-lg">
        <h3>Agotado</h3>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isInCatalog; else elseTemplate">
    <app-share-button [product]="product"></app-share-button>
    <app-btn-add-product
      [disabled]="product.sku === undefined"
      [sku]="product.sku"
    ></app-btn-add-product>
  </ng-container>
  <ng-template #elseTemplate>
    <app-btn-add-product
      [disabled]="product.sku === undefined"
      [sku]="product.sku"
    ></app-btn-add-product>
  </ng-template>
</div>
