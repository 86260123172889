import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Coupon} from '../../model/coupon';

@Injectable({
  providedIn: 'root'
})
export class CouponsService {

  url: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = `${baseUrl}coupon`;
  }

  getCoupons(): Observable<Coupon[]> {
    return this.http.get<Coupon[]>(this.url);
  }

  createCoupon(coupon: Coupon): Observable<Coupon> {
    // TODO: usar una interfaz diferente para mandar en el post ¿?
    return this.http.post<Coupon>(this.url, coupon);
  }
}
