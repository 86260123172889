import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CouponsService } from '../../../../services/coupons/coupons.service';
import { Subscription } from 'rxjs';
import { Coupon } from '../../../../model/coupon';
import { StoreService } from '../../../../services/store/store.service';
import { UntypedFormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../../../services/shared/shared.service';
import { Catalog } from '../../../../model/Catalog';
import { AngularFireAuth } from '@angular/fire/auth';
import { getBeeSellerRevenue, getIVA } from 'src/main';

@Component({
  selector: 'app-link-discount-coupons-modal',
  templateUrl: './link-discount-coupons-modal.component.html',
  styleUrls: ['./link-discount-coupons-modal.component.scss'],
})
export class LinkDiscountCouponsModalComponent implements OnInit, OnDestroy {
  coupons: Coupon[] = [];
  selectedCoupons: Coupon[] = [];

  linkData: { coupon: Coupon; discountPercentage: number }[] = [];

  public readonly prefixId = 'percentage-';
  private subscriptions = new Subscription();
  private readonly catalogInfo: Catalog;

  public sellerProfits: number[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private couponsService: CouponsService,
    private toast: ToastrService,
    private storeService: StoreService,
    private sharedService: SharedService,
    private translate: TranslateService
  ) {
    this.catalogInfo = this.sharedService.catalogToLinkToCoupons;
    this.subscriptions.add(
      this.couponsService.getCoupons().subscribe((coupons) => {
        this.sellerProfits = new Array(coupons.length).fill(
          this.calProfitSeller(0)
        );
        this.coupons.push(...coupons);
      })
    );
  }

  get maxDiscountPercentageAllowed(): number {
    return (
      this.catalogProfitPercentage -
      (this.catalogProfitPercentage * getBeeSellerRevenue() +
        this.catalogProfitPercentage * getBeeSellerRevenue() * getIVA())
    );
  }

  get catalogProfitPercentage() {
    return this.catalogInfo.percentage;
  }

  private static getInputValue(id: string): number {
    return parseFloat(
      (document.getElementById(`${id}`) as HTMLInputElement).value
    );
  }

  ngOnInit(): void {}

  createForm(): void {}

  couponClickEvent(ev: any): void {
    if (ev.target.checked) {
      const percentage = LinkDiscountCouponsModalComponent.getInputValue(
        `${this.prefixId}${ev.target.id}`
      );

      this.selectedCoupons.push(
        this.coupons.find((coupon) => coupon.uuid === ev.target.id)
      );
      this.linkData.push({
        coupon: this.coupons.find((coupon) => coupon.uuid === ev.target.id),
        discountPercentage: percentage,
      });
    } else {
      const index = this.selectedCoupons.findIndex(
        (coupon) => coupon.uuid === ev.target.id
      );
      if (index !== -1) {
        this.selectedCoupons.splice(index, 1);
      }

      const input = document.getElementById(
        `${this.prefixId}${ev.target.id}`
      ) as HTMLInputElement;
      input.value = '0';

      const index2 = this.linkData.findIndex(
        (data) => data.coupon.uuid === ev.target.id
      );
      if (index2 !== -1) {
        this.linkData.splice(index2, 1);
      }
    }
  }

  allPercentagesValid(): boolean {
    // TODO: mejorar la obtencion/manejo del campo del porcentaje.
    return this.linkData.every((value) => {
      const inputValue = LinkDiscountCouponsModalComponent.getInputValue(
        `${this.prefixId}${value.coupon.uuid}`
      );
      return inputValue <= this.maxDiscountPercentageAllowed;
    });
  }

  calProfitSeller(value: number): number {
    return this.maxDiscountPercentageAllowed - value;
  }

  inputChangeEvent(ev: any, i: number): void {
    if (ev.value > this.maxDiscountPercentageAllowed) {
      this.toast.warning('El porcentaje no puede superar el máximo permitido');
      return;
    }

    if (ev.value > 0) {
      this.sellerProfits[i] = this.calProfitSeller(ev.value);
    } else {
      this.sellerProfits[i] = this.calProfitSeller(0);
    }

    const id = ev.id.replace(this.prefixId, '');
    const inputcheck = document.getElementById(`${id}`) as HTMLInputElement;
    if (ev.value > 0 && inputcheck.checked === false) {
      inputcheck.click();
    } else if (ev.value === 0) {
      inputcheck.checked = false;
      inputcheck.click();
    }
  }

  linkDiscountCoupons(): void {
    if (!this.allPercentagesValid()) {
      this.toast.warning(
        'El porcentaje no puede superar el máximo permitido',
        this.translate.instant('Toast.warning')
      );

      return;
    }

    const sub = this.storeService
      .linkDiscountCoupons(this.catalogInfo.uuid, {
        couponsData: this.selectedCoupons.map((coupon) => ({
          uuid: coupon.uuid,
          discountPercentage: LinkDiscountCouponsModalComponent.getInputValue(
            `${this.prefixId}${coupon.uuid}`
          ),
        })),
      })
      .subscribe(
        () => {
          this.toast.success(
            'Cupones asociados!',
            this.translate.instant('Toast.success')
          );

          this.activeModal.dismiss();
        },
        (error) => {
          console.error(error);
          if (error.error.httpStatusCode === 409) {
            this.toast.error(
              'El cupon ya se encontraba asociado.',
              this.translate.instant('Toast.error')
            );
          }
        }
      );

    this.subscriptions.add(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
