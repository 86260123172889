import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DefaultSetting, DefaultSettingProduct } from 'src/app/model/default-setting';
import { ProductsService } from 'src/app/services/products/products.service';
import { SettingService } from 'src/app/services/setting/setting.setvice';

@Component({
  selector: 'app-default-setting',
  templateUrl: './default-setting.component.html',
  styleUrls: ['./default-setting.component.scss']
})
export class DefaultSettingComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}

