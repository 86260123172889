<div
  class="page-wrapper"
  [ngStyle]="{
    'background-image': 'url(../../../../assets/images/fondologin.png)'
  }"
>
  <div class="authentication-box">
    <div class="container">
      <div class="row">
        <div class="card shadow-sm">
          <div class="card-body">
            <div class="row">
              <div class="col-md-5 w-100">
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                >
                  <img
                    *ngIf="country === 'UY'"
                    class="w-50"
                    [width]="85"
                    alt=""
                    src="../../../../assets/images/BeeSeller-07.png"
                  />
                  <p class="mt-4">{{ "SignUp.create-store" | translate }}</p>
                  <img
                    *ngIf="country !== 'UY'"
                    class="w-100"
                    [width]="85"
                    alt=""
                    src="../../../../assets/images/registro_shedone.png"
                  />
                </div>
              </div>
              <div class="col-md-7 p-0">
                <app-account-form
                  [disableForm1]="disableForm1"
                  (updateDisableForm1)="updateDisableForm1($event)"
                  class="p-3"
                >
                </app-account-form>
                <app-personal-form
                  class="p-3"
                  [disableForm1]="disableForm1"
                  [acountInfo]="accountInfo"
                  (updateDisableForm1)="updateDisableForm1($event)"
                ></app-personal-form>
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                  *ngIf="disableForm1 === 1"
                >
                  <div class="w-75 mt-2">
                    <button
                      *ngIf="!this.loadingLogin"
                      (click)="registerWithGoogle()"
                      class="btn btn-outline-primary rounded-pill mt-1 w-100 d-flex justify-content-center"
                    >
                      <img
                        class="logo-size"
                        alt=""
                        src="../../../../assets/images/google(1).svg"
                      />
                      <span class="mx-2">
                        {{ "SignUp.continue-google" | translate }}
                      </span>
                    </button>
                    <div *ngIf="this.loadingLogin" class="loader"></div>
                  </div>
                  <!-- <div class="w-75">
                                        <button *ngIf="!this.loadingLogin"
                                            class="btn btn-outline-primary rounded-pill mt-1 w-100 d-flex justify-content-center">
                                            <img class="logo-size" alt=""
                                                src="../../../../assets/images/facebook.svg" />
                                            <span class="mx-2">
                                                Ingresar con facebook
                                            </span>
                                        </button>
                                        <div *ngIf="this.loadingLogin" class="loader"></div>
                                    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
