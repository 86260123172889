import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";

import { Settings, Store } from '../../../model/Store';
import { StoreService } from "src/app/services/store/store.service";
import { AngularFireStorage } from "@angular/fire/storage";
import { SharedService } from "src/app/services/shared/shared.service";
import { imageDTO } from "src/app/model/dto/settings/ImageDTO";
import { UpdateStoreDTO } from "src/app/model/dto/settings/UpdateStoreDTO";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { User } from "src/app/model/User";
import { TemplateSettingJson } from "src/app/utils/TemplateSettingJson";
import { TemplateConfig } from "src/app/utils/config-data";
import { getCountry } from "src/main";


@Component({
    selector: 'app-form-edit-store',
    templateUrl: './form-edit-store.component.html',
    styleUrls: ['./form-edit-store.component.scss'],
})
export class FormEditStoreComponent implements OnInit, OnChanges {

    @Input() userId: string;
    @Input() user: User;
    userPhoneArea: string = '+598';
    store: Store = {} as Store;
    storeSetting: Settings = {
        backgroundColor: '#000',
        bannerImg: '',
        logoUrl: '',
        headerColor: '#000',
        storeName: '',
        storeNameColor: '#000',
        contacts: {
            email: '',
            facebook: '',
            instagram: '',
            telephone: '',
            tiktok: '',
        }
    };

    private subscriptions: Subscription = new Subscription();

    storeForm = this.formBuilder.group({
        url: [this.store.uri, [Validators.required]],
        enabled: [{ value: this.store.enabled, disabled: true }, []],
        headerColor: [{ value: this.storeSetting.headerColor, disabled: true }, []],
        bannerImg: [],
        storeName: [{ value: this.storeSetting.storeName, disabled: true }, []],
        storeNameColor: [{ value: this.storeSetting.storeNameColor, disabled: true }, []],
        backgroundColor: [{ value: this.storeSetting.backgroundColor, disabled: true }, []],
        telephone: [
            { value: this.storeSetting.contacts.telephone, disabled: true },
            []],
        email: [{ value: this.storeSetting.contacts.email, disabled: true }, [Validators.email]],
        instagram: [{ value: this.storeSetting.contacts.instagram, disabled: true }, []],
        facebook: [{ value: this.storeSetting.contacts.facebook || '', disabled: true }, []],
        tiktok: [{ value: this.storeSetting.contacts.tiktok, disabled: true }, []],
    });

    logoData: imageDTO;
    bannerImgData: imageDTO;
    editMode: boolean = false;

    defaultTemplateSelected: TemplateConfig = null;
    fileImgSelected: string = '';

    public settingTemplate: TemplateSettingJson;

    country = getCountry();

    constructor(
        private formBuilder: FormBuilder,
        private storeService: StoreService,
        public sharedService: SharedService,
        private toaster: ToastrService,
        private translate: TranslateService,
        private storage: AngularFireStorage,
        @Inject('FRONT_OFFICE_BASE_URL') public frontOfficeBaseUrl: string
    ) { }

    ngOnInit(): void {
        this.getStoreByVendor(this.user.uid);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.user.currentValue !== changes.user.previousValue) {
            if (this.user.country.toLocaleUpperCase() === 'UY') {
                this.userPhoneArea = '+598';
            }
        }
    }

    getTemplateSelection(template: TemplateConfig) {
        this.defaultTemplateSelected = { ...template };
        this.storeForm.controls['storeNameColor'].patchValue(template.titleColor, { onlySelf: true });
        this.storeForm.controls['backgroundColor'].patchValue(template.backgroundColor, { onlySelf: true });
        this.storeForm.get('headerColor').patchValue(template.headerColor, { onlySelf: true });
    }

    getStoreByVendor(userId: string) {
        const storeSubscription = this.storeService.getStoreByVendor(userId).subscribe((res: Store) => {
            this.store = res;
            this.storeSetting = this.store.settings;

            this.onInitParsedFields(this.store)

        });

        this.subscriptions.add(storeSubscription);
    }

    async parseFormData(): Promise<UpdateStoreDTO> {
        let bannerImg = '';
        if (!this.defaultTemplateSelected) {
            bannerImg = this.bannerImgData ? await this.uploadImagesToFireStorage(this.bannerImgData.image, this.bannerImgData.imageName) : this.store.settings.bannerImg;
        } else {
            bannerImg = this.defaultTemplateSelected.banner || this.store.settings.bannerImg;
        }

        return {
            uri: this.storeForm.controls.url.value,
            enabled: this.storeForm.controls.enabled.value,
            settings: {
                headerColor: this.storeForm.controls.headerColor.value ?
                    this.storeForm.controls.headerColor.value
                    : '#202124',
                logoUrl: '',
                bannerImg: bannerImg,
                storeName: this.storeForm.controls.storeName.value,
                storeNameColor: this.storeForm.controls.storeNameColor.value ?
                    this.storeForm.controls.storeNameColor.value
                    : '#202124',
                backgroundColor: this.storeForm.controls.backgroundColor.value || '#E9ECEF',
                contacts: {
                    email: this.storeForm.controls.email.value || '',
                    facebook: this.storeForm.controls.facebook.value || '',
                    instagram: this.storeForm.controls.instagram.value || '',
                    telephone: this.storeForm.controls.telephone.value || '',
                    tiktok: this.storeForm.controls.tiktok.value || '',
                },
            }
        }
    }

    onRemoveBannerImg() {
        this.defaultTemplateSelected.banner = "";
    }

    onInputPhone(value: string) {
        if (value.match(/^0|\+|598|\D/)) { //(?=[a-z, A-Z])
            this.toaster.error('El numero de teléfono no debe comensar con 0, +, o 598', 'Numero de Teléfono invalido');
        }
    }

    async editStore() {
        const newSettings = await this.parseFormData();
        const editSubscription = this.storeService.editStore(this.store.uuid, newSettings, this.userId).subscribe((res: any) => {
            this.store.uri = res.uri;
            this.store.enabled = res.enabled;
            this.store.settings = res.settings;
            this.sharedService.ecommercePublished = this.store.enabled;
            this.sharedService.ecommerceUrl = this.store.uri;

            this.storeSetting = res.settings;
            this.defaultTemplateSelected = null;

            this.onInitParsedFields(this.store)

            this.toaster.success(this.translate.instant('Toast.store.status'), this.translate.instant('Toast.success'), {
                progressBar: true,
                timeOut: 3000
            });
            this.changeEditMode();
        }, error => {
            if (error.status === 400) {
                Object.entries(error.error.errors).forEach(([key, value]) => {
                    this.toaster.warning(this.translate.instant('Toast.store.bad-request.' + key), this.translate.instant('Toast.warning'), {
                        progressBar: true,
                        timeOut: 3000
                    });
                });
            }
        });

        this.subscriptions.add(editSubscription);
    }

    public getImageData(event) {

        if (event.target.files.length < 1) {
            event.preventDefault();
            this.toaster.error('Debes seleccionar un archivo!');
            return;
        }

        const mimesExtensions = { 'image/jpeg': 'jpg', 'image/png': 'png' };
        const file = event.target.files[0];


        if (!Object.keys(mimesExtensions).includes(file.type)) {
            event.preventDefault();
            this.toaster.error('El archivo no es una imágen válida!');
            return;
        }

        if (file.size > 204800 && event.target.name === 'logo' || event.target.name === 'profilePicture') { // La imagen no debe superar los 200 KB.
            event.preventDefault();
            this.toaster.error('La imagen no puede superar los 200 KB!');
            return;
        }

        if (file.size > 2000000 && event.target.name === 'bannerImg') {
            event.preventDefault();
            this.toaster.error('La imagen no puede superar los 2 MB!');
            return;
        }

        const fileExtension = mimesExtensions[file.type];

        let bannerName = `${this.store.uuid}-banner.${fileExtension}`;

        this.bannerImgData = {
            image: file,
            imageName: bannerName
        }
        this.fileImgSelected = file.name;
    }

    private async uploadImagesToFireStorage(image: any, imageName: string) {
        return this.storage
            .upload(imageName, image)
            .then(taskSnapshot => taskSnapshot.ref.getDownloadURL())
    }

    onClickToFile() {
        const inputFile = document.getElementById('bannerImg') as HTMLInputElement;
        inputFile.click();
    }

    changeEditMode() {
        this.editMode = !this.editMode;
        if (this.editMode) {
            this.storeForm.enable()
        } else {
            this.storeForm.disable()
        }
    }

    onInitParsedFields(values: Store) {
        this.storeForm.controls.url.setValue(values.uri);
        this.storeForm.controls.enabled.setValue(values.enabled);
        this.storeForm.controls.headerColor.setValue(values.settings.headerColor);
        this.storeForm.controls.storeName.setValue(values.settings.storeName);
        this.storeForm.controls.storeNameColor.setValue(values.settings.storeNameColor);
        this.storeForm.controls.backgroundColor.setValue(values.settings.backgroundColor);
        this.storeForm.controls.email.setValue(values.settings.contacts.email);
        this.storeForm.controls.facebook.setValue(values.settings.contacts.facebook);
        this.storeForm.controls.instagram.setValue(values.settings.contacts.instagram);
        this.storeForm.controls.telephone.setValue(values.settings.contacts.telephone);
        this.storeForm.controls.tiktok.setValue(values.settings.contacts.tiktok);
    }
}
