import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Category} from '../../model/Category';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  private readonly url: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = `${baseUrl}products_api/categories`;
  }

  getCategories(): Observable<Category[]> {
    return this.http.get<Category[]>(this.url);
  }
}
