import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CONSTANTS } from '../../../../utils/Constants';

@Component({
  selector: 'app-account-form',
  templateUrl: './accountForm.component.html',
  styleUrls: ['./accountForm.component.scss']
})
export class AccountFormComponent implements OnInit {
  @Input()
  disableForm1: number = 1;
  @Output() updateDisableForm1: EventEmitter<any> = new EventEmitter<any>();

  public countries;
  public show = false;
  public repeatShow = false;
  public disableNextButton = false;
  public formUserAcount: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private translate: TranslateService, private toast: ToastrService) {
    this.createFormUserAcount();
    this.countries = CONSTANTS.COUNTRIES;
  }

  createFormUserAcount() {
    this.formUserAcount = this.formBuilder.group({
      password: ['', [Validators.required]],
      repeatPassword: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
    });
  }

  ngOnInit() {
  }

  showPassword() {
    this.show = !this.show;
  }

  showRepeatPassword() {
    this.repeatShow = !this.repeatShow;
  }

  onChange() {
    if (this.formUserAcount.valid) {
      this.disableNextButton = false;
    }
  }

  async registerAcount(formRegister: UntypedFormGroup) {
    if (!formRegister.valid) {
      this.toast.error(
        this.translate.instant('Register.invalid-form'),
        this.translate.instant('Toast.error')
      );

      this.disableNextButton = true;
      return;
    }

    const userInfo = { ...formRegister.value };

    if (userInfo.password !== userInfo.repeatPassword) {
      this.toast.error(
        this.translate.instant('Register.password-not-match'),
        this.translate.instant('Toast.error')
      );

      this.disableNextButton = true;
      return;
    }

    if (String(userInfo.store).trim() === '') {
      userInfo.store = 'bee-store' + Math.floor(Math.random() * (1000 - 1) + 1) + ' ' + Date.now();
    }

    this.disableNextButton = false;
    this.updateDisableForm1.emit({ disableForm: 2, accountInfo: userInfo });
  }
}
