import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { DefaultSettingProduct } from 'src/app/model/default-setting';
import { SharedService } from 'src/app/services/shared/shared.service';
import { StoreService } from 'src/app/services/store/store.service';
import { ProductDetailsModalComponent } from '../../modal/modals/product-details-modal/product-details-modal.component';
import { Category } from 'src/app/model/Category';

@Component({
  selector: 'app-product-month-card',
  templateUrl: './product-month-card.component.html',
  styleUrls: ['./product-month-card.component.scss'],
})
export class ProductMonthCardComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input()
  title: string = '';
  @Input()
  subtitle?: string;
  @Input()
  product?: DefaultSettingProduct = {} as DefaultSettingProduct;

  @Input() categories: Category[];

  private subscription: Subscription = new Subscription();

  isInCatalog: boolean = false;
  productUrl: string = '';

  constructor(
    private storeService: StoreService,
    private sharedService: SharedService,
    @Inject('FRONT_OFFICE_BASE_URL') private frontOfficeUrl: string,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    const uri = localStorage.getItem('storeUri');
    this.productUrl = `${this.frontOfficeUrl}/${uri}/shop/product/${this.product.sku}`;
    this.settupSubscriptions();
  }

  settupSubscriptions() {
    this.subscription.add(
      this.storeService
        .isInCatalog(this.product.sku)
        .subscribe((isInCatalog: boolean) => {
          this.isInCatalog = isInCatalog;
        })
    );
  }

  openModal() {
    if (this.product.sku !== undefined) {
      this.sharedService.selectedProductId = this.product.sku;
      this.sharedService.categories = this.categories;
      this.modalService.open(ProductDetailsModalComponent, {
        size: 'xl',
        centered: true,
        backdrop: 'static',
      });
    }
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
