import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { User } from "src/app/model/User";
import { UsersService } from "src/app/services/users/users.service";

@Component({
    selector: 'app-form-edit-network',
    templateUrl: './form-edit-network.component.html',
    styleUrls: ['./form-edit-network.component.scss'],
})
export class FormEditNetworkComponent implements OnInit {

    user: User;
    backendUser: User;
    userSocialNetworksEditMode = false;

    userSocialNetworksForm: FormGroup;

    public readonly snMetadata = {
        class: {
            facebook: 'fb',
            instagram: 'google',
            twitter: 'twitter'
        },
        url: {
            facebook: 'https://facebook.com',
            instagram: 'https://instagram.com',
            twitter: 'https://twitter.com'
        }
    };

    private subscriptions: Subscription = new Subscription();

    constructor(
        private userService: UsersService,
        private formBuilder: FormBuilder,
    ) { }

    ngOnInit(): void {
    }

    editUserSocialNetworks(): void {
        const user: User = { socialNetworks: [] };
        Object.keys(this.userSocialNetworksForm.value)
            .forEach(k => {
                const value = this.userSocialNetworksForm.value[k];
                if (value) {
                    user.socialNetworks.push({
                        name: k,
                        username: this.userSocialNetworksForm.value[k]
                    });
                }
            });

        this.subscriptions.add(this.userService.editUser(this.user.uuid, user).subscribe(
            u => {
                this.backendUser = u;
                this.changeUserSocialNetworksEditMode();
            },
            error => console.error(error)
        ));
    }

    changeUserSocialNetworksEditMode() {
        this.userSocialNetworksEditMode = !this.userSocialNetworksEditMode;
        // this.userSocialNetworksEditMode ? this.storeForm.controls.enabled.enable() : this.storeForm.controls.enabled.disable();
    }

    createUserSocialNetworksForm(): void {
        const asd = {
          facebook: [],
          instagram: [],
          twitter: []
        };
    
        this.backendUser.socialNetworks?.forEach(sn => {
          asd[sn.name].push(sn.username ? sn.username : '');
        });
        this.userSocialNetworksForm = this.formBuilder.group(asd);
      }
}