import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DefaultSettingProduct } from 'src/app/model/default-setting';
import { ProductsService } from 'src/app/services/products/products.service';
import { SettingService } from 'src/app/services/setting/setting.setvice';

@Component({
  selector: 'app-product-month',
  templateUrl: './product-month.component.html',
  styleUrls: ['./product-month.component.scss']
})
export class ProductMonthSettingComponent implements OnInit {

  product: DefaultSettingProduct = {} as DefaultSettingProduct;

  settingForm: FormGroup = this.fb.group({
    productMonth: [this.product.sku, [Validators.minLength(1)]],
  });

  togleBtnSave: boolean = true;

  constructor(
    private settingService: SettingService,
    private productService: ProductsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.settingService.getProductMonth().subscribe((response: any) => {
      if (response.sku) {
        this.getProductSku(response.sku);
        this.resetForm(response.sku);
      } else {
        this.togleBtnSave = true;
      }
    });

    this.settingForm.statusChanges.subscribe((status) => {
      if (status === 'VALID') {
        this.togleBtnSave = true;
      } else {
        this.togleBtnSave = false;
      }
    });
  }

  onSave() {
    const sku = this.settingForm.controls.productMonth.value;
    this.settingService.updateProductMonth(sku).subscribe(() => {
      this.getProductSku(this.settingForm.controls.productMonth.value);
      this.toastr.success('Producto del mes guardado');
    }, (error) => {
      this.toastr.error('Error al guardar el producto del mes');
    });
  }

  resetForm(sku?: string) {
    this.settingForm.controls.productMonth.setValue(sku);
    this.togleBtnSave = false;
  }

  getProductSku(sku: string) {
    this.productService.getProductBySku(sku).subscribe((product) => {
      this.product = {
        sku: product.sku,
        image: product.images[0],
        title: product.artNombreMl,
        description: product.catalogDescrip,
        price: product.pricePesos,
        stock: product.currentStock,
        category: product?.categories?.[0]?.name || 'Sin categoría',
      };
    }, (error) => {
      this.toastr.error('Error al obtener el producto');
    });
  }
}

