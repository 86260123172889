<div class="container-fluid">
  <div class="card">
    <div class="card-body tab2-card">
      <ngb-tabset class="tab-coupon">
        <ngb-tab [title]="'Coupon.new' | translate">
          <ng-template ngbTabContent>
            <form [formGroup]="generalForm" class="needs-validation" novalidate="">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group row">
                    <label class="col-xl-3 col-md-4" for="validationCustom1"><span>*</span>
                      {{'Coupon.code' | translate}}</label>
                    <input class="form-control col-md-7" formControlName="code"
                           id="validationCustom1" required="" type="text" maxlength="20">
                    <div class="valid-feedback">{{'Coupon.validator.code' | translate}}</div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-md-4"><span>*</span>{{'Coupon.due-date' | translate}}</label>
                    <div class="input-group col-md-7 input-grp-p">
                      <input #c="ngbDatepicker" class="datepicker-here form-control digits"
                             formControlName="dueDate" ngbDatepicker>
                      <div class="input-group-append">
                        <button (click)="c.toggle()" class="btn calendar" type="button"><i
                          class="fa fa-calendar"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
      <div class="pull-right">
        <button (click)="createCoupon()" class="btn btn-primary" type="button">{{'Coupon.save' | translate}}</button>
        <button [routerLink]="['/coupons/list-coupons']"
                class="btn btn-secondary ml-1">{{'Coupon.cancel' | translate}}</button>
      </div>
    </div>
  </div>
</div>
