import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SettingService } from 'src/app/services/setting/setting.setvice';
import { StoreService } from 'src/app/services/store/store.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-analytics-card',
  templateUrl: './analytics-card.component.html',
  styleUrls: ['./analytics-card.component.scss'],
})
export class AnalyticsCardComponent implements OnInit, OnDestroy {
  @Input()
  title: string = '';
  @Input()
  private subscription: Subscription = new Subscription();

  screenPageViews: number = 0;
  activeUsers: number = 0;
  isLoading: boolean = false;

  dateFilterForm: FormGroup;

  constructor(public storeService: StoreService) {}

  ngOnInit(): void {
    this.dateFilterForm = new FormGroup({
      dateStart: new FormControl(''),
      dateEnd: new FormControl(''),
    });

    const today = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(today.getDate() - 7);

    const formatDate = (date: Date): string => {
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };

    const startDate = formatDate(oneWeekAgo);
    const endDate = formatDate(today);

    this.dateFilterForm.setValue({
      dateStart: startDate,
      dateEnd: endDate,
    });

    this.subscription.add(
      this.dateFilterForm.valueChanges.subscribe((values) => {
        const { dateStart, dateEnd } = values;
        if (dateStart && dateEnd) {
          this.fetchAnalyticsData(dateStart, dateEnd);
        }
      })
    );

    // Realizar la llamada inicial con los valores predeterminados
    this.fetchAnalyticsData(startDate, endDate);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  fetchAnalyticsData(startDate: string, endDate: string) {
    this.isLoading = true;
    const storeUuid = localStorage.getItem('storeUuid');
    this.subscription.add(
      this.storeService
        .getGoogleAnalyticsReport(storeUuid, startDate, endDate)
        .subscribe((e: any) => {
          this.activeUsers = e?.activeUsers || 0;
          this.screenPageViews = e?.screenPageViews || 0;
          this.isLoading = false;
        })
    );
  }
}
