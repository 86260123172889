// #FFCC85
export interface TemplateConfig {
    banner: string;
    headerColor: string;
    backgroundColor: string;
    titleColor: string;
}

export const Templates: Array<TemplateConfig> = [
    {
        banner: 'https://firebasestorage.googleapis.com/v0/b/landing-ppgg-test.appspot.com/o/banner-opcion1.png?alt=media&token=13698773-15c7-4752-8767-95fbb165c627',
        headerColor: '#FFCC85',
        backgroundColor: '#FEB820',
        titleColor: '#000',
    },
    {
        banner: 'https://firebasestorage.googleapis.com/v0/b/landing-ppgg-test.appspot.com/o/banner-opcion2.png?alt=media&token=c2b8f1c9-a9b9-4d31-a241-eedc1de81efa',
        headerColor: '#91B3FA',
        backgroundColor: '#13097A',
        titleColor: '#000'
    },
    {
        banner: 'https://firebasestorage.googleapis.com/v0/b/landing-ppgg-test.appspot.com/o/banner-opcion3.png?alt=media&token=ff952ccf-7c01-4cfd-a858-dad64e6e4a85',
        headerColor: '#B7F0E2',
        backgroundColor: '#2F9271',
        titleColor: '#000'
    }
];
