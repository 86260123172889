import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ToggleFullscreenDirective } from './directives/fullscreen.directive';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { NavService } from './service/nav.service';
import { WINDOW_PROVIDERS } from './service/windows.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProductsModule } from '../components/products/products.module';
import { AuthModule } from '../components/auth/auth.module';
import { LoadersModule } from '../components/loaders/loaders.module';
import { ModalComponent } from './modal/modal.component';
import { CreateCatalogModalComponent } from './modal/modals/create-catalog-modal/create-catalog-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductDetailsModalComponent } from './modal/modals/product-details-modal/product-details-modal.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PublishEcommerceModalComponent } from './modal/modals/publish-ecommerce-modal/publish-ecommerce-modal.component';
import { DeleteCatalogModalComponent } from './modal/modals/delete-catalog-modal/delete-catalog-modal.component';
import { OrderModalComponent } from './modal/modals/order-modal/order-modal.component';
import { LinkDiscountCouponsModalComponent } from './modal/modals/link-discount-coupons-modal/link-discount-coupons-modal.component';
import { PublishCatalogModalComponent } from './modal/modals/publish-catalog-modal/publish-catalog-modal.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ProductMonthCardComponent } from './components/product-month-card/product-month-card.component';
import { BtnAddProductComponent } from './components/btn-add-product/btn-add-product.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ShareButtonModule } from '../share-button/share-button.module';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { ProductSkeletonComponent } from './components/produc-skeleton/produc-skeleton.component';
import { TransformTextTagPipe } from './pipe/transform-text-tag.pipe';
import { ModalRankingComponent } from './modal/modals/modal-ranking/modal-ranking.component';
import { TransformTextSkuPipe } from './pipe/transform-text-sku.pipe';
import { AnalyticsCardComponent } from './components/analytics-card/analytics-card.component';

@NgModule({
  declarations: [
    ToggleFullscreenDirective,
    FeatherIconsComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    ContentLayoutComponent,
    BreadcrumbComponent,
    RightSidebarComponent,
    ModalComponent,
    CreateCatalogModalComponent,
    ProductDetailsModalComponent,
    PublishEcommerceModalComponent,
    DeleteCatalogModalComponent,
    OrderModalComponent,
    LinkDiscountCouponsModalComponent,
    PublishCatalogModalComponent,
    ProductMonthCardComponent,
    BtnAddProductComponent,
    ProductCardComponent,
    ProductSkeletonComponent,
    AnalyticsCardComponent,
    ModalRankingComponent,
    TransformTextTagPipe,
    TransformTextSkuPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    ProductsModule,
    // AuthModule,
    LoadersModule,
    FormsModule,
    CarouselModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    ShareButtonModule,
    NgbCarouselModule,
    IndicatorsModule,
  ],
  providers: [NavService, WINDOW_PROVIDERS],
  exports: [
    FeatherIconsComponent,
    ToggleFullscreenDirective,
    ModalComponent,
    ProductMonthCardComponent,
    BtnAddProductComponent,
    ProductCardComponent,
    ProductSkeletonComponent,
    TransformTextTagPipe,
    TransformTextSkuPipe,
    AnalyticsCardComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
