import {Component, OnInit} from '@angular/core';
import {categoryDB} from '../../../../shared/tables/category';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductsService} from '../../../../services/products/products.service';
import {Product} from '../../../../model/Product';
import {ServerDataSource} from 'ng2-smart-table';
import {Router} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  public closeResult: string;
  public categories = [];
  pageSize = 15;
  sizePage = [];
  source: ServerDataSource;
  public products: Product[] = [];
  public settings: any;


  constructor(private http: HttpClient, private modalService: NgbModal, private productService: ProductsService, private router: Router,
              private translate: TranslateService) {
    this.categories = categoryDB.category;
    this.source = new ServerDataSource(http,
      {
        endPoint: this.productService.url,
        dataKey: 'products',
        pagerPageKey: 'page',
        pagerLimitKey: 'size',
        totalKey: 'total',
      });
    this.translate.use(this.translate.currentLang);
    this.initTableSettings();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translate.use(event.lang);
      // every time the languages will change, we reload the settings
      this.initTableSettings();
    });
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  getTranslation(key: string) {
    return this.translate.instant(key);
  }

  ngOnInit() {}

  setPager(ev: any) {
    this.source.setPaging(1, this.pageSize, true);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  goToProductDetails(clickEvent: any) {
    this.router.navigate(['/products/physical/product-detail', clickEvent.data.sku]);
  }

  initTableSettings(): void {
    const show = this.getTranslation('Product-table.column.page-size');
    const items = this.getTranslation('Product-table.column.items');
    this.sizePage = [
      {value: 5, name: show + ' 5 ' + items },
      {value: 10, name: show + ' 10 ' + items },
      {value: 15, name: show + ' 15 ' + items },
      {value: 20, name: show + ' 20 ' + items },
      {value: 25, name: show + ' 25 ' + items },
    ];
    this.settings = {
      actions: {
        position: 'right',
        add: false,
        edit: false,
        delete: false,
      },
      pager: {
        display: true,
        perPage: this.pageSize,
      },
      sort: true,
      columns: {
        sku: {
          title: this.getTranslation('Product-table.column.SKU'),
          filter: false
        },
        art_nombreml: {
          title: this.getTranslation('Product-table.column.name'),
          filter: false
        },
        stock_actual: {
          title: this.getTranslation('Product-table.column.stock'),
          filter: false
        },
      },
    };
  }
}
