import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CreateCatalogInStore } from 'src/app/model/catalog-in-store';
import { ProductsService } from 'src/app/services/products/products.service';
import { SettingService } from 'src/app/services/setting/setting.setvice';
import { StoreService } from 'src/app/services/store/store.service';

@Component({
  selector: 'app-btn-add-product',
  templateUrl: './btn-add-product.component.html',
  styleUrls: ['./btn-add-product.component.scss'],
})
export class BtnAddProductComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @Input()
  sku: string;
  @Input() disabled;

  private subscription: Subscription = new Subscription();
  private catalog: CreateCatalogInStore;
  private skus: Set<string> = new Set();
  isInCatalog: boolean = false;

  constructor(
    private storeService: StoreService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.settupSkusCatatlog();
  }

  settupSkusCatatlog() {
    this.subscription.add(
      this.storeService.getSkus().subscribe((skus: Set<string>) => {
        this.isInCatalog = skus.has(this.sku);
        this.isInCatalogFn();
      })
    );
  }

  ngAfterViewInit(): void {
    this.isInCatalogFn();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sku.currentValue) {
      this.sku = changes.sku.currentValue;
      this.isInCatalogSubscription();
    }
  }

  isInCatalogSubscription() {
    this.subscription.add(
      this.storeService
        .isInCatalog(this.sku)
        .subscribe((isInCatalog: boolean) => {
          this.isInCatalog = isInCatalog;
        })
    );
  }

  isInCatalogFn() {
    this.subscription.add(
      this.storeService
        .getCatalog()
        .subscribe((response: CreateCatalogInStore) => {
          this.catalog = response;
          this.skus = new Set(response.skuProducts);
          this.isInCatalog = this.skus.has(this.sku);
        })
    );
  }

  onClickAdd() {
    if (!this.isInCatalog) {
      if (!this.disabled) {
        this.subscription.add(
          this.storeService
            .addProductToCatalog(this.catalog.uuid, [this.sku])
            .subscribe(
              (response) => {
                this.skus.add(this.sku);
                this.isInCatalog = true;
                this.storeService.setSkus(this.skus);
                this.activeModal.close();
                this.toastr.success('Producto agregado al catalogo');
              },
              (error) => {
                console.error(
                  'No se pudo agregar el producto al catalogo',
                  error
                );
                this.toastr.error('No se pudo agregar el producto al catalogo');
              }
            )
        );
      } else {
        this.toastr.error('No existe el producto en la tarjeta');
      }
    }
  }

  onRemoveProduct() {
    this.skus.delete(this.sku);
    this.subscription.add(
      this.storeService
        .removeProductFromCatalog(this.catalog.uuid, [this.sku])
        .subscribe(
          (response) => {
            this.isInCatalogFn();
            this.storeService.setSkus(this.skus);
            this.activeModal.close();
            this.toastr.success('Producto eliminado del catálogo');
          },
          (error) => {
            console.error(
              'No se pudo eliminar el producto del catalogo',
              error
            );
            this.toastr.error('No se pudo eliminar el producto del catalogo');
          }
        )
    );
  }
}
