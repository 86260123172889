import {Status} from '../Order';

export class SearchOrderImp {
    status: Status;
    profileId: string;
    delivery: DeliveryFilterDto;
    shopper: ShopperFiltersDto;
    date: DateFiltersDto;

    constructor() {
        this.status = Status.ALL;
        this.profileId = this.getProfileId();
        this.delivery = {
            address: '',
            location: '',
        };
        this.shopper = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
        };
        this.date = {
            dateStart: this.calcDateStart(),
            dateEnd: this.calcDateEnd()//new Date().toISOString().slice(0, 10)
        };
    }

    getProfileId() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user.userType === 'ADMIN' ? '' : user.profileId;
    }

    calcDateStart() {
        let dateNow = new Date();
        dateNow.setUTCDate(1);
        
        return dateNow.toISOString().slice(0, 10);
    }

    calcDateEnd() {
        let dateNow = new Date();
        dateNow.setUTCDate(dateNow.getUTCDate() + 1);
        
        return dateNow.toISOString().slice(0, 10);
    }
}


export interface SearchOrdersDto {
    status: Status;
    profileId: string;
    delivery: DeliveryFilterDto;
    shopper: ShopperFiltersDto;
    date: DateFiltersDto;
}

export interface DeliveryFilterDto {
    address: string;
    location: string;
}

export interface ShopperFiltersDto {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

export interface DateFiltersDto {
    dateStart: string;
    dateEnd: string;
}
