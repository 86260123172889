import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {NgbCalendar, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {CouponsService} from '../../../services/coupons/coupons.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Coupon} from '../../../model/coupon';

@Component({
  selector: 'app-create-coupon',
  templateUrl: './create-coupon.component.html',
  styleUrls: ['./create-coupon.component.scss']
})
export class CreateCouponComponent implements OnInit, OnDestroy {

  public generalForm: UntypedFormGroup;
  public model: NgbDateStruct;
  private subscriptions = new Subscription();

  constructor(private formBuilder: UntypedFormBuilder,
              private calendar: NgbCalendar,
              private couponsService: CouponsService,
              private router: Router,
              private toast: ToastrService,
              private translate: TranslateService) {

    this.createForm();
  }

  private static dateToString(d: { year: number, month: number, day: number }): string {
    const year: number = d.year;
    const month: number = d.month;
    const day: number = d.day;

    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  createForm() {
    this.generalForm = this.formBuilder.group({
      code: ['', [Validators.required]],
      dueDate: ['', [Validators.required, this.customDateValidator()]],
    });
  }

  createCoupon(): void {
    if (!this.generalForm.valid) {
      this.toast.warning(
        this.translate.instant('Toast.not-valid'),
        this.translate.instant('Toast.warning')
      );

      return;
    }

    // Se crea un clon del objeto de values.
    // Surje la necesidad de hacerlo para que cuando se modifica el valor de 'data.dueDate',
    // no se modifique el valor de 'dueDate' en el objeto 'this.generalForm.value'.
    const data: Coupon = Object.assign({}, this.generalForm.value);


    // Se formatea la fecha para que los meses y los dias siempre sean numeros de dos digitos.
    data.dueDate = CreateCouponComponent.dateToString(this.generalForm.value.dueDate);
    data.description = data.code;

    this.subscriptions.add(this.couponsService
      .createCoupon(data)
      .subscribe(
        coupon => {
          this.router.navigate(['/coupons/list-coupons']).then(() => {
            this.toast.success(
              this.translate.instant('Toast.coupon.created'),
              this.translate.instant('Toast.success')
            );
          });
        },
        error => {
          let message: string;
          if (error.status === 409) {
            message = this.translate.instant('Toast.coupon.conflict');

          } else {
            message = error.error.message;
          }

          this.toast.error(
            message,
            this.translate.instant('Toast.error')
          );
        }
      ));
  }

  ngOnInit() {
  }

  private customDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const now = Date.now();
      const controlDate = Date.parse(CreateCouponComponent.dateToString(control.value));
      return controlDate < now ? {invalidDate: {value: control.value}} : null;
    };
  }
}
