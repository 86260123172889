<form [formGroup]="formPersonalInfo" class="form-horizontal auth-form p-3" novalidate="" *ngIf="disableForm1 === 2">
  <!-- Linea 6. Nombre y Apellido-->
  <div class="row">
    <div class="col form-group">
      <input class="form-control" formControlName="firstName" name="register[firstName]"
             placeholder="Nombre y apellidos*">
    </div>
    <!-- <div class="col form-group">
      <input class="form-control" formControlName="lastName" name="register[lastName]"
        placeholder="{{'Register.placeholder.lastName' | translate}}">
    </div> -->
  </div>
  <!-- Linea 7. Documento y telefono -->
  <div class="row">
    <div class="col form-group" *ngIf="country === 'UY'">
      <select (change)="documentTypeChange()" [(ngModel)]="documentType" [ngModelOptions]="{standalone: true}"
              class="form-control" style="padding: 5px 25px !important;">
        <option [value]="defaultDocumentType" disabled selected>Tipo de documento</option>
        <!-- TODO: traducir -->
        <option value="ci">{{ 'Register.placeholder.ci' | translate }}</option>
        <option value="rut">{{ 'Register.placeholder.rut' | translate }}</option>
      </select>
    </div>
    <div class="col form-group">
      <input class="form-control" formControlName="telephone" name="register[telephone]" maxlength="13"
             placeholder="{{'Register.placeholder.telephone' | translate}}">
    </div>
  </div>
  <!-- Linea 8. Documento -->
  <div class="row">
    <div *ngIf="documentType === 'ci'" class="col form-group">
      <input class="form-control" formControlName="ci" name="register[ci]"
             placeholder="{{'Register.placeholder.ci' | translate}}">
    </div>
    <div *ngIf="documentType === 'rut'" class="col form-group">
      <input class="form-control" formControlName="rut" name="register[rut]"
             placeholder="{{'Register.placeholder.rut' | translate}}">
    </div>
  </div>
  <!-- Linea 9. Direccion y Empresa -->
  <div class="row">
    <div class="col form-group">
      <input class="form-control" formControlName="address" name="register[address]"
             placeholder="{{'Register.placeholder.address' | translate}}">
    </div>
    <div class="col form-group" *ngIf="documentType === 'rut'">
      <input class="form-control" formControlName="businessName" name="register[businessName]"
             placeholder="{{'Register.placeholder.businessName' | translate}}">
    </div>
  </div>

  <div class="row">
    <div class="form-button col form-group" style="text-align: center" *ngIf="!this.loadingRegister">
      <button type="button" [disabled]="disableRegisterButton" class="btn btn-form-back mt-4" (click)="back()">
        {{ 'Register.back' | translate }}
      </button>
    </div>
    <!-- Buttom submit -->
    <div class="form-button col form-group" style="text-align: center" *ngIf="!this.loadingRegister">
      <button [disabled]="disableRegisterButton" type="button" class="btn btn-primary mt-4" (click)="register($event)">
        <!-- {{'Register.submit' | translate}} -->
        Crear cuenta
      </button>
    </div>
    <div *ngIf="this.loadingRegister === true" class="loader"></div>
  </div>
</form>
