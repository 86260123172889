<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="custom-datatable">
            <button [routerLink]="'/coupons/create-coupons'" class="btn btn-primary mr-1 mb-3" style="float: right;">
              {{'Coupon.create' | translate}}
            </button>
            <div class="clearfix"></div>
            <ngx-datatable (select)='onSelect($event)' [columnMode]="'force'" [footerHeight]="50"
                           [headerHeight]="50" [limit]="5" [rowHeight]="'auto'" [rows]="digitalCategories"
                           [selectAllRowsOnPage]="false" [selected]="selected" [selectionType]="'falsey'"
                           class="bootstrap">
              <ngx-datatable-column [canAutoResize]="false" [checkboxable]="false" [draggable]="false"
                                    [headerCheckboxable]="true" [resizeable]="false" [sortable]="false"
                                    [width]="30">
                <button class="btn btn-primery" type="button">Delete</button>
              </ngx-datatable-column>
              <ngx-datatable-column [name]="'Coupon.table.description' | translate" prop="description"></ngx-datatable-column>
              <ngx-datatable-column [name]="'Coupon.table.code' | translate" prop="code"></ngx-datatable-column>
              <ngx-datatable-column prop="dueDate" [width]="120">
                <ng-template let-column="column" ngx-datatable-header-template>
                  {{'Coupon.table.due-date' | translate }}
                </ng-template>
                <ng-template let-value="value" ngx-datatable-cell-template>
                  {{value | date: 'dd/MM/yyyy'}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [name]="'Coupon.table.active' | translate" prop="active"></ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
