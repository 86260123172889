<div aria-labelledby="account-tab" class="tab-pane fade active show" id="account" role="tabpanel">
  <form [formGroup]="accountForm" class="needs-validation user-add" novalida>
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <div class="profile-details text-center" style="display: flex; flex-direction: column">
              <div class="profile-pic">
                <label class="-label" for="profilePhoto">
                  <span class="fa fa-camera"></span>
                  <span>Change Image</span>
                </label>
                <input (change)="getImageData($event)" [disabled]="photoChangeDisabled" accept="image/png, image/jpeg"
                       name='profilePicture' id="profilePhoto" type="file"/>
                <img alt="Profile photo" class="blur-up lazyloaded" id="output"
                     src="{{ user.image || 'assets/images/user-generic.png'}}"/>
              </div>
              <h5 class="f-w-600 mb-0">{{ user?.firstName }} {{ user?.lastName }}</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="form-group row">
          <label class="col-xl-3 col-md-4" for="name"> {{
              'Users.form.first-name' | translate
            }}</label>
          <input class="form-control col-xl-8 col-md-7" formControlName="name" id="firstName" type="text">
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-md-4" for="email"> {{ 'Users.form.email' | translate }}</label>
          <input disabled class="form-control col-xl-8 col-md-7" formControlName="email" id="username" type="email">
        </div>

        <div class="form-group row" *ngIf="country === 'UY'">
          <label class="col-xl-3 col-md-4" for="rut"> {{ 'Users.form.rut' | translate }}
          </label>
          <input class="form-control col-xl-8 col-md-7" formControlName="rut" id="rut" type="text">
        </div>
        <div class="form-group row" *ngIf="country === 'UY'">
          <label class="col-xl-3 col-md-4" for="ci"> {{ 'Users.form.ci' | translate }}</label>
          <input class="form-control col-xl-8 col-md-7" formControlName="ci" id="ci" type="text">
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-md-4" for="telephone"> {{
              'Users.form.telephone' | translate
            }}</label>
          <input class="form-control col-xl-8 col-md-7" formControlName="telephone" id="telephone" type="tel" maxlength="13">
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-md-4" for="uid"> {{ 'Users.form.address' | translate }}</label>
          <input class="form-control col-xl-8 col-md-7" formControlName="address" id="uid" type="text">
        </div>
      </div>
    </div>
    <!-- <h4>{{ 'Users.form.subtitle' | translate }}</h4> -->
    <div class="d-flex justify-content-center" *ngIf="hasChanges">
      <button (click)="editUser(accountForm)" class="btn btn-primary" type="button">{{
          'Settings.buttons.save' |
            translate
        }}
      </button>
    </div>
  </form>
</div>
