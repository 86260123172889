import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {User} from '../../../model/User';
import {UsersService} from '../../../services/users/users.service';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {AngularFireStorage} from '@angular/fire/storage';
import { getCountry } from 'src/main';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, OnDestroy {

  @Input()
  user: User;
  photoChangeDisabled: boolean = false;
  backendUser: User;
  public accountForm: UntypedFormGroup;
  private userUuid: string;

  private subscriptions = new Subscription();
  hasChanges: boolean = false;

  country = getCountry();

  constructor(private formBuilder: UntypedFormBuilder,
              private usersService: UsersService,
              private router: Router,
              private route: ActivatedRoute,
              private storage: AngularFireStorage,
              private toast: ToastrService,
              private translate: TranslateService) {
    this.accountForm = this.formBuilder.group({
      name: ['', []],
      rut: ['' || ''/*, [Validators.pattern(/\d+/)]*/],
      ci: ['' || ''/*, [Validators.pattern(/\d+/), Validators.maxLength(8)]*/],
      email: ['', [Validators.email]],
      telephone: ['' || '', [Validators.pattern(/\d+/), Validators.maxLength(13)]],
      address: ['' || '', []],
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(this.route.params
      .pipe(
        switchMap(params => {
          this.userUuid = params.uuid;
          if (params.uuid) {
            this.userUuid = params.uuid;
            return this.usersService.getUser(params.uuid);
          } else {
            this.userUuid = this.user.uuid;
            return this.usersService.getUser(this.user.uuid);
          }
        })
      )
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.createForm(this.user);
        }
      })
    );

  }

  /*ngAfterViewInit(): void {
    this.accountForm.controls['name'].setValue(`${this.user.firstName} ${this.user.lastName}`);
    this.accountForm.controls['ci'].setValue(this.user.ci);
    this.accountForm.controls['rut'].setValue(this.user.rut);
    this.accountForm.controls['email'].setValue(this.user.email);
    this.accountForm.controls['telephone'].setValue(this.user.telephone);
    this.accountForm.controls['address'].setValue(this.user.address);
  }*/

  createForm(user: User) {
    this.accountForm.controls['name'].setValue(user.firstName + ' ' + user.lastName);
    this.accountForm.controls['email'].setValue(user.email);
    this.accountForm.controls['telephone'].setValue(user.telephone);
    this.accountForm.controls['address'].setValue(user.address);
    this.accountForm.controls['ci'].setValue(this.user.ci);
    this.accountForm.controls['rut'].setValue(this.user.rut);
    this.subscriptions.add(this.accountForm.valueChanges.subscribe(data => {
      if (this.accountForm.valid) {
        let names = data.name.split(' ');
        this.user.firstName = names[0];
        this.user.lastName = names[1];
        this.user.email = data.email;
        this.user.telephone = data.telephone;
        this.user.rut = data.rut;
        this.user.ci = data.ci;
        this.user.address = data.address;
        this.hasChanges = true;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  editUser(data: UntypedFormGroup) {
    if (!data.valid) {
      Object.keys(data.controls).forEach((key: string) => {
        if (!data.controls[key].errors) {
          return;
        }
      });

      this.toast.warning(
        this.translate.instant('Toast.not-valid'),
        this.translate.instant('Toast.warning')
      );

      return;
    }

    if (data.controls['name'].value.split(' ').length === 1) {
      this.toast.error('El nombre no tiene un apellido');
      return;
    }

    this.subscriptions.add(this.usersService.editUserV2(this.userUuid, this.user).subscribe(user => {
        localStorage.setItem('user', JSON.stringify(user));
        this.usersService.getUserLocalStorage.next(user);
        this.router.navigate(['/users/list-user']).then(() => {

          this.toast.success(
            this.translate.instant('Toast.user.updated'),
            this.translate.instant('Toast.success')
          );
        });
      },
      error => {
        this.toast.error(
          error.error.message,
          this.translate.instant('Toast.error')
        );
      }));
  }

  public getImageData(event) {
    if (event.target.files.length < 1) {
      event.preventDefault();
      this.toast.info('Debes seleccionar un archivo!');
      return;
    }

    const mimesExtensions = {'image/jpeg': 'jpg', 'image/png': 'png'};
    const file = event.target.files[0];


    if (!Object.keys(mimesExtensions).includes(file.type)) {
      event.preventDefault();
      this.toast.error('El archivo no es una imágen válida!', `Error`,);
      return;
    }

    if (file.size > 204800 && event.target.name === 'profilePicture') { // La imagen no debe superar los 200 KB.
      event.preventDefault();
      this.toast.error('La imagen no puede superar los 200 KB!', `Error`);
      return;
    }

    const fileExtension = mimesExtensions[file.type];

    let pictureName = `${this.user.uuid}.${fileExtension}`;

    this.uploadImagesToFireStorage(file, pictureName)
      .then(refUrl => this.updateUserProfilePhoto(refUrl))
      .catch(error => this.toast.error(`No se pudo guardar la imagen`, `Error`));
  }

  private async uploadImagesToFireStorage(image: File, imageName: string) {
    return this.storage
      .upload(imageName, image)
      .then(taskSnapshot => taskSnapshot.ref.getDownloadURL());
  }

  private updateUserProfilePhoto(photoUrl: string): void {
    this.subscriptions.add(
      this.usersService.editUser(this.user.uuid, {image: photoUrl})
        .subscribe(
          user => {
            this.user = user;
            localStorage.setItem('user', JSON.stringify(user));
            this.usersService.getUserLocalStorage.next(user);
            // this.backendUser.image = user.image;
            this.photoChangeDisabled = false;
          },
          error => {
            console.error(error);
            alert('Hubo un problema!');
            this.photoChangeDisabled = false;
          }
        )
    );
  }
}

