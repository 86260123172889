import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SharedService} from '../../../../services/shared/shared.service';
import {Order} from 'src/app/model/Order';
import { getCountry } from 'src/main';

@Component({
    selector: 'app-order-modal',
    templateUrl: './order-modal.component.html',
    styleUrls: ['./order-modal.component.scss']
})
export class OrderModalComponent implements OnInit {

    order: Order;
    subTotal = 0;
    vendorProfit = 0;
    discount = 0;
    beeSellerProfit: number = 0;
    sellerProfit: number = 0;
    vendorProfitWithDiscount = 0;
    user = JSON.parse(localStorage.user);
    country = getCountry();

    constructor(public activeModal: NgbActiveModal, public sharedService: SharedService) {
    }

    ngOnInit(): void {
        this.order = this.sharedService.orderSelected;
        const itemList = this.order.itemList;

        this.subTotal = this.order.subTotal;
        this.vendorProfit = this.order.beeSellerProfit * 4;
        this.beeSellerProfit = this.order.beeSellerProfit;
        this.sellerProfit = this.order.sellerProfit;

        this.vendorProfitWithDiscount = this.vendorProfit;
        if (this.order.discountCouponPercentage != null) {
            this.discount = (((this.subTotal) * this.order.discountCouponPercentage) / 100);
            this.vendorProfitWithDiscount = this.vendorProfit - this.discount;
        }
    }

}
