import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { Ng2SmartTableModule } from 'ng2-smart-table';
import { UsersRoutingModule } from './users-routing.module';
import { ListUserComponent } from './list-user/list-user.component';
import { CreateUserComponent } from './create-user/create-user.component';

import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditUserComponent } from './edit-user/edit-user.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    ListUserComponent,
    CreateUserComponent,
    EditUserComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    UsersRoutingModule,
    TranslateModule
  ],
  exports: [
    ListUserComponent,
    CreateUserComponent,
    EditUserComponent
  ]
})
export class UsersModule {
}
