<div [class.open]="open" class="page-main-header">
  <div class="main-header-right row">
    <div class="main-header-left d-lg-none">
      <div class="logo-wrapper"><a [routerLink]="'/dashboard'">
        <img alt=""
             class="blur-up lazyloaded"
             src="assets/images/Bee-horizontal-white.png"></a>
      </div>
    </div>
    <div class="mobile-sidebar">
      <div class="media-body text-right switch-sm">
        <label class="switch">
          <a>
            <app-feather-icons (click)="collapseSidebar()" [icon]="'align-left'" id="sidebar-toggle">
            </app-feather-icons>
          </a>
        </label>
      </div>
    </div>
    <div class="nav-right col">
      <ul [ngClass]="{'open' : openNav}" class="nav-menus">
        <li>
          <a class="text-dark" href="javascript:void(0)" toggleFullscreen>
            <app-feather-icons [icon]="'maximize-2'"></app-feather-icons>
          </a>
        </li>
        <li class="onhover-dropdown" style="border-right: 1px solid #eff0f1"><a class="txt-dark"
                                                                                href="javascript:void(0)">
          <h6>{{lang}}</h6>
        </a>
          <ul class="language-dropdown onhover-show-div p-20">
            <li><a (click)="changeLanguage('en')" data-lng="en"><i class="flag-icon flag-icon-us"></i>
              {{'Lang.en' | translate}}</a></li>
            <li><a (click)="changeLanguage('es')" data-lng="es"><i class="flag-icon flag-icon-es"></i>
              {{'Lang.es' | translate}}</a></li>
          </ul>
        </li>
        <li class="onhover-dropdown">
          <div class="media align-items-center"><img
            alt="header-user"
            class="align-self-center pull-right img-60 rounded-circle"
            src="{{ user?.image || 'assets/images/user-generic.png'}}">

            <!-- Puntito animado que hace pensar que hay notificaciones. -->
            <!-- No se elimina el codigo para poder utilizarlo luego, cuando tengamos notificaciones. -->
            <!--            <div class="dotted-animation">-->
            <!--              <span class="animate-circle"></span>-->
            <!--              <span class="main-circle"></span>-->
            <!--            </div>-->
          </div>
          <ul class="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
            <li><a (click)="logout()">
              <app-feather-icons [icon]="'log-out'"></app-feather-icons>
              {{'Header.profile.logout' | translate}}
            </a></li>
          </ul>
        </li>
      </ul>
      <div (click)="openMobileNav()" class="d-lg-none mobile-toggle pull-right">
        <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
      </div>
    </div>
  </div>
</div>
