export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDmCb-Bd5A4C8L_atJnDPuk41VoEZWrO9Y',
    authDomain: 'mx-landings-test.firebaseapp.com',
    projectId: 'mx-landings-test',
    storageBucket: 'mx-landings-test.appspot.com',
    messagingSenderId: '147310335161',
    appId: '1:147310335161:web:48fd959d874a9106a7f768',
  },
  // url: 'http://localhost:3000/',
  url: 'https://mx-api.landings.bee-seller.com/',
  //frontOfficeBaseUrl: 'http://localhost:4200',
  frontOfficeBaseUrl: 'https://mx.marketplace.bee-seller.com/',
  // baseImgUrl: 'https://api-dev.pepeganga-api.com:8443/landing/image/api/bucket/download-file-from-store-bucket?pathFile=:imagePath&prefix=:imgSize',
  baseImgUrl:
    'https://mx.prestashop.bee-seller.com/:imgPath-large_default/prod-img.jpg',
  language: 'es_mx',
  country: 'MX',
  coupunsEnable: false,
  beeSellerRevenue: '',
  IVA: '',
};
