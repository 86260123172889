import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Menu, NavService} from '../../service/nav.service';
import {SharedService} from '../../../services/shared/shared.service';
import {User} from '../../../model/User';
import {UsersService} from '../../../services/users/users.service';

// import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {

  menuItems: Menu[];
  rol: string;
  user: User;
  frontOfficeBaseUrl: string;
  displayRole: string;

  constructor(private router: Router,
              public navServices: NavService,
              public sharedService: SharedService,
              private userService: UsersService,
              // private deviceService: DeviceDetectorService,
              @Inject('FRONT_OFFICE_BASE_URL') frontOfficeBaseUrl: string) {

    this.frontOfficeBaseUrl = frontOfficeBaseUrl;

    this.rol = localStorage.getItem('rol');
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    this.userService.getUserLocalStorage$.subscribe(user => this.user = user);
    this.displayRole = this.user?.userType.replace('VENDOR', 'Vendedor') || 'Vendedor';
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url) {
              this.setNavActive(items);
            }
            if (!items.children) {
              return false;
            }
            items.children.filter(subItems => {
              if (subItems.path === event.url) {
                this.setNavActive(subItems);
              }
              if (!subItems.children) {
                return false;
              }
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });
  }

  ngOnInit(): void {
    this.navServices.renderMenu();
    if (this.rol !== 'ADMIN') {
      this.sharedService.getStoreByVendor();
    }
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) {
          return false;
        }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }

  collapseSidebar() {
    const isMobile = (window.navigator.maxTouchPoints > 0);

    if (isMobile) {
      this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    }
    return;
  }
}
