import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RecuperarPasswordComponent } from './RecuperarPassword/RecuperarPassword.component';
import { SuccessRegisterComponent } from './success-register/success-register.component';
import { SingUpComponent } from './singup/singup.component';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: SingUpComponent,
  },
  {
    path: 'thankyou',
    component: SuccessRegisterComponent,
  },
  {
    path: 'recuperarpassword',
    component: RecuperarPasswordComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
