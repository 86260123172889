<img class="emoji"
    src="https://firebasestorage.googleapis.com/v0/b/landing-ppgg-test.appspot.com/o/icons%2Femotic%2Fsadface1.png?alt=media&token=46b97756-1d02-43c5-b17b-4005e7fcbfa4"
    alt="" *ngIf="emoji == 0">
<img class="emoji"
    src="https://firebasestorage.googleapis.com/v0/b/landing-ppgg-test.appspot.com/o/icons%2Femotic%2Fsadface2.png?alt=media&token=09081f2e-ec6c-48e5-a0c7-63e243b7d29c"
    alt="" *ngIf="emoji == 1">
<img class="emoji"
    src="https://firebasestorage.googleapis.com/v0/b/landing-ppgg-test.appspot.com/o/icons%2Femotic%2Fsurprisedface.png?alt=media&token=9ac34743-04ab-4227-81b1-d536154c9b68"
    alt="" *ngIf="emoji == 2">
<img class="emoji"
    src="https://firebasestorage.googleapis.com/v0/b/landing-ppgg-test.appspot.com/o/icons%2Femotic%2Fsmileface.png?alt=media&token=304f37d0-2196-4b74-8e83-ff7c63f1ef22"
    alt="" *ngIf="emoji == 3">
<img class="emoji"
    src="https://firebasestorage.googleapis.com/v0/b/landing-ppgg-test.appspot.com/o/icons%2Femotic%2Fhappyface.png?alt=media&token=e7724aba-39ac-4025-b25b-5dc8fa1647a2"
    alt="" *ngIf="emoji == 4">