<div class="container-fluid">
  <div class="card ">
    <div class="card-header">
      <h4>{{'Help.title' | translate}}</h4>
    </div>
    <div class="card-body">
      <h4>{{'Help.subtitle' | translate}}</h4>
      <br>
      <h5>#Introducción - Sobre Bee-Seller</h5>
      <div class="row">
        <div class="col-md-6">
          <div class="iframe-container">
            <iframe class="responsive-iframe" src="https://www.youtube.com/embed/B9ls_3O-v7U" title="Tutorial de Bee-Seller" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <br>
      <h5>#1 - Módulo "Registro"</h5>
      <div class="row">
        <div class="col-md-6">
          <div class="iframe-container">
            <iframe class="responsive-iframe" src="https://www.youtube.com/embed/3EkNF0-hATw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <br>
      <h5>#2 - Módulo "Personalización de tu tienda"</h5>
      <div class="row">
        <div class="col-md-6">
          <div class="iframe-container">
            <iframe class="responsive-iframe" src="https://www.youtube.com/embed/4oakgs5XJFE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <br>
      <h5>#3 - Módulo "Creación de catálogo"</h5>
      <div class="row">
        <div class="col-md-6">
          <div class="iframe-container">
            <iframe class="responsive-iframe" src="https://www.youtube.com/embed/_ZVr_rrqEZw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <br>
      <h5>#4 - Módulo "Edición de catálogo"</h5>
      <div class="row">
        <div class="col-md-6">
          <div class="iframe-container">
            <iframe class="responsive-iframe" src="https://www.youtube.com/embed/ZiUacs3fRp8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <br>
      <h5>#5 - Módulo "Cupones de descuento"</h5>
      <div class="row">
        <div class="col-md-6">
          <div class="iframe-container">
            <iframe class="responsive-iframe" src="https://www.youtube.com/embed/I-MJRsvecrY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <br>
      <h4>{{'Help.info' | translate}} <a target="_blank" href="https://www.youtube.com/channel/UC3_d9niFWNraglxiTcWfa8g">Youtube!</a></h4>
      </div>
    </div>
</div>
