<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">
      {{ "Catalog-sub.create.modal.title" | translate }}
    </h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="alert alert-primary" role="alert">
      {{ "Catalog-sub.create.modal.message1" | translate }}
      <strong>75%</strong>
      {{ "Catalog-sub.create.modal.message2" | translate }}
      <strong>25%</strong> {{ "Catalog-sub.create.modal.message3" | translate }}
    </div>
    <form class="needs-validation">
      <div class="form">
        <div class="form-group">
          <label for="validationCustom01" class="mb-1">{{
            "Catalog-sub.create.modal.name" | translate
          }}</label>
          <input
            class="form-control"
            id="validationCustom01"
            [(ngModel)]="this.newCatalog.name"
            name="inpurCatalogName"
            type="text"
          />
        </div>
        <div class="form-group range" *ngIf="country === 'UY'">
          <label for="validationCustom02" class="mb-1">{{
            "Catalog-sub.create.modal.percentage" | translate
          }}</label>
          <input
            class="form-control"
            id="validationCustom02"
            [(ngModel)]="this.newCatalog.percentage"
            name="inpurPorcentage"
            type="range"
            max="200"
            min="0"
            step="1"
          />
          <p id="”rangeValue”">{{ this.newCatalog.percentage }} %</p>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-secondary"
      type="button"
      (click)="this.createCatalog(true)"
      *ngIf="!editModal"
    >
      {{ "Catalog-sub.create.modal.publish" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="this.createCatalog(true)"
      *ngIf="editModal"
    >
      {{ "Catalog-sub.create.modal.save" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="this.createCatalog(false)"
      *ngIf="!editModal"
    >
      {{ "Catalog-sub.create.modal.save" | translate }}
    </button>
    <button
      class="btn btn-secondary"
      type="button"
      data-dismiss="modal"
      (click)="activeModal.dismiss()"
    >
      {{ "Catalog-sub.create.modal.close" | translate }}
    </button>
  </div>
</div>
