import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-product-skeleton",
    templateUrl: "./produc-skeleton.component.html",
    styleUrls: ["./produc-skeleton.component.scss"],
})
export class ProductSkeletonComponent implements OnInit {
    constructor() { }
    ngOnInit(): void {

    }
}