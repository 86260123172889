import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TopProduct } from 'src/app/model/top-product';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    private ordersUrl: string;
    constructor(
        private httpClient: HttpClient,
        @Inject('BASE_URL') baseUrl: string
    ) {
        this.ordersUrl = baseUrl + 'orders';
        // this.ordersUrl = 'http://localhost:3000/orders';
    }

    getTopProducts() {
        return this.httpClient.get<TopProduct[]>(this.ordersUrl + '/statics/top-products');
    }
}