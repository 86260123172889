import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ListUserComponent} from './list-user/list-user.component';
import {CreateUserComponent} from './create-user/create-user.component';
import {EditUserComponent} from './edit-user/edit-user.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list-user',
        component: ListUserComponent,
        data: {
          title: 'Sidebar.users',
          breadcrumb: 'Users.list-users.title'
        }
      },
      {
        path: 'create-user',
        component: CreateUserComponent,
        data: {
          title: 'Sidebar.users',
          breadcrumb: 'Users.create-user.title'
        }
      },
      {
        path: 'edit-user/:uuid',
        component: EditUserComponent,
        data: {
          title: 'Sidebar.users',
          breadcrumb: 'Users.edit-user.title'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule {
}
