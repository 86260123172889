<div class="container text-center mt-4">
  <div class="row d-flex justify-content-center align-items-center">
    <!-- <div class="d-flex justify-content-center align-items-center"> -->
    <div class="card-left d-flex align-items-center justify-content-xl-center p-0">
      <div class="bg-primary p-4 shadow-lg border rounded">
        <div class="svg-icon2">
          <img [width]="190" alt="" src="../../../../assets/images/BeeSeller-07.png" />
        </div>
      </div>
    </div>

    <div class="col-md-6 p-0 card-right p-4 shadow-lg border rounded rounded-4 border-start-0">
      <h3 class="text-left card-title">{{'ResetPassword.title' | translate}}</h3>
      <hr class="border-top border-3 opacity-75" />
      <div class="card-body text-left form-floating">
        <div class="form-floating">
          <label class="text-left" for="exampleInputEmail1">
            {{'ResetPassword.label' | translate}}
          </label>
          <input class="form-control" [(ngModel)]="email" id="exampleInputEmail1" name="login[email]"
            placeholder="email@example.com" required type="email">
        </div>
        <div class="form-button" style="text-align: center">
          <button class="btn btn-primary mt-4" type="button" (click)="resetPassword()">
            {{'ResetPassword.btn' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</div>