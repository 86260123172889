import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareButtonComponent } from './shar-button/share-button.component';



@NgModule({
  declarations: [
    ShareButtonComponent
  ],
  imports: [
    CommonModule,
    ShareButtonsModule,
    ShareIconsModule,
  ],
  exports: [
    ShareButtonComponent
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class ShareButtonModule { }
