<div class="container-fluid">
    <div class="row-md-12">
        <kendo-skeleton shape="text" width="100%" height="rectangle"></kendo-skeleton>
    </div>
    <div class="row-md-12">
        <div class="row justify-content-center align-items-start">
            <div class="col-md-6">
                <kendo-skeleton shape="rectangle" width="50%" height="100%"></kendo-skeleton>
            </div>
            <div class="col-md-6">
                <!--    <h6>{{ 'Product-details.category' | translate }} {{ this.selectedProduct.categories[0].description }}</h6>-->
                <div class="row">
                    <kendo-skeleton shape="text" width="100%" height="100%"></kendo-skeleton>
                </div>

                <div class="row">
                    <kendo-skeleton shape="text" width="100%" height="rectangle"></kendo-skeleton>
                </div>

                <div class="row">
                    <kendo-skeleton shape="text" width="100%" height="rectangle"></kendo-skeleton>
                </div>
                <div class="row">
                    <kendo-skeleton shape="text" width="100%" height="rectangle"></kendo-skeleton>
                </div>
                <div class="row">
                    <kendo-skeleton shape="text" width="100%" height="rectangle"></kendo-skeleton>
                </div>

                <div class="row">
                    <kendo-skeleton shape="text" width="100%" height="rectangle"></kendo-skeleton>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <kendo-skeleton shape="text" width="100%" height="rectangle"></kendo-skeleton>
    </div>
</div>