import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { RankingSellers } from "src/app/model/ranking-sellers";
import { GamificationService } from "src/app/services/gamification/gamification.service";

@Component({
    selector: 'app-modal-ranking',
    templateUrl: './modal-ranking.component.html',
    styleUrls: ['./modal-ranking.component.scss']
})
export class ModalRankingComponent {
    ranking: RankingSellers[] = [];
    month: string = '';

    constructor(public activeModal: NgbActiveModal, private gamificationService: GamificationService) {
        this.ranking = this.gamificationService.rankingSellers;
        this.month = this.primeraLetraMayuscula(this.ranking[0].period);
    }

    private primeraLetraMayuscula(cadena) {
        return cadena.replace(/^\w/, (c) => c.toUpperCase());
    }
}