import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DefaultSettingProduct } from 'src/app/model/default-setting';
import { OrderService } from 'src/app/services/orders/order.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { SettingService } from 'src/app/services/setting/setting.setvice';
import { StoreService } from 'src/app/services/store/store.service';

@Component({
  selector: 'app-row-products',
  templateUrl: './row-products.component.html',
  styleUrls: ['./row-products.component.scss'],
})
export class RowProductsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  monthProduct: DefaultSettingProduct = {} as DefaultSettingProduct;
  topProduct: DefaultSettingProduct = {} as DefaultSettingProduct;

  constructor(
    private orderService: OrderService,
    private storeService: StoreService,
    private productService: ProductsService,
    private settingService: SettingService
  ) {}

  ngOnInit(): void {
    this.getTopProduct();
    this.getProductMonth();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getProductMonth() {
    this.subscription.add(
      this.settingService.getProductMonth().subscribe((response: any) => {
        if (response.sku !== '') {
          this.productService
            .getProductBySku(response.sku)
            .subscribe((product: any) => {
              this.monthProduct = {
                sku: product.sku,
                image: product.images[0],
                title: product.artNombreMl,
                description: product.catalogDescrip,
                price: product.pricePesos,
                stock: product.currentStock,
                category: product?.categories?.[0]?.name || 'Sin categoría',
              };
            });
        }
      })
    );
  }

  getTopProduct() {
    this.subscription.add(
      this.orderService.getTopProducts().subscribe((response: any) => {
        if (response[0] !== undefined) {
          this.productService
            .getProductBySku(response[0]?.sku)
            .subscribe((product: any) => {
              this.topProduct = {
                sku: product.sku,
                image: product.images[0],
                title: product.artNombreMl,
                description: product.catalogDescrip,
                price: product.pricePesos,
                stock: product.currentStock,
                category: product?.categories?.[0]?.name || 'Sin categoría',
              };
            });
        }
      })
    );
  }
}
