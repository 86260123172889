import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DefaultSetting } from "src/app/model/default-setting";

@Injectable({
    providedIn: 'root'
})
export class SettingService {
    private baseUrl: string;
    isInCatalog$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    constructor(
        @Inject('BASE_URL') baseUrl: string,
        private httpClient: HttpClient,
    ) {
        this.baseUrl = baseUrl + 'microsite/default-setting';
    }

    getIsInCatalog() {
        return this.isInCatalog$.asObservable();
    }
    setIsInCatalog(isInCatalog: boolean) {
        this.isInCatalog$.next(isInCatalog);
    }

    createDefaultSetting(defaultSetting: DefaultSetting) {
        return this.httpClient.post(this.baseUrl, defaultSetting);
    }

    getDefaultSetting() {
        return this.httpClient.get<DefaultSetting>(this.baseUrl);
    }

    updateDefaultSetting(defaultSetting: DefaultSetting) {
        return this.httpClient.put(this.baseUrl, defaultSetting);
    }

    updateProductMonth(productMonth: string) {
        return this.httpClient.put(this.baseUrl + '/product-month', productMonth);
    }

    getProductMonth() {
        return this.httpClient.get(this.baseUrl + '/product-month');
    }

    getCatalogName() {
        return this.httpClient.get<string>(this.baseUrl + '/catalog-name');
    }

    updateCatalogName(catalogName: string) {
        return this.httpClient.put(this.baseUrl + '/catalog-name', catalogName);
    }

    updateProducts(products: string[]) {
        return this.httpClient.put(this.baseUrl + '/products', products);
    }

    getProducts() {
        return this.httpClient.get<string[]>(this.baseUrl + '/products');
    }

    getPercentage() {
        return this.httpClient.get<number>(this.baseUrl + '/percentage');
    }

    updatePercentage(percentage: number) {
        return this.httpClient.put(this.baseUrl + '/percentage', percentage);
    }
}