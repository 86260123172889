import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { CatalogPublished } from "src/app/model/CatalogPublished";
import { DefaultSettingProduct } from "src/app/model/default-setting";
import { ProductsService } from "src/app/services/products/products.service";
import { SettingService } from "src/app/services/setting/setting.setvice";
import { StoreService } from "src/app/services/store/store.service";

@Component({
    selector: 'app-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit, OnDestroy {
    @Input()
    title: string = '';
    @Input()
    subtitle?: string;
    @Input()
    product?: DefaultSettingProduct = {} as DefaultSettingProduct;
    private subscription: Subscription = new Subscription();

    isInCatalog: boolean = false;

    constructor(
        public settingService: SettingService,
        private productService: ProductsService,
    ) { }

    ngOnInit(): void {
        if (!this.product.sku) {
            this.subscription.add(this.settingService.getProductMonth().subscribe((response: any) => {
                if (response.sku) {
                    this.getProductSku(response.sku);
                }
            }));
        }
        this.settingService.getIsInCatalog().subscribe((isInCatalog) => {
            this.isInCatalog = isInCatalog
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getProductSku(sku: string) {
        this.productService.getProductBySku(sku).subscribe((product) => {
            this.product = {
                sku: product.sku,
                image: product.images[0],
                title: product.artNombreMl,
                description: product.catalogDescrip,
                price: product.pricePesos,
                stock: product.currentStock,
                category: product?.categories?.[0]?.name || 'Sin categoría',
            };
        }, (error) => {
            console.error(error);
        });
    }
}