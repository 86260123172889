import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-RecuperarPassword',
  templateUrl: './RecuperarPassword.component.html',
  styleUrls: ['./RecuperarPassword.component.css']
})
export class RecuperarPasswordComponent implements OnInit {
  loadingLogin: boolean = false;

  constructor(private authService: AuthService, private route: Router, public toast: ToastrService) { }

  ngOnInit() {
  }
  email: string = '';
  resetPassword() {

    if (this.email.length == 0) {
      this.toast.error('Please enter a valid email address');
      return;
    }

    this.authService.resetPass(this.email)
      .then(() => {
        this.toast.success('Recibirá un email con el link para cambiar su contraseña');
        this.route.navigate(['/auth/login']);
      }).catch((error) => {
        this.toast.error("El email es incorrecto o su cuenta de usuario no existe, \npor favor verifique su email.", "Error email invalid");
      })
  }
}
