<div class="card o-hidden widget-cards h-100">
    <div class="row justify-content-center align-items-center p-3 h-100">
        <h3 class="text-uppercase text-center">ANALÍTICA DE LA TIENDA</h3>
        <div class="filter-container">
                <form [formGroup]="dateFilterForm" >
                    <div class="rounded-left text-center p-2">
                        <span class="mx-1">{{'Orders.filter.dateStart' | translate}}</span>
                    </div>
                    <div>
                        <input class="form-control" type="date" formControlName="dateStart" />
                    </div>
                    <div class="text-center p-2">
                        <span class="mx-1"> {{'Orders.filter.dateEnd' | translate}} </span>
                    </div>
                    <div>
                        <input class="form-control" type="date" formControlName="dateEnd" />
                    </div>
                </form>
        </div>
        
        <div>
            <div class="subtitle">Estadísticas de tu Tienda</div>
            <div *ngIf="isLoading" class="item">
                <div class="spinner-border"></div>
            </div>
            <div *ngIf="isLoading" class="item">
                <div class="spinner-border"></div>
            </div>
            <div *ngIf="!isLoading" class="item">Visitas: {{ screenPageViews }}</div>
            <div *ngIf="!isLoading" class="item">Usuarios: {{ activeUsers }}</div> 
        </div>
    </div>
</div>