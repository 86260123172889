<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card tab2-card">
        <div class="card-body tab2-card">
          <ngb-tabset class="tab-coupon">
            <ngb-tab title="{{ 'Users.form.tab-title' | translate }}">
              <ng-template ngbTabContent>
                <div aria-labelledby="account-tab" class="tab-pane fade active show" id="account" role="tabpanel">
                  <form [formGroup]="accountForm" class="needs-validation user-add" novalidate="">
                    <div class="form-group row">
                      <label class="col-xl-3 col-md-4"
                             for="userType"><span>*</span> {{ 'Users.form.user-type' | translate }}</label>
                      <input class="form-control col-xl-8 col-md-7" formControlName="userType" id="userType" required
                             readonly type="text">
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-3 col-md-4"
                             for="username"><span>*</span> {{ 'Users.form.username' | translate }}</label>
                      <input class="form-control col-xl-8 col-md-7" formControlName="username" id="username" required
                             type="text">
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-3 col-md-4"
                             for="password"><span>*</span> {{ 'Users.form.password' | translate }}</label>
                      <input class="form-control col-xl-8 col-md-7" formControlName="password" id="password" required
                             type="password">
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-3 col-md-4"
                             for="email"><span>*</span> {{ 'Users.form.email' | translate }}</label>
                      <!-- TODO: translate -->
                      <input class="form-control col-xl-8 col-md-7" formControlName="email" id="email" required
                             type="email">
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-3 col-md-4"
                             for="firstName"><span>*</span> {{ 'Users.form.first-name' | translate }}</label>
                      <input class="form-control col-xl-8 col-md-7" formControlName="firstName" id="firstName"
                             required=""
                             type="text">
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-3 col-md-4"
                             for="lastName"><span>*</span> {{ 'Users.form.last-name' | translate }}</label>
                      <input class="form-control col-xl-8 col-md-7" formControlName="lastName" id="lastName" required
                             type="text">
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-3 col-md-4" for="ci"><span>*</span> {{ 'Users.form.dni' | translate }}</label>
                      <input class="form-control col-xl-8 col-md-7" formControlName="ci" id="ci" required
                             type="text">
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-3 col-md-4" for="rut"><span>*</span> {{ 'Users.form.rut' | translate }}
                      </label>
                      <input class="form-control col-xl-8 col-md-7" formControlName="rut" id="rut" required
                             type="text">
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-3 col-md-4"
                             for="address"><span>*</span> {{ 'Users.form.address' | translate }}
                      </label>
                      <input class="form-control col-xl-8 col-md-7" formControlName="address" id="address" required
                             type="text">
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-3 col-md-4"
                             for="businessName"><span>*</span> {{ 'Users.form.businessName' | translate }}
                      </label>
                      <input class="form-control col-xl-8 col-md-7" formControlName="businessName" id="businessName"
                             required
                             type="text">
                    </div>
                    <div class="form-group row">
                      <label class="col-xl-3 col-md-4" for="store"><span>*</span> {{ 'Users.form.store' | translate }}
                      </label>
                      <input class="form-control col-xl-8 col-md-7" formControlName="store" id="store" required
                             type="text">
                    </div>
                  </form>
                </div>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>
          <div class="pull-right">
            <button (click)="createUser(accountForm)" class="btn btn-primary" type="button">{{'Settings.buttons.save' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
