import { Component, OnDestroy, OnInit } from '@angular/core';
import { UsersService } from '../../../services/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit, OnDestroy {
  public userList = [];
  public settings = {};
  tableSource = new LocalDataSource();

  private subscriptions = new Subscription();

  constructor(private usersService: UsersService,
    private router: Router,
    private toast: ToastrService,
    private translate: TranslateService) {

    this.translate.onLangChange
      .subscribe(() => this.initTableSettings());

    // Se inicializa la tabla luego de haber obtenido una clave desde el JSON.
    // De esta forma podemos asegurar que cuando se inicializa la tabla por primer vez,
    // el archivo de traducciones ya fue descargado.
    this.translate.get('Users.list-users.table.column.username')
      .subscribe(() => this.initTableSettings());

    this.initTableSettings();
  }

  ngOnInit() {
    this.subscriptions.add(this.usersService.getUsers().subscribe(value => {
      this.tableSource.load(value).then();
    }));
  }

  editUser(event: any) {
    this.router.navigate(['/', 'users', 'edit-user', event.data.uuid]).then();
  }

  deleteUser(event: any) {
    this.subscriptions.add(this.usersService.deleteUser(event.data.uuid).subscribe(() => {
      this.tableSource.remove(event.data).then(() => {
        this.toast.success(
          this.translate.instant('Toast.user.deleted'),
          this.translate.instant('Toast.success')
        );
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initTableSettings() {
    const asd = this.translate.instant('Users.list-users.table.column.actions');
    this.settings = {
      noDataMessage: this.translate.instant('Users.list-users.table.column.no-results'),
      sort: true,
      mode: 'external',
      actions: {
        columnTitle: asd,
        add: false,
        position: 'right'
      },
      columns: {
        username: {
          filter: false,
          title: this.translate.instant('Users.list-users.table.column.username')
        },
        email: {
          filter: false,
          title: this.translate.instant('Users.list-users.table.column.email')
        },
        firstName: {
          filter: false,
          title: this.translate.instant('Users.list-users.table.column.firstName')
        },
        lastName: {
          filter: false,
          title: this.translate.instant('Users.list-users.table.column.lastName')
        },
        telephone: {
          filter: false,
          // title: this.translate.instant('Users.list-users.table.column.telephone')
          title: 'Teléfono'
        },
        rut: {
          filter: false,
          title: this.translate.instant('Users.list-users.table.column.rut')
        },
        ci: {
          filter: false,
          title: this.translate.instant('Users.list-users.table.column.ci')
        },
        country: {
          filter: false,
          title: this.translate.instant('Users.list-users.table.column.country')
        },
        // telephone: {
        // filter: false,
        // title: this.translate.instant('Users.list-users.table.column.telephone')
        // }
      }
    };
  }
}
