import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SharedService} from '../../../../services/shared/shared.service';

@Component({
  selector: 'app-publish-ecommerce-modal',
  templateUrl: './publish-ecommerce-modal.component.html',
  styleUrls: ['./publish-ecommerce-modal.component.scss']
})
export class PublishEcommerceModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, public sharedService: SharedService) { }

  ngOnInit(): void {
  }

  publishVendorEcommerce() {
    this.sharedService.ecommercePublished = !this.sharedService.ecommercePublished;
    this.activeModal.dismiss();
  }

  cancel() {
    this.activeModal.dismiss();
  }

}
