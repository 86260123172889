import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SettingRoutingModule } from './setting-routing.module';
import { SettingComponent } from './setting.component';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PropertyItemComponent } from './widgets/property-item/property-item.component';
import { FormEditStoreComponent } from './form-edit-store/form-edit-store.component';
import { TemplatesComponent } from './form-edit-store/templates/templates.component';
import { FormEditNetworkComponent } from './form-edit-network/form-edit-network.component';
import { AppSeparatorComponent } from './widgets/saparator/separator.component';
import { UsersModule } from '../users/users.module';
import { DefaultSettingComponent } from './default-setting/default-setting.component';
import { ProductMonthSettingComponent } from './default-setting/product-month/product-month.component';
import { CatalogDefaultSettingComponent } from './default-setting/catalog-default/catalog-default.component';

@NgModule({
  declarations: [
    SettingComponent,
    FormEditStoreComponent,
    FormEditNetworkComponent,
    TemplatesComponent,
    PropertyItemComponent,
    AppSeparatorComponent,
    DefaultSettingComponent,
    ProductMonthSettingComponent,
    CatalogDefaultSettingComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    SettingRoutingModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    NgbNavModule,
    UsersModule
  ]
})
export class SettingModule { }
