<form (ngSubmit)="editUserSocialNetworks()" *ngIf="this.userSocialNetworksEditMode" [formGroup]="userSocialNetworksForm"
    class="mt-4">
    <div class="alert alert-primary" role="alert">
        <i class="fa fa-info-circle"></i> Para eliminar una red social, deja el campo en blanco.
    </div>
    <div class="form-group">
        <label class="pull-left" for="facebook">Facebook (usuario)</label>
        <div class="input-group mb-3">
            <input class="form-control form-control-sm" formControlName="facebook" id="facebook" name="facebook"
                type="text">
            <div class="input-group-append">
                <button (click)="userSocialNetworksForm.controls.facebook.reset()" class="btn-sm btn-secondary"
                    type="button">
                    Eliminar
                </button>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label class="pull-left" for="instagram">Instagram (usuario)</label>
        <div class="input-group mb-3">
            <input class="form-control form-control-sm" formControlName="instagram" id="instagram" name="instagram"
                type="text">
            <div class="input-group-append">
                <button (click)="userSocialNetworksForm.controls.instagram.reset()" class="btn-sm btn-secondary"
                    type="button">
                    Eliminar
                </button>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label class="pull-left" for="twitter">Twitter (usuario)</label>
        <div class="input-group mb-3">
            <input class="form-control form-control-sm" formControlName="twitter" id="twitter" name="twitter"
                type="text">
            <div class="input-group-append">
                <button (click)="userSocialNetworksForm.controls.twitter.reset()" class="btn-sm btn-secondary"
                    type="button">
                    Eliminar
                </button>
            </div>
        </div>
    </div>
    <button class="btn btn-secondary" style="padding: 0.4rem 0.5rem; font-size: 12px" type="submit">
        {{'Settings.buttons.save' | translate}}
    </button>
</form>

<div class="pull-right mt-4">
    <button (click)="changeUserSocialNetworksEditMode()" class="btn-sm btn-primary" style="color: #0a0100">
        <span *ngIf="!this.userSocialNetworksEditMode">Editar</span>
        <span *ngIf="this.userSocialNetworksEditMode">Cancelar</span>
    </button>
</div>