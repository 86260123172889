import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UserCreate } from 'src/app/model/User';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UsersService } from 'src/app/services/users/users.service';
import {getCountry} from '../../../../../main';

@Component({
  selector: 'app-personal-form',
  templateUrl: './personalForm.component.html',
  styleUrls: ['./personalForm.component.scss']
})
export class PersonalFormComponent implements OnInit, OnDestroy {
  @Input()
  disableForm1: number;
  @Input()
  acountInfo: any;
  @Output() updateDisableForm1: EventEmitter<any> = new EventEmitter<any>();
  public subscriptions = new Subscription();
  public formPersonalInfo: UntypedFormGroup;
  public readonly defaultDocumentType = 'invalid';
  public documentType = this.defaultDocumentType;
  public disableRegisterButton = false;
  public loadingRegister = false;
  country = getCountry();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private toast: ToastrService,
    private translate: TranslateService,
    private usersService: UsersService,
    private router: Router,
    private authService: AuthService) {
    this.createFormPersonalInfo();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  createFormPersonalInfo() {
    this.formPersonalInfo = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      ci: [null],
      telephone: ['', [Validators.required]],
      rut: [null],
      address: ['', [Validators.required]],
      businessName: ['']
    });
  }

  async back() {
    this.updateDisableForm1.emit({ disableForm: 1 });
  }


  async register($event) {
    $event.preventDefault();
    this.loadingRegister = !this.loadingRegister;
    this.disableRegisterButton = true;

    const user: UserCreate = { ...this.acountInfo, ...this.formPersonalInfo.value };
    user.enabled = true;
    user.locked = false;
    const names = user.firstName.split(' ');
    user.firstName = names[0];
    user.lastName = names[1];
    if (String(user.businessName).trim() === '') {
      user.businessName = 'vendor bee seller';
    }

    this.subscriptions.add(this.usersService.signup(user).subscribe(
      () => {
        this.disableRegisterButton = false;
        this.loginAuthService(this.acountInfo.email, this.acountInfo.password);
      },
      error => {
        let errMsg: string;
        if (error.status === 409) {
          errMsg = this.translate.instant('Toast.user.conflict');
        }

        if (error.status === 400) {
          errMsg = `Algo no se ve bien en los siguientes campos: ${Object.keys(error.error.errors).join(', ')}`;
        }

        this.toast.error(
          errMsg || error.error.message,
          this.translate.instant('Toast.error')
        );

        this.disableRegisterButton = false;
        this.loadingRegister = !this.loadingRegister;
      }
    ));
  }

  documentTypeChange(): void {
    if (this.documentType === 'ci') {
      this.formPersonalInfo.controls.ci.setValidators([Validators.required]);
      this.formPersonalInfo.controls.rut.setValidators(null);

    } else {
      this.formPersonalInfo.controls.ci.setValidators(null);
      this.formPersonalInfo.controls.rut.setValidators([Validators.required]);
    }

    this.formPersonalInfo.controls.ci.reset();
    this.formPersonalInfo.controls.rut.reset();
  }

  async loginAuthService(mail: string, pass: string) {
    try {
      const navigate = () => {
        this.router.navigate(['/thankyou']).then().catch(console.error);
      };
      await this.authService.login(mail, pass, navigate);
      this.loadingRegister = !this.loadingRegister;
      this.subscriptions.unsubscribe();
    } catch (error) {
      this.loadingRegister = !this.loadingRegister;
      this.toast.error(
        this.translate.instant('Login.wrong-userpass'),
        this.translate.instant('Toast.error')
      );
    }
  }
}
