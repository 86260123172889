import { Component, OnInit } from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SharedService} from '../../../../services/shared/shared.service';
import {CatalogService} from '../../../../services/catalog/catalog.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-publish-catalog-modal',
  templateUrl: './publish-catalog-modal.component.html',
  styleUrls: ['./publish-catalog-modal.component.scss']
})
export class PublishCatalogModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
              public catalogService: CatalogService,
              private modalService: NgbModal,
              private router: Router,
              private toaster: ToastrService,
              private translate: TranslateService,
              public sharedService: SharedService) { }

  ngOnInit(): void {
  }

  publishCatalog() {
    this.catalogService.publishCatalog(this.sharedService.catalogId).subscribe(res => {
      this.modalService.dismissAll();
      this.toaster.success(this.translate.instant('Toast.catalog.published'), this.translate.instant('Toast.success'), {
        progressBar: true,
        timeOut: 3000
      });
      this.router.navigate(['/catalogs/catalog-list']);
    });
  }

  cancel() {
    this.activeModal.dismiss();
  }
}
