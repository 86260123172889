import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ListCouponComponent} from './list-coupon/list-coupon.component';
import {CreateCouponComponent} from './create-coupon/create-coupon.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list-coupons',
        component: ListCouponComponent,
        data: {
          title: 'Coupon.list',
          breadcrumb: 'Coupon.list'
        }
      },
      {
        path: 'create-coupons',
        component: CreateCouponComponent,
        data: {
          title: 'Coupon.create',
          breadcrumb: 'Coupon.create'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CouponsRoutingModule {
}
