<!--<div class="row justify-content-around align-items-center">-->
<div class="row-products">
  <app-product-month-card
    title="{{ 'Modal-Product.title' | translate }}"
    [product]="monthProduct"
  ></app-product-month-card>

  <app-product-month-card
    title="{{ 'Modal-Product.title2' | translate }}"
    [product]="topProduct"
  ></app-product-month-card>

  <app-analytics-card></app-analytics-card>
</div>
