<div class="container-fluid">
  <div class="row">
    <div class="col-xl-12">
      <div class="card tab2-card text-color">
        <div class="card-body">
          <ngb-tabset class="tab-coupon profile-tabs">
            <ngb-tab>
              <ng-template ngbTabTitle>
                <app-feather-icons [icon]="'shopping-cart'"></app-feather-icons>
                {{ "Modal-Product.title" | translate }}
              </ng-template>
              <ng-template ngbTabContent>
                <app-product-month></app-product-month>
              </ng-template>
            </ngb-tab>
            <ngb-tab>
              <ng-template ngbTabTitle>
                <app-feather-icons [icon]="'user'"></app-feather-icons>
                Catalogo
              </ng-template>
              <ng-template ngbTabContent>
                <app-catalog-default></app-catalog-default>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>
        </div>
      </div>
    </div>
  </div>
</div>
