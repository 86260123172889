<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title f-w-600">{{'Coupon.title-modal' | translate}}</h5>
    <button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form action="" >
    <div class="alert alert-primary" role="alert">
      {{'Coupon.message1' | translate}} {{catalogProfitPercentage}}%, {{'Coupon.message2' | translate}}
      <strong>{{'Coupon.message3' | translate}} {{(maxDiscountPercentageAllowed).toFixed(2)}} {{'Coupon.message4' | translate}}</strong>
    </div>
    <div class="row">
      <div class="col-md-4">{{'Coupon.code' | translate}}</div>
      <div class="col-md-4">{{'Coupon.percentage' | translate}}</div>
      <div class="col-md-4 text-left">{{'Coupon.profit' | translate}}</div>
    </div>
    <hr style="margin-top: 0;"/>
    <div *ngFor="let coupon of coupons; index as i" class="form-group row">
      <div class="checkbox checkbox-primary col-md-4">
        <input (click)="couponClickEvent($event)" data-original-title=""
               id="{{coupon.uuid}}" title="" type="checkbox">
        <label for="{{coupon.uuid}}"> {{coupon.code}}</label>
      </div>
      <div class="col text-center">
        <label>
          <input id="{{prefixId}}{{coupon.uuid}}" 
          type="number" min="0"
          max="{{(maxDiscountPercentageAllowed).toFixed(0)}}"
          (input)="inputChangeEvent($event.target , i)"
          value="0">
          <strong>%</strong>
        </label>
      </div>
      <div class="col text-right"><strong>{{sellerProfits[i].toFixed(2)}} %</strong></div>
    </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="linkDiscountCoupons()" class="btn btn-primary"
            type="button">{{'Dashboard.modal.confirm' | translate}}</button>
    <button (click)="activeModal.dismiss()" class="btn btn-secondary"
            type="button">{{'Dashboard.modal.cancel' | translate}}</button>
  </div>
</div>
