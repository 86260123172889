<form
  [formGroup]="formUserAcount"
  class="form-horizontal auth-form p-3"
  novalidate=""
  *ngIf="disableForm1 === 1"
  (change)="onChange()"
>
  <div class="row">
    <div class="col form-group">
      <input
        class="form-control"
        formControlName="email"
        id="exampleInputEmail2"
        name="register[email]"
        placeholder="Email*"
        required
        type="email"
      />
    </div>
  </div>
  <div class="row"></div>
  <div class="row">
    <div class="col form-group" style="display: flex">
      <input
        class="form-control"
        formControlName="password"
        name="register[password]"
        placeholder="{{ 'Register.placeholder.password' | translate }}"
        required
        [type]="show ? 'text' : 'password'"
      />
      <div class="center view-pass">
        <a (click)="this.showPassword()" class="view-pass-eye"
          ><app-feather-icons
            [icon]="'eye'"
            *ngIf="!show; else eyeHide"
          ></app-feather-icons>
          <ng-template #eyeHide>
            <app-feather-icons
              [icon]="'eye-off'"
            ></app-feather-icons> </ng-template
        ></a>
      </div>
    </div>
  </div>
  <!-- Cuarta linea. Confirmar password -->
  <div class="row">
    <div class="col form-group" style="display: flex">
      <input
        class="form-control"
        formControlName="repeatPassword"
        name="register[repeatPassword]"
        placeholder="{{ 'Register.placeholder.repeatPassword' | translate }}"
        required
        [type]="repeatShow ? 'text' : 'password'"
      />
      <div class="center view-pass">
        <a (click)="this.showRepeatPassword()" class="view-pass-eye">
          <app-feather-icons
            [icon]="'eye'"
            *ngIf="!repeatShow; else eyeHide"
          ></app-feather-icons>
          <ng-template #eyeHide>
            <app-feather-icons [icon]="'eye-off'"></app-feather-icons>
          </ng-template>
        </a>
      </div>
    </div>
  </div>

  <!-- Boton siguiente -->
  <div class="form-button" style="text-align: center">
    <button
      type="button"
      class="btn btn-primary mt-4"
      [disabled]="disableNextButton"
      (click)="registerAcount(formUserAcount)"
    >
      {{ "SignUp.continue" | translate }}
    </button>
  </div>
</form>
