<div
  class="row justify-content-center align-items-center p-3"
  *ngIf="!isInCatalog"
>
  <h5 class="text-uppercase text-center btn btn-primary" (click)="onClickAdd()">
    {{ "Modal-Product.add-catalogue" | translate }}
  </h5>
</div>

<div
  class="row justify-content-center align-items-center p-3"
  *ngIf="isInCatalog"
>
  <h5
    class="text-uppercase text-center text-bold btn btn-outline-secondary"
    (click)="onRemoveProduct()"
  >
    {{ "Modal-Product.remove-catalogue" | translate }}
  </h5>
</div>

<!--  -->
