import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { CountToModule } from 'angular-count-to';
import { ChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartistModule } from 'ng-chartist';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { EmoticComponent } from './emotic/emotic.component';
import { RowProgressComponent } from './row-prgress/row-prgress.component';
import { RowProductsComponent } from './row-products/row-products.component';
import { RowRankingComponent } from './row-ranking/row-ranking.component';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

@NgModule({
  declarations: [
    DashboardComponent,
    EmoticComponent,
    RowProgressComponent,
    RowProductsComponent,
    RowRankingComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    CountToModule,
    SharedModule,
    ChartsModule,
    Ng2GoogleChartsModule,
    NgxChartsModule,
    ChartistModule,
    TranslateModule,
    NgxPaginationModule,
    ShareButtonsModule,
    ShareIconsModule
  ]
})
export class DashboardModule { }
