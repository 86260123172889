<div class="modal-content rounded-3 fw-bolder">
  <ng-container *ngIf="loagin; else elseTemplate">
    <app-product-skeleton></app-product-skeleton>
  </ng-container>
  <ng-template #elseTemplate>
    <div class="modal-header">
      <h5 class="modal-title f-w-600" id="exampleModalLabel">
        {{ this.selectedProduct.artNombreMl }}
      </h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row justify-content-center align-items-start">
        <div class="col-md-6 imageCarousel">
          <ngb-carousel
            #carousel
            [interval]="1000"
            [pauseOnHover]="pauseOnHover"
            (slide)="onSlide($event)"
          >
            <ng-template ngbSlide *ngFor="let img of selectedProduct.images">
              <div class="picsum-img-wrapper">
                <img [src]="img" class="w-100" />
              </div>
              <!-- Controls -->
            </ng-template>
          </ngb-carousel>
          <a class="carousel-control-prev" role="button" data-slide="prev">
            <span class="fa fa-chevron-left fa-lg" style="color: red"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next fs-1" role="button" data-slide="next">
            <span class="fa fa-chevron-right fa-lg" style="color: red"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <div class="col-md-6">
          <!--    <h6>{{ 'Product-details.category' | translate }} {{ this.selectedProduct.categories[0].description }}</h6>-->
          <div class="row pt-3">
            <h4><b>Stock:</b> {{ this.selectedProduct.currentStock }}</h4>
          </div>

          <div class="row pt-3">
            <h4><b>SKU:</b> {{ this.selectedProduct.sku | transformTextSku }}</h4>
          </div>

          <div class="row pt-3">
            <h4>
              <b>{{ "Modal-Product.category" | translate }}:</b> {{ categoria }}
            </h4>
          </div>

          <div class="row pt-3">
            <h4>
              <b>{{ "Modal-Product.description" | translate }}:</b>
            </h4>
            <p>{{ this.selectedProduct.catalogDescrip | transformTextTag }}</p>
          </div>
          <div class="row pt-3">
            <!-- <div class="price-details"> -->
            <h4>
              <b>{{ "Product-details.cost-ppgg" | translate }}</b> $
              {{ this.selectedProduct.pricePesos }}
            </h4>
          </div>

          <div class="row pt-3">
            <h4>
              <b>{{ "Product-details.selling-price" | translate }}</b> $
              {{ getSellingPrice(this.selectedProduct.pricePesos) }}
            </h4>
          </div>
          <div class="row pt-3" *ngIf="country === 'UY'">
            <h4>
              <b>{{ "Modal-Product.percentage-profit" | translate }}:</b>
              {{ this.profitPercentage }}%
            </h4>
          </div>

          <div class="row pt-3" *ngIf="country === 'UY'">
            <h4>
              <b>{{ "Modal-Product.net-income" | translate }}:</b> $
              {{ getSellerProfit(this.selectedProduct.pricePesos) }}
            </h4>
          </div>
          <div class="row pt-3" *ngIf="country === 'UY'">
            <h4>
              <b>{{ "Modal-Product.discount" | translate }}:</b> $
              {{ getSellerProfit(this.selectedProduct.pricePesos, false) }}
            </h4>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer" *ngIf="this.triggeredFrom !== 'my-catalog'">
      <app-btn-add-product [sku]="selectedProduct.sku"></app-btn-add-product>
    </div>
  </ng-template>
</div>
