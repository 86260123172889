import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EMPTY, Observable, of, Subject} from 'rxjs';
import {User} from '../../model/User';
import {ShopperFiltersDto} from '../../model/dto/SearchOrdersDto';
import {share} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  // Todos unsubscriptos

  private readonly url: string;
  private readonly searchUrl: string;

  getUserLocalStorage = new Subject<User>();
  getUserLocalStorage$ = this.getUserLocalStorage.asObservable();

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = `${baseUrl}users`;
    this.searchUrl = baseUrl + 'users/search';
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.url);
  }

  getUser(userUuid: string): Observable<User> {
    return this.http.get<User>(`${this.url}/${userUuid}`);
  }

  createUser(user: User): Observable<User> {
    return this.http.post<User>(this.url, user);
  }

  editUser(userUuid: string, user: User) {
    return this.http.put<User>(`${this.url}/${userUuid}`, user);
  }

  editUserV2(userUuid: string, user: User) {
    return this.http.put<User>(`${this.url}/vendor/${userUuid}`, user);
  }

  deleteUser(userUuid: string) {
    return this.http.delete<void>(`${this.url}/${userUuid}`);
  }

  getUserByProfileId(userUuid: string) {
    return this.http.get<User>(`${this.url}/by-profile-id/${userUuid}`);
  }

  searchUserByFilters(userFilters: ShopperFiltersDto) {
    return this.http.post(this.searchUrl, userFilters);
  }

  signup(user: User): Observable<void> {
    return this.http.post<void>(`${this.url}/anon/signup`, user);
  }
}
