export class digitalSubCategoryDB {
  static digital_sub_category = [
    {
      img: "<img src='assets/images/digital-product/logo.jpg' class='imgTable'>",
      product_name: 'Logo Design',
      price: '$74.00',
      status: "<i class='fa fa-circle font-success f-12'></i>",
      category: 'Digital',
    },
    {
      img: "<img src='assets/images/digital-product/php.png' class='imgTable'>",
      product_name: 'Php',
      price: '$213.00',
      status: "<i class='fa fa-circle font-danger f-12'></i>",
      category: 'Digital',
    },
    {
      img: "<img src='assets/images/digital-product/html.png' class='imgTable'>",
      product_name: 'HTML',
      price: '$254.00',
      status: "<i class='fa fa-circle font-success f-12'></i>",
      category: 'Digital',
    },
    {
      img: "<img src='assets/images/digital-product/css.jpg' class='imgTable'>",
      product_name: 'CSS',
      price: '$794.00',
      status: "<i class='fa fa-circle font-success f-12'></i>",
      category: 'Digital',
    },
    {
      img: "<img src='assets/images/digital-product/web-element.png' class='imgTable'>",
      product_name: 'Web element',
      price: '$5764.00',
      status: "<i class='fa fa-circle font-danger f-12'></i>",
      category: 'Digital',
    },
    {
      img: "<img src='assets/images/digital-product/wordpress.jpg' class='imgTable'>",
      product_name: 'Wordpress',
      price: '$347.00',
      status: "<i class='fa fa-circle font-danger f-12'></i>",
      category: 'Digital',
    },
    {
      img: "<img src='assets/images/digital-product/3d-design.jpg' class='imgTable'>",
      product_name: '3D Design',
      price: '$5764.00',
      status: "<i class='fa fa-circle font-success f-12'></i>",
      category: 'Digital',
    },
  ];
}
