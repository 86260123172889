import {Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import modals from '../../../shared/modal/modals/modals';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-loader',
  templateUrl: './modal-loader.component.html',
  styleUrls: ['./modal-loader.component.scss']
})
export class ModalLoaderComponent implements OnInit {

  @Input() modalId: string;
  @Input() modalIdSubject: Subject<string>;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    this.modalIdSubject.subscribe((modalId: string) => {
      if (this.modalService.hasOpenModals()) {
        this.modalService.dismissAll();
      }

      this.modalService.open(this.getComponent(modalId), {backdrop: 'static', keyboard: false});
    });
  }

  getComponent(id: string): any {
    if (id in modals) {
      return modals[id];
    }
  }

}
