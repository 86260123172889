import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'app-separator',
    templateUrl: './separator.component.html'
})
export class AppSeparatorComponent implements OnInit {
    @Input() name;
    ngOnInit(): void {
        
    }
}