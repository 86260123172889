import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared/shared.service';
import { StoreService } from '../../services/store/store.service';
import { Store } from '../../model/Store';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { User } from '../../model/User';
import { UsersService } from '../../services/users/users.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { UploadTaskSnapshot } from '@angular/fire/storage/interfaces';
import { imageDTO } from 'src/app/model/dto/settings/ImageDTO';
import { UpdateStoreDTO } from 'src/app/model/dto/settings/UpdateStoreDTO';
import { TemplateSettingJson } from 'src/app/utils/TemplateSettingJson';

@Component({
  selector: 'app-profile',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit, OnDestroy {

  public photoChangeDisabled = false;

  active = 1;

  store: Store;
  userId: string;
  editMode = false;
  userDataEditMode = false;
  userSocialNetworksEditMode = false;
  storeForm: UntypedFormGroup;
  userSocialNetworksForm: UntypedFormGroup;
  frontOfficeBaseUrl: string;
  user: User;
  backendUser: User;

  bannerImgData: imageDTO;
  logoData: imageDTO;

  // Social Network Metadata to fill the view.
  public readonly snMetadata = {
    class: {
      facebook: 'fb',
      instagram: 'google',
      twitter: 'twitter'
    },
    url: {
      facebook: 'https://facebook.com',
      instagram: 'https://instagram.com',
      twitter: 'https://twitter.com'
    }
  };

  private subscriptions = new Subscription();

  constructor(public sharedService: SharedService,
    public storeService: StoreService,
    private userService: UsersService,
    private formBuilder: UntypedFormBuilder,
    private toaster: ToastrService,
    private translate: TranslateService,
    private storage: AngularFireStorage,
    @Inject('FRONT_OFFICE_BASE_URL') frontOfficeBaseUrl: string) {

    this.frontOfficeBaseUrl = frontOfficeBaseUrl;
    this.user = JSON.parse(localStorage.getItem('user') || '{}');
  }

  ngOnInit() {
    this.userId = localStorage.getItem('uuid');
    this.getUserData();
  }

  changeUserDataEditMode() {
    this.userDataEditMode = !this.userDataEditMode;
    this.userDataEditMode ? this.storeForm.controls.enabled.enable() : this.storeForm.controls.enabled.disable();
  }

  getUserData(): void {
    this.subscriptions.add(this.userService.getUser(this.user.uuid).subscribe(
      u => {
        this.backendUser = u;
      },
      error => console.error(error)
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
