export const CONSTANTS = {
  MAX_SIZE_CATALOG: 75,
  MIN_SIZE_CATALOG: 1,
  DEFAULT_IMAGE: 'https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg',
  VENDOR: 'VENDOR',
  ADMIN: 'ADMIN',
  COUNTRIES: [
    {
      name: 'México',
      value: 'mx'
    },
    {
      name: 'Uruguay',
      value: 'uy'
    }
  ]
};
