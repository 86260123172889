import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { TemplateConfig, Templates } from '../../../../utils/config-data'

@Component({
    selector: 'app-templates-stores',
    templateUrl: './templates.component.html',
    styleUrls: ['./templates.component.scss'],
})
export class TemplatesComponent implements OnInit{

    @Output()
    select: EventEmitter<TemplateConfig> = new EventEmitter<TemplateConfig>();

    public templates: TemplateConfig[] = Templates;

    constructor() { }
    
    ngOnInit(): void {
        
    }

    onSelect(template: TemplateConfig) {
        this.select.emit(template);
    }
}