import { Pipe, PipeTransform } from '@angular/core';
import { getCountry } from '../../../main';

@Pipe({
  name: 'transformTextSku',
})
export class TransformTextSkuPipe implements PipeTransform {

  country = getCountry();

  transform(value: string): any {
    if (this.country === 'UY' && value !== undefined && value.split('-').length > 1) {
      return value.split('-')[0];
    }
    return value
  }
}
