import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UsersService} from '../../../services/users/users.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {CONSTANTS} from '../../../utils/Constants';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit, OnDestroy {

  public accountForm: UntypedFormGroup;
  public permissionForm: UntypedFormGroup;
  private subscriptions = new Subscription();

  constructor(private formBuilder: UntypedFormBuilder,
              private usersService: UsersService,
              private router: Router,
              private toast: ToastrService,
              private translate: TranslateService) {

    this.createAccountForm();
  }

  createAccountForm() {
    this.accountForm = this.formBuilder.group({
      userType: [CONSTANTS.VENDOR, [Validators.required]],
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      image: [CONSTANTS.DEFAULT_IMAGE],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      ci: ['', [Validators.required, Validators.pattern(/\d+/)]],
      rut: ['', [Validators.required, Validators.pattern(/\d+/)]],
      address: ['', [Validators.required]],
      businessName: ['', [Validators.required]],
      store: ['', [Validators.required]]
    });
  }

  ngOnInit() {
  }

  createUser(data: UntypedFormGroup) {
    if (!data.valid) {
      this.toast.warning(
        this.translate.instant('Toast.not-valid'),
        this.translate.instant('Toast.warning')
      );

      return;
    }

    this.subscriptions.add(this.usersService.createUser(data.value).subscribe(
      user => {
        this.router.navigate(['/users/list-user']).then(() => {
          this.toast.success(
            this.translate.instant('Toast.user.created'),
            this.translate.instant('Toast.success')
          );
        });
      },
      error => {
        this.toast.error(
          error.error.message,
          this.translate.instant('Toast.error')
        );
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
